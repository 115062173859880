import api from "../";
import TiposPedido from "../../util/typesTipoPedido";

// //! Pega todas as formas de pagamento
// export const GetFormaPagamentoComercial = async (idTipoOperacao, pass) => {

//    try {
//       const { data: response, status } = await api.get(`/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoOperacao}`)

//       return {
//          status,
//          errorMessage: response.errorMessage,
//          data: response.data,
//       }
//    } catch (error) {
//       throw new Error(error + "R3BY5");
//    }
// }

// //! Pega todas as formas de pagamento
// export const GetFormaPagamentoTurismo = async (idTipoOperacao, idPraca) => {

//    try {
//       const { data: response, status } = await api.get(`/FormaPagamento/GetByIdTipoPedidoIdPraca?idTipoPedido=${idTipoOperacao}&idPraca=${idPraca}`)

//       return {
//          status,
//          errorMessage: response.errorMessage,
//          data: response.data,
//       }
//    } catch (error) {
//       throw new Error(error + "R3BY5");
//    }
// }

export const GetFormaPagamento = async (idTipoPedido, idPraca) => {
  try {
    if (
      idTipoPedido != TiposPedido.Recebimento &&
      idTipoPedido != TiposPedido.Remessa
    ) {
      const { data: response, status } = await api.get(
        `/FormaPagamento/GetByIdTipoPedidoIdPraca?idTipoPedido=${idTipoPedido}&idPraca=${idPraca}`
      );
      return {
        status,
        errorMessage: response.errorMessage,
        data: response.data,
      };
    } else {
      const { data: response, status } = await api.get(
        `/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoPedido}`
      );

      // const { data: response, status } =

      return {
        status,
        errorMessage: response.errorMessage,
        data: response.data,
      };
    }
  } catch (error) {
    throw new Error(error + "R3BY5");
  }
};

//! Adiciona a forma de pagamento selecionada no carrinho Temp
export const AddCarrinhoTempConfigurePagamento = async (formData) => {
  try {
    const { data: response } = await api.post(
      `/CarrinhoTemp/ConfiguraPagamento?idCliente=${
        formData?.userID
      }&idOrigem=${3}`,
      {
        idFormaPagamento: formData?.idFormaPagamento,
        descricao: formData?.descricao,
      }
    );

    return response;
  } catch (error) {
    throw new Error(error + " RG77R");
  }
};

//! Pega a forma de pagamento com base em seu ID
export const GetFormaPagamentoByID = async (idFormaPagamento) => {
  try {
    const { data: response, status } = await api.get(
      `/FormaPagamento/GetById?id=${idFormaPagamento}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "RP64E");
  }
};

//! Pega a forma de pagamento com base em seu ID
export const GetCanalBancarioExteriorByIDMoeda = async (idMoeda) => {
  try {
    const { data: response, status } = await api.get(
      `/FormaPagamento/GetCanalBancarioExteriorByIDMoeda?idMoeda=${idMoeda}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R78E8");
  }
};

export const EnviarEmailRemetenteExterior = async (data) => {
  try {
    const response = await api.post(
      `/FormaPagamento/EnviaEmailCanalBancarioExteriorByIDMoeda?idMoeda=${data?.idMoeda}&email=${data?.email}`
    );
  } catch (error) {
    throw new Error(error + " RABC6");
  }
};
