import { ADD_OPERACAO } from '../types';

const initialState = {
   idCliente: 0,
   idNatureza: 0,
   idMoeda: 0,
   idFormaPagamento: 0,
   idItemVitrine: 0,
   idHotsite: 0,
   idTaxa: 0,
   idTaxaDolar: 0,
   IP: 0,
   idCupomPromocional: 0,
   idCupomDesconto: 0,
   idCupomPromocionalTaxaFixa: 0,
   idPais: 0,
   idPraca: 0,
   tarifas: 0.00,
   valorME: 0.00,
   valorTaxaAplicada: 0.00,
   isOriginApp: false,
   valorVet: 0,
   valorIOF: 0,
   isAceiteTermo: false,
   idTipoOperacao: 0,
   idTipoPedido: 0,
   isRecarga: false,
   idPagadorRecebedor: 0,
   quantidadeMe: 0.00,
   formaPagamento: {},
   valorTaxa: 0.00,
   itemVitrine: {},
   natureza: {},
}


// userID: user.id,
// idOrigem: 3,
// idNatureza: selectedNatureza,
// idCliente: 0,
// idMoeda: itemVitrineSelecionado.moeda.id,
// idFormaPagamento: 0,
// formaPagamentoDescricao: "",
// idItemVitrine: itemVitrineSelecionado.id,
// idHotsite: 0,
// idTaxa: composicoes.idTaxa,
// idTaxaDolar: composicoes.idTaxaDolar,
// idCupom: 0,
// IP: 0,
// idCupomPromocional: 0,
// idCupomDesconto: 0,
// idCupomPromocionalTaxaFixa: 0,
// idPais: 0,
// idPraca: 0,
// tarifas: composicoes.tarifaAdministrativa,
// valorME: parseFloat(valorME.replace(".", "").replace(",", ".")),
// valorTaxaAplicada: composicoes.valorTaxa,
// isOriginApp: false,
// valorVet: 0,
// valorIOF: 0,
// isAceiteTermo: isAceite,
// itemVitrine: itemVitrineSelecionado,
// idVitrine: selectedVitrine,
// natureza: naturezaSelecionado,
// idTipoOperacao: 18,
// idTipoPedido: 3,
// isRecarga: false,


const operacaoCambio = (state = initialState, action) => {
   switch (action.type) {
      case ADD_OPERACAO:
         return {
            ...action.payload
         }
      default:
         return state;

   }
}

export default operacaoCambio;