
import api from '../index';


//! Busca a liste de bancos nacionais cadastradas em sistema
export const GetBancosNacionais = async () => {
   try {
      const { data: response, status } = await api.get(`/BancoNacional/GetAll`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "RJAZD");
   }
}

