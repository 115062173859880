import { makeStyles } from "@mui/styles";

const StyleModal = makeStyles((theme) => ({
  buttonClose: {
    position: "fixed",
    right: "1rem",
    top: "0.5rem",
    width: "100%,",
  },
  Box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 359,
    maxHeight: 500,
    overflowY: "auto",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: 24,
    padding: 15,
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 470,
      maxHeight: 500,
      overflowY: "auto",
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxShadow: 24,
      padding: 15,
    },
  },
  IconClose: {
    fontSize: "16px !important",
  },

  containerNotification: {
    position: "fixed",
  }



}));

export default StyleModal;
