import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../../hooks/AuthContext";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { ScreenshotButton } from "../ScreenshotButton";
import TextField from "@mui/material/TextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import useAlert from "../../../hooks/useAlert";
import api from "../../../api";
import { styled } from "@mui/material/styles";
import useStyles from "../styles";
const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginTop: 12,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.up("sm")]: {
    width: "auto",
    marginTop: 0,
  },
}));
export function FeedbackContentStep({
  feedbackType,
  onFeedbackRestartRequested,
  onFeedbackSent,
  // tipoTitulo,
  // tipoImagem,
  // tipoImagemAlt
}) {
  const classes = useStyles();

  const { user } = useAuth();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [screenshot, setScreenshot] = useState(null);
  const [comment, setComment] = useState("");
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  async function handleSubmitFeedback(event) {
    event.preventDefault();
    setIsSendingFeedback(true);
    async function enviaFeedback() {
      setIsSendingFeedback(true);
      try {
        const response = await api.post(`/feedback/SendFeedback`, {
          idCliente: user.id,
          tipoFeedback: feedbackType.title,
          comentario: comment,
          base64Print: screenshot,
        });
        if (
          response.data.errorMessage &&
          response.data.errorMessage.length > 0
        ) {
          showAlertError(response.data.errorMessage);
          // setLoading(false);
        } else {
          showAlertSuccess("Email enviado com sucesso");
          onFeedbackSent();
        }
      } catch (error) {
        showAlertError(error.message);
      }
      setIsSendingFeedback(true);
    }
    enviaFeedback();
  }
  return (
    <>
      <Alert />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={onFeedbackRestartRequested}>
          <ArrowBackIosIcon fontSize="small" sx={{ color: "#000" }} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            color="black"
            justifyContent="center"
            alignItems="center"
            sx={{ display: "flex", fontSize: "10pt" }}
          >
            {feedbackType.title === "Problema" ? (
              <ErrorOutlineIcon sx={{ mr: 1 }} fontSize="small" />
            ) : feedbackType.title === "Ideia" ? (
              <LightbulbOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
            ) : (
              <MapsUgcOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
            )}
            {feedbackType.title}
          </Typography>
          <Tooltip title="Iremos analisar seu Feedback e inserir em nossas melhorias na plataforma">
            <IconButton>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Typography
        color="black"
        sx={{ fontWeight: 500, fontSize: "14pt", mt: 2 }}
        variant="body1"
      >
        Conte com detalhes o que está acontecendo
      </Typography>
      <Typography
        color="text"
        sx={{ fontSize: "10pt", mt: 1 }}
        variant="subtitle1"
      >
        Caso precise, tire um print para anexar.
      </Typography>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": {
            mt: 2,
            width: "100%",
            minHeight: "25ch",
            maxHeight: "25ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-textarea"
          label="Deixe seu FeedBack"
          multiline
          maxRows={8}
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
          hiddenLabel={true}
          onFocus={(event) => {
            if (comment === "Conte com detalhes o que está acontecendo...")
              setComment("");
          }}
        />
      </Box>

      <Box className={classes.Screen}>
        <ScreenshotButton
          screenshot={screenshot}
          onScreenshotTaken={setScreenshot}
        />
        <ColorButton
          variant="contained"
          size="small"
          disabled={
            comment.length === 0 ||
            isSendingFeedback ||
            comment === "Conte com detalhes o que está acontecendo..."
          }
          onClick={handleSubmitFeedback}
        >
          {isSendingFeedback ? <Skeleton /> : "Enviar feedback"}
        </ColorButton>
      </Box>
    </>
  );
}
