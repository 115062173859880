import { getSaldoClienteByIdMoeda, getSaldoCLiente } from '../../api/chamadasApi';
// import { GET_SALDO } from "../types"



export const getAllSaldo = (idCliente) => async (dispatch) => {
   let response = { isSuccess: true, errorMessage: "" };
   try {
      const response = await getSaldoCLiente(idCliente);


      // if (data.errorMessage) {
      //    response = { ...response, isSuccess: false }
      //    throw new Error(`${data.errorMessage} - RGT23`)
      // }

      // dispatch({ type: GET_SALDO, payload: { ...data.data[0] } });
      // return response;
   } catch (error) {
      return { ...response, errorMessage: error.message }
   }
}

export const getSaldoByIdMoeda = (idCliente, idMoeda = 1) => async (dispatch) => {
   const data = await getSaldoClienteByIdMoeda(idCliente, idMoeda);
   dispatch({ type: "GET_SALDO", payload: data });
};


