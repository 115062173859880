import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
