import { localStorageGetItem } from "../../util/LocalStorage";

let usr = JSON.parse(localStorageGetItem());
const initialState = {
  usuario: usr,
  errors: {},
};

export default function usuarioState (state = initialState, action: any) {
  switch (action.type) {
    default:
      return state;
  }
}
