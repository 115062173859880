import { makeStyles } from "@mui/styles";
const important = " !important";

const useStyles = makeStyles((theme) => ({
  Box: {
    minWidth: "80vw",
      margin:25,
              minHeight: "400px",
             
              [theme.breakpoints.up("sm")]: {
                minWidth: "500px",
                margin:15,
                minHeight: "400px",
          
            }
  },
  Popover: {
  background:"rgba(0,0,0,0.2) !important",

              [theme.breakpoints.up("sm")]: {
              
          
            }
  },
Screen:{

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    justifyContent: "space-between",
    
}
},
img:{
  width:'60px',
height:38,

marginLeft:12,
  [theme.breakpoints.up("sm")]: {
width:'auto',
height:40,
m:0,
}
},
buttonFeedback:{
  marginBottom:'8em !important',
  bottom:10,
  [theme.breakpoints.up("sm")]: {
    bottom:10,
    marginBottom:'20px !important',
  },

}

}));

export default useStyles;
