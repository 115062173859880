import { useState } from "react";
import { useQuery } from "react-query";
import { CLIENTEISTOKENATIVO } from '../../util/typesReactQuery';
import { isTokenAtivo } from "../../api/ContaCliente";

const Cliente = () => {
    const [tokenAtivo, setTokenAtivo] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useQuery([CLIENTEISTOKENATIVO], isTokenAtivo, {
        onSuccess: (response) => {

            if (response.errorMessage) {
                setErrorMessage(response.errorMessage);
            }

            setTokenAtivo(response.data);

        },
    });


    return {
        errorMessage,
        isTokenAtivo: tokenAtivo
    }
}

export default Cliente;