import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
List:{
    
    maxHeight:'auto',
    [theme.breakpoints.up("sm")]: {
  
        maxHeight:'52vh',
        overflow: 'auto',
    
}
}


}));

export default useStyles;
