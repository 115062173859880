import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Titulo: {
    paddingLeft: "10px ",
    marginBottom: 2,
    alignItems: "center",
    display: "flex",
  },

  PedidoCambio: {
    cursor:'pointer',
    background:'#fcfcfc',

    marginBottom:12,
    '&:hover': {
      background:'#fafafa',
    }
  },

  Grid: {
    margin: "auto !important",
    background: "#fff",
    maxHeight: "80vh",

    borderRadius: "10px",
    marginTop: "4em !important",
    [theme.breakpoints.up("sm")]: {
      margin: "auto !important",
      background: "#fff",
      maxHeight: "80vh",
      padding: 20,
      borderRadius: "10px",
      marginTop: "4em !important",
      maxWidth: "75em",
    },
  },
  Container: {
    background: "#fff",
    height: "auto,",
    padding:10,
  },
  Status: {
    display: "flex",
  },
  Box: {
    alignItems: "center",
    display: "flex",
    marginBottom: 20,
    justifyContent: "space-between",
  },
}));
export default useStyles;
