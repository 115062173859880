import { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getNotificacoesLidasCliente } from "../../../api/Notificacao";
import { useAuth } from "../../AuthContext";



export default function useNotificationsLidas() {
    const { user } = useAuth();
    const [notificacoes, setNotificacoes] = useState();
    const [errorNotLida, setErrorNotLida] = useState("")

    const {
        isLoading,
        refetch: refetchNotificacoesLidas,
        hasNextPage: hasNextPageLidas,
        fetchNextPage: handlerNextPageLidas,
    } = useInfiniteQuery(
        "NOTIFICACOESLIDAS",
        ({ pageParam = 1 }) =>
            getNotificacoesLidasCliente(user?.id, pageParam),
        {
            getNextPageParam: (lastPage, allPages, data) => {

                if (lastPage?.data?.length === 5) {
                    return allPages.length + 1;
                }
                return undefined;
            },
            onSuccess: (data) => {
                if (data?.pages) {
                    let dadosNotificacao = [];
                    data?.pages?.forEach((page) => {
                        page.data.forEach(notification => dadosNotificacao.push(notification));
                    })

                    setNotificacoes(() => dadosNotificacao);
                }
            },
            onError: (error) => {
                setErrorNotLida(error.message + " RWL99")
            },
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        notificacoesLida: notificacoes,
        hasNextPageLidas,
        refetchNotificacoesLidas,
        handlerNextPageLidas,
        isLoadingNotLida: isLoading,
        errorNotLida
    }

}