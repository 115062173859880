import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import useStyles from "./styles";
import { useFormContext } from "react-hook-form";


/**
 * @param {string} value : valor que ira aparecer no campo;
 * @param {function} handlerChange : função para alteração do valor do campo; 
 * @param {string} name : usado como uma maneira de se referencia ao campo; 
 * @param {string} label : um texto que indica para que serve esse campo;
 * @param {string} size: 'small' =>  determina que o campo será pequeno, 'medium' =>  valor padrão, determina que o campo terá um tamanho padrão referente a largura e altura
 * @returns retorna um campo de formulario de inserção de e-mail
 */

export default function CampoEmail({ value, handlerChange, name, label, size, ...props }) {
  const [isError, setIsError] = useState(false);
  const [textError, setTextError] = useState("");
  const classes = useStyles();
  const formsControll = useFormContext()

  useEffect(() => {
    const valid = /\S+@\S+\.\S+/.test(value);
    setIsError(!valid);
  }, [value]);


  useEffect(() => {
    if (!!formsControll?.formState?.errors && formsControll?.formState?.errors[name]) {
      setTextError(formsControll?.formState?.errors[name]?.message)
      setIsError(true);
    }
  }, [formsControll?.formState])


  return (
    <>
      <TextField
        value={value}
        margin="normal"
        required
        name={name}
        size={size}
        fullWidth
        disabled={false}
        id="email"
        label={label}
        autoComplete={name}
        autoFocus
        onChange={handlerChange ?? null}
        helperText={textError ? textError : isError ? "Email inválido!" : ""}
        error={isError}
        className={classes.root}
        {...props}
      />
    </>
  );
}
