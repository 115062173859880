import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Box: {
    [theme.breakpoints.down("sm")]: {
      padding: 25,
    },
    [theme.breakpoints.up("md")]: {
      padding: 25,
    },
    [theme.breakpoints.up("lg")]: {
      padding: 15,
    },
  },
}));

export default useStyles;
