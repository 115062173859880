import api from "../index";
import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const saveEndereco = async (endereco) => {
  try {
    const { status, data: response } = await api.post(
      "/Endereco/Save",
      endereco
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R013E");
  }
};

export const getEnderecos = async (idCliente) => {
  try {
    const { data: response } = await api.get(
      `/Endereco/GetByIdCliente?idCliente=${idCliente}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RBE38");
  }
};

export const desativaAtivaByID = async ({ idCliente, idEndereco }) => {
  try {
    const { status, data: response } = await api.post(
      `/Endereco/DesativaAtivaByID?idCliente=${idCliente}&idEndereco=${idEndereco}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R470A");
  }
};

export const getCep = async (cep, idCliente, idTipoEndereco) => {
  try {
    const { status, data: response } = await api.get(
      `/Endereco/GetCep?cep=${cep}&idCliente=${idCliente}&idTipoEndereco=${idTipoEndereco}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " 7198A");
  }
};

export const getEnderecoCorrespondencia = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/Endereco/Correspondencia?idCliente=${idCliente}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " FD06A");
  }
};

export const updateEndereco = async (endereco) => {
  try {
    const { status, data: response } = await api.put(
      "/Endereco/Update",
      endereco
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " 5BB85}");
  }
};

export const removeEndereco = async (idEndereco) => {
  try {
    const { status, data: response } = await api.delete(
      `/Endereco/Delete?id=${idEndereco}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " B30A5");
  }
};

export const getValorSedex = async (cep, idPraca, isOuro, valorTotalMN = 0) => {
  try {
    const { status, data: response } = await api.get(
      `/RegiaoEntrega/CalcularValorSedex?cepEntrega=${cep}&idPraca=${idPraca}&isOuro=${isOuro}&valorTotalMoedaNacional=${isOuro ? valorTotalMN : 0}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " B30A5");
  }
};
