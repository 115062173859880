import { TextField, FormControl } from "@mui/material";
import NumberFormat from "react-number-format";
import CampoOperacaoStyle from "./styles";
import Skeleton from "@mui/material/Skeleton";

const CampoOperacao = ({
  index,
  value,
  sx,
  prefix,
  onChange,
  onBlur,
  onValueChange,
  enable,
  size,
  format,
  mask,
  isLoading = false,
  label,
  decimalSeparator = true,
}) => {
  const classes = CampoOperacaoStyle();

  const handlerChangeValueFormatted = (value) => (event) => {
    onValueChange(value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        sx={{ width: "100% !important", display: "flex" }}
        index={index}
        size={size}
        fullWidth={true}
      >
        {isLoading && (
          <Skeleton
            variant="rectangular"
            height={40}
            sx={sx}
            animation={false}
            className={classes.root}
          />
        )}
        {!isLoading && (
          <NumberFormat
            size={size}
            label={label}
            format={format ?? null}
            mask={mask ?? null}
            fullWidth={true}
            value={value}
            displayType="input"
            allowNegative={false}
            prefix={prefix ?? ""}
            decimalScale={2}
            disabled={!enable}
            onValueChange={(values) => handlerChangeValueFormatted(values)}
            onChange={onChange}
            onBlur={onBlur}
            decimalSeparator={decimalSeparator ? "," : false}
            thousandSeparator="."
            customInput={TextField}
          />
        )}
      </FormControl>
    </>
  );
};

export default CampoOperacao;
