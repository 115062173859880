import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { TooltipInformacao, PositionTooltip } from "../../../TooltipInformacao";
import InfoIcon from "@mui/icons-material/Info";
import { Skeleton } from "@mui/material";
import {
  formatMoneyInStringForm,
  formatMoneyInString,
} from "../../../../util/helpers";

const ComposicaoEspecie = ({ composicaoData, moeda, valorIOF = 0.0 }) => {
  return (
    <>
      <Box sx={{ ml: 3 }}>
        <Typography
          sx={{ ml: 1, mt: 0, alignItems: "center", display: "flex" }}
          color="text.secondary"
          variant="body1"
        >
          {!composicaoData.aliquotaIOF && moeda ? (
            <Skeleton />
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <TooltipInformacao
                  positionStart={PositionTooltip.left}
                  descricao="IOF significa imposto sobre 'Operações Financeiras'. Essa tarifa é retida pela receita federal e, para transferências de mesma titularidade "
                >
                  <InfoIcon color="primary"></InfoIcon>
                </TooltipInformacao>
                Valor IOF({composicaoData.aliquotaIOF}):{" "}
                {formatMoneyInStringForm(
                  valorIOF,
                  moeda.moeda.numeroCasaDecimais
                )}
              </Box>
            </>
          )}
        </Typography>
        <Box sx={{ mt: ".8rem" }} />

        <Box sx={{ mt: ".8rem" }} />
        <Typography
          sx={{ ml: 1, mt: 2, alignItems: "center", display: "flex" }}
          color="text.secondary"
          variant="body1"
        >
          {!composicaoData.valorTaxa && moeda ? (
            <Skeleton />
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <TooltipInformacao
                  positionStart={PositionTooltip.left}
                  descricao="Cotação da moeda da sua transferência, sem taxas ou impostos."
                >
                  <InfoIcon color="primary"></InfoIcon>
                </TooltipInformacao>{" "}
                {moeda.moeda.simbolo} ={" "}
                {formatMoneyInString(composicaoData.valorTaxa)}
              </Box>
            </>
          )}
        </Typography>

        <Typography
          sx={{ ml: 1, mt: 2, alignItems: "center", display: "flex" }}
          color="text.secondary"
          variant="body1"
        >
          {!composicaoData.valorTaxa && moeda ? (
            <Skeleton />
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <TooltipInformacao
                  positionStart={PositionTooltip.left}
                  descricao="O Valor Efetivo Total (VET) representa o custo de uma operação de câmbio em reais por moeda estrangeira, englobando a taxa de câmbio, as tarifas e tributos incidentes sobre essa operação."
                >
                  <InfoIcon color="primary"></InfoIcon>
                </TooltipInformacao>
                VET:
              </Box>
            </>
          )}
        </Typography>
      </Box>
    </>
  );
};

export default ComposicaoEspecie;
