import { useEffect, useState } from "react";
import PagadorRecebedor from "../../../../../components/PagadorRecebedor";
import { useAuth } from "../../../../../hooks/AuthContext";
import {
  getPagadorRecebedor,
  addItemPagadorRecebedor,
} from "../../../../../api/Carrinho/TranferenciaInternacional";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import { LoadingBeneficiario, Timer } from "../../../../../components";
import useAlert from "../../../../../hooks/useAlert";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import { saveLocalStorageTimerCarrinho } from "../../../../../util/LocalStorage";
import { StepEnvio } from '../../../../../util/StepsComercial'

export default function Beneficiarios() {

  const [carrinho, setCarrinho] = useState(null);
  const { user } = useAuth();
  const navigation = useNavigate();
  const [loadingSelect, setLoadingSelect] = useState(false);
  const { Alert, showAlertError } = useAlert();
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);



  useEffect(() => {
    init(user.id);
  }, []);

  const init = async (idCliente) => {
    const pagadoresRecebedores = await getPagadorRecebedor(idCliente);

    if (pagadoresRecebedores.data) {
      setCarrinho(pagadoresRecebedores.data);
      saveLocalStorageTimerCarrinho(pagadoresRecebedores.data.dataExpiracao);
    }
  };

  const handlerNext = async (event, pagadorRecebedor) => {
    event.preventDefault();
    try {
      setLoadingSelect(true);
      const response = await addItemPagadorRecebedor(user.id, pagadorRecebedor);

      if (!!response.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${response.errorMessage} - R14BC`
        );

      if (!!!response)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possivel registrar o beneficiario escolhido - R0670`
        );

      if (response && !response.errorMessage) {
        setTimeout(() => {
          navigation("/carrinho/transferencia-internacional/envio/pagamento");
        }, 2000);
      }

      setTimeout(() => {
        setLoadingSelect(false);
      }, 2000);
    } catch (error) {
      setLoadingSelect(false);
      showAlertError(`${error} - R370D`);
    }
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/envio/vitrine");
  };

  return (
    <div>
      <Alert />

      <CarrinhoPage
        disableButtonNext={true}
        handleBack={handleBack}
        steps={StepEnvio}
        activeStep={1}
      >
        {!!carrinho?.dataExpiracao && (
          <Timer
            active={!!carrinho.dataExpiracao}
            dataExpiracao={carrinho?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}

        <>
          {carrinho ? (
            <>
              <Typography
                style={{
                  fontSize: "16pt",
                  paddingBottom: "21pt",
                  paddingTop: "10px",
                }}
              >
                Para quem deseja enviar?
              </Typography>
              <PagadorRecebedor
                idPais={carrinho.pais.id}
                paises={carrinho.paises}
                onNext={handlerNext}
                tipoLabel="Beneficiário"
                loadingSelect={loadingSelect}
              />
            </>
          ) : loadingSelect ? (
            <>
              <Skeleton height={50} animation="wave" width={250} />
              <LoadingBeneficiario />
            </>
          ) : (
            <Typography
              style={{
                fontSize: "16pt",
                paddingBottom: "21pt",
                paddingTop: "10px",
              }}
            >
              Não existem parâmetros de país para envio/recebimento configurado
              no sistema.
            </Typography>
          )}
        </>
      </CarrinhoPage>
    </div>
  );
}
