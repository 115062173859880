import CampoTexto from "../../../Campos/CampoTexto";
import CampoData from "../../../Campos/CampoData";
import CampoOperacao from "../../../Campos/CampoOperacao";
import Seletor from "../../../Seletor";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomGrid from "../../../GridCadastroCustom";

const sexos = [
  {
    id: 1,
    descricao: "Masculino",
  },
  {
    id: 2,
    descricao: "Feminino",
  },
  {
    id: 3,
    descricao: "Não Informado",
  },
];

const estadosCivis = [
  {
    id: 1,
    descricao: "Solteiro",
  },
  {
    id: 2,
    descricao: "Casado",
  },
  {
    id: 3,
    descricao: "Divorciado",
  },
  {
    id: 4,
    descricao: "Viúvo",
  },
  {
    id: 5,
    descricao: "Não Informado",
  },
];

export default function DadosPessoaEstrangeira({
  classes,
  dadosForm,
  setForm,
  setData,
  isLoading,
}) {
  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    } else {
      return value;
    }
  };

  const handlerChangeValue = (e) => {
    const camposInternos = dadosForm.dadosPessoaEstrangeira.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira["campos"] = camposInternos;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira["campos"] = camposInternos;
      return newCliente;
    });
  };

  const handleChangeCheck = (e) => {
    const camposInternos = dadosForm.dadosPessoaEstrangeira.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira["campos"] = camposInternos;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira["campos"] = camposInternos;
      return newCliente;
    });
  };

  const handlerChangeSelect = (name, value) => {
    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira[name] = value;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaEstrangeira[name] = value;
      return newCliente;
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {dadosForm.dadosPessoaEstrangeira.campos &&
          dadosForm.dadosPessoaEstrangeira.campos.map((c, i, array) => (
            <CustomGrid index={i} array={array} md={3}>
              <>
                {c.tipoControle === "Texto" && (
                  <CampoTexto
                    enable={c.enable}
                    sx={{ mt: 0, m: 2 }}
                    maximoDigitos={c.maxLength}
                    variant="filled"
                    size="small"
                    fullWidth={false}
                    label={c.label}
                    name={c.propriedade}
                    value={c.valor}
                    handlerChange={handlerChangeValue}
                    className={classes.campo}
                    isLoading={isLoading}
                  />
                )}

                {c.tipoControle === "Check" && (
                  <FormGroup>
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          value={c.valor}
                          checked={c.valor}
                          onChange={handleChangeCheck}
                          inputProps={{ "aria-label": "controlled" }}
                          name={`${c.propriedade}`}
                        />
                      }
                      label={c.label}
                    />
                  </FormGroup>
                )}

                {(c.tipoControle === "Data" || c.tipoMascara === "Data") && (
                  <CampoData
                    enable={c.enable}
                    sx={{ mt: 0, m: 2 }}
                    size="small"
                    label={c.label}
                    fullWidth={false}
                    name={c.propriedade}
                    value={formatDateValueInput(c.valor)}
                    onChange={handlerChangeValue}
                    className={classes.campo}
                    isLoading={isLoading}
                  />
                )}

                {c.tipoControle === "Numero" && (
                  <CampoOperacao
                    key={i}
                    enable={c.enable}
                    sx={{ mt: 0, m: 2 }}
                    variant="filled"
                    size="small"
                    fullWidth={false}
                    label={c.label}
                    name={c.propriedade}
                    value={c.valor}
                    onChange={handlerChangeValue}
                    className={classes.campo}
                    isLoading={isLoading}
                    required={c.obrigatorio}
                    maximoDigitos={c.maxLength}
                  />
                )}
              </>
            </CustomGrid>
          ))}

        {dadosForm.dadosPessoaEstrangeira.idSexo > 0 && (
          <Grid item xs={6} md={3}>
            <Seletor
              sx={{ mt: 0, m: 2, pr: 4 }}
              setSelect={(e) => handlerChangeSelect("idSexo", e)}
              list={sexos}
              size="small"
              label="Sexo"
              fullWidth={true}
              value={dadosForm.dadosPessoaEstrangeira.idSexo}
              isLoading={isLoading}
            />
          </Grid>
        )}
        {dadosForm.dadosPessoaEstrangeira.idEstadoCivil > 0 && (
          <Grid item xs={6}>
            <Seletor
              sx={{ mt: 0, m: 2, pr: 4 }}
              setSelect={(e) => handlerChangeSelect("idEstadoCivil", e)}
              list={estadosCivis}
              size="small"
              fullWidth={true}
              label="Estado Civil"
              value={dadosForm.dadosPessoaEstrangeira.idEstadoCivil}
              isLoading={isLoading}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
