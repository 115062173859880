import { useState } from "react";
import { getNotificacoesNaoLidasCliente, setNotificacaoClientelido } from "../../../api/Notificacao";
import { useInfiniteQuery } from "react-query";
import { useAuth } from "../../AuthContext";


export default function useNotificationsNaoLidas(isNotificacaoLida) {
    const { user } = useAuth();
    const [notificacoes, setNotificacoes] = useState();
    const [errorNotNaoLida, seterrorNotNaoLida] = useState("");


    const setMarcarComoLida = async (idNotificacao) => {
        try {
            let data = await setNotificacaoClientelido(user?.id, idNotificacao);

            if (!!data?.data?.errorMessage)
                throw new Error(data.data.errorMessage);

            refetchNotificacoesNaoLidas();

        } catch (error) {
            seterrorNotNaoLida(error.message + " RHD9I")
        }
    }

    const {
        isLoading,
        refetch: refetchNotificacoesNaoLidas,
        hasNextPage: hasNextPageNaoLidas,
        fetchNextPage: handlerNextPageNaoLidas,
    } = useInfiniteQuery(
        "",
        ({ pageParam = 1 }) =>
            getNotificacoesNaoLidasCliente(user?.id, pageParam),
        {
            getNextPageParam: (lastPage, allPages, data) => {
                if (lastPage?.data?.length === 5) {
                    return allPages.length + 1;
                }
                return undefined;
            },
            onSuccess: (data) => {
                if (data?.pages) {
                    let dadosNotificacao = [];
                    data?.pages.forEach((page) => {
                        page.data.forEach(notification => dadosNotificacao.push(notification));
                    })

                    setNotificacoes(() => dadosNotificacao);
                }
            },
            onError: (error) => {
                seterrorNotNaoLida(error.message + " RHS77")
            },
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        notificacoesNaoLidas: notificacoes,
        hasNextPageNaoLidas,
        quantidadeNotificacoes: notificacoes?.length ?? 0,
        refetchNotificacoesNaoLidas,
        handlerNextPageNaoLidas,
        setMarcarComoLida,
        isLoadingNotNaoLida: isLoading,
        errorNotNaoLida

    }

}