import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      "& .Mui-error": {
   
      },
      "& .MuiFormHelperText-root": {
        color: "orange",
       
      },
      '& .MuiInputBase-input':{
        fontSize:16,
        letterSpacing:2,
      }
    },
   
  }));

export default useStyles;
