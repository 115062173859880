import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Skeleton } from "@mui/material";
import List from "@mui/material/List";

// ? ======================== Chamadas componentes =============================
import {
  CupomDesconto,
  Subtitulo,
  Titulo,
  Timer,
} from "../../../../components";
import RemessaStyle from "./styles";
import { formatMoneyInStringForm } from "../../../../util/helpers";
import useAlert from "../../../../hooks/useAlert";
import { useAuth } from "../../../../hooks/AuthContext";

import {
  getCarrinho,
  RemoveItemByOuroIdMoeda,
} from "../../../../api/Carrinho/Ouro";

import ItemCambioCarrinho from "../../../../components/ItemCambioCarrinho";
import CarrinhoPage from "../../../../components/CarrinhoPage";
import { finalizar } from "../../../../api/Carrinho/Ouro";
import { useMutation, useQuery } from "react-query";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import TiposPedidos from "../../../../util/typesTipoPedido";
import { StepComEntrega } from "../../../../util/StepsTurismo";
import { clearLocalStorageTimerCarrinho } from "../../../../util/LocalStorage";
import { formatMoneyInString } from "../../../../util/helpers";
import { GETITENSCARRINHOOURO } from "../../../../util/typesReactQuery";
import typesTipoPedido from "../../../../util/typesTipoPedido";
import usePedido from "../../../../hooks/usePedido";

export default function ItemCarrinhoTurismo() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { Alert, showAlertError, showAlertSuccess } = useAlert();
  const [urlRetorno, setUrlRetorno] = useState("/carrinho/Compra-ouro/vitrine");
  const [carrinho, setCarrinho] = useState({});
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [urlBack, setUrlBack] = useState("");
  const [dadosCartao, setDadosCartao] = useState({
    maximoDigitos: 0,
    codigo: "",
  });

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const { refetch: refetchItens } = useQuery(
    [GETITENSCARRINHOOURO],
    () => getCarrinho(user.id),
    {
      onSuccess: (data) => {
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum carrinho - RK140`
          );
          return;
        }
        if (data.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - R7K7X`
          );
          return;
        }

        if (!!!data.data || data?.data?.itens.length === 0)
          setTimeout(() => {
            clearLocalStorageTimerCarrinho();
            navigation("/CarrinhoVazio", {
              replace: false,
            });
          }, 2000);

        setSteps(StepComEntrega);
        setActiveStep(3);
        setUrlBack(
          `/carrinho/Ouro/pagamento/${data.data.itens[0].idTipoOperacaoCarrinho}`
        );

        setCarrinho(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - R5TGF`
        );
      },
      refetchOnWindowFocus: true,
    }
  );

  const mutationRemoveItem = useMutation(RemoveItemByOuroIdMoeda, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoadingConfirmePedido(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - R78LO");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RGUQE `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchItens();
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2000);
    },
  });

  const handlerRemoveItem = (idMoeda) => (event) => {
    mutationRemoveItem.mutate({
      idCliente: user?.id,
      idOrigem: user?.idOrigemCadastroCliente,
      idMoeda,
    });
  };

  const handleNext = async (event) => {
    event.preventDefault();
    try {
      setLoadingConfirmePedido(true);

      if (dadosCartao?.maximoDigitos) {
        if (!dadosCartao?.codigo) {
          showAlertError(
            "Ops, algo deu errado: É necessario informar os digitos do cartão - RK7ZA"
          );
          return;
        }

        if (
          dadosCartao?.codigo &&
          dadosCartao.codigo.length < dadosCartao.maximoDigitos
        ) {
          showAlertError(
            `Ops, algo deu errado: É necessario informar os ${dadosCartao.maximoDigitos} digitos do cartao  - RYJE8`
          );
          return;
        }
      }

      const responseFinalizacao = await finalizar(user.id);

      if (!!responseFinalizacao.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${responseFinalizacao.errorMessage} - RFNIE`
        );

      if (!!!responseFinalizacao.data)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possivel finalizar o pedido - RVD39`
        );

      showAlertSuccess(
        "Oba, seu pedido foi registrado, espere um pouco e veja como efetuar o pagamento"
      );

      if (responseFinalizacao && !responseFinalizacao.errorMessage) {
        setTimeout(() => {
          navigation(
            `/turismo/PedidoConcluidoPagamentoEscolhido/${responseFinalizacao.data}`
          );
        }, 2500);
      }
    } catch (error) {
      showAlertError(`${error} - RTFYG`);
    } finally {
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2499);
    }
  };

  const handlerComprarMais = () => {
    navigation(urlRetorno);
  };

  const handleBack = () => {
    navigation(urlBack, {
      replace: false,
    });
  };

  const handlerRemoveCupom = (event) => {
    setCarrinho({
      ...carrinho,
      ["cupom"]: { codigoPromocional: "" },
    });
  };

  return (
    <>
      <Alert />

      <CarrinhoPage
        handleBack={handleBack}
        handleNext={handleNext}
        steps={steps}
        activeStep={activeStep}
        loadingButton={loadingConfirmePedido}
        disabledVoltarCompras={true}
        handleBackVoltarComprar={handlerComprarMais}
      >
        {!!carrinho?.dataExpiracao && (
          <Timer
            active={!!carrinho.dataExpiracao}
            dataExpiracao={carrinho?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}

        <List dense className={classes.List}>
          <Grid item className={classes.BlocoConferir}>
            {loadingConfirmePedido ? (
              <Skeleton height={50} width={200} animation="wave" />
            ) : (
              <Titulo className={classes.Titulo}>
                Confira sua operação de ouro
              </Titulo>
            )}

            {carrinho && (
              <>
                {loadingConfirmePedido ? (
                  <>
                    <Skeleton height={50} animation="wave" />
                    <Skeleton height={50} animation="wave" />
                    <Skeleton height={50} animation="wave" />
                  </>
                ) : (
                  <>
                    <ItemCambioCarrinho
                      itens={carrinho.itens}
                      impostos={carrinho.impostos}
                      praca={carrinho?.praca}
                      setLoading={setLoadingConfirmePedido}
                      loading={loadingConfirmePedido}
                      handlerRemoveItem={handlerRemoveItem}
                      refetchItens={refetchItens}
                      setDadosCartao={setDadosCartao}
                      dadosCartao={dadosCartao}
                    />

                    <Divider />

                    <Titulo sx={{ marginTop: "1rem" }}>Frete e Praça</Titulo>
                    <br />
                  </>
                )}

                {loadingConfirmePedido ? (
                  <>
                    <Skeleton
                      height={50}
                      width={"100%"}
                      animation="wave"
                      style={{ marginBottom: ".5rem !important" }}
                    />

                    <Skeleton height={50} width={"100%"} animation="wave" />
                    <Skeleton height={50} width={"100%"} animation="wave" />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: ".5rem",
                      }}
                    >
                      <Subtitulo>Praça</Subtitulo>
                      {carrinho?.praca?.descricao}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: ".5rem",
                      }}
                    >

                      <Subtitulo>Valor da entrega</Subtitulo>
                      {formatMoneyInString(carrinho.valorFrete + carrinho.valorSeguroFrete + carrinho.valorLogisticaFrete)}
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Subtitulo>Valor da confecção e embalagem</Subtitulo>
                      {formatMoneyInString(carrinho.valorEmbalagem)}
                    </Box>
                  </>
                )}

                <br />

                <Divider />
              </>
            )}
            <Divider />

            {carrinho &&
              carrinho.cupom &&
              carrinho.cupom.valorDesconto &&
              (!loadingConfirmePedido ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  {" "}
                  <Titulo color="text.secondary" label="Desconto"></Titulo>
                  <Titulo sx={{ fontWeight: "500" }} align="right">
                    R${" "}
                    {formatMoneyInStringForm(carrinho.cupom.valorDesconto, 2)}
                  </Titulo>
                </Box>
              ) : (
                <Skeleton height={50} width={150} animation="wave" />
              ))}

            {carrinho &&
              carrinho?.valorTotalMoedaNacional &&
              (!loadingConfirmePedido ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  {" "}
                  <Titulo
                    color="text.secondary"
                    sx={{ fontSize: 20 }}
                    label="Total"
                  ></Titulo>
                  <Titulo
                    sx={{ fontWeight: "bold", fontSize: 21 }}
                    color="primary"
                    align="right"
                  >
                    R${" "}
                    {formatMoneyInStringForm(
                      carrinho?.valorTotalMoedaNacional,
                      2
                    )}
                  </Titulo>
                </Box>
              ) : (
                <Skeleton width={"100%"} height={50} animation="wave" />
              ))}
            <br></br>
            <Box>
              {loadingConfirmePedido ? (
                <>
                  <Skeleton height={50} animation="wave" />
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "-10px",
                  }}
                >
                  <CupomDesconto
                    cupom={carrinho && carrinho?.cupom}
                    idTipoMercado={1}
                    idMoeda={carrinho.idMoeda}
                    idTipoPedido={typesTipoPedido.VendaOuro}
                    onReflesh={() => refetchItens()}
                  />
                  {carrinho &&
                    carrinho?.cupom?.id &&
                    (!loadingConfirmePedido ? (
                      <Button
                        color="error"
                        variant="contained"
                        size="medium"
                        onClick={handlerRemoveCupom}
                      >
                        Remover
                      </Button>
                    ) : (
                      <Skeleton height={50} width={150} animation="wave" />
                    ))}
                </Grid>
              )}
            </Box>
          </Grid>
        </List>
      </CarrinhoPage>
    </>
  );
}
