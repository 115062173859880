import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { Tabs, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Pedidos from "../Pedidos";
import LimiteOperacional from "../LimiteOperacional/index";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import Saldo from "../Saldo";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import { styled } from "@mui/material/styles";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Carteira() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        maxWidth: "100vw",
      }}
    >
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/*<TabPanel
          style={{
            width: "100vw",
          }}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <Saldo />
        </TabPanel>*/ }
        <TabPanel
          sx={{
            width: "100vw",
          }}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <LimiteOperacional />
        </TabPanel>
        <TabPanel
          sx={{
            width: "100vw",
          }}
          value={value}
          index={1}
          dir={theme.direction}
        >
          <Grid sx={{marginTop:2, p:1}}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight:'bold', color:'black'}}>
                Últimos pedidos
              </Typography>
              <Link to="/MeusPedidos" sx={{mt:2}} color="primary">
                Ver todos
              </Link>
            </Box>
            <Pedidos />
          </Grid>
        </TabPanel>
      </SwipeableViews>
      <AppBar
        position="static"
        sx={{
          position: "fixed",
          bottom: "0",
          boxShadow: "0px 0px 4px 3px #ccc",
          background: "#eee",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {/*<AntTab
            icon={
              <AccountBalanceWalletOutlinedIcon sx={{ fontSize: "21px" }} />
            }
            label="Carteira"
            {...a11yProps(0)}
          />*/}
          <AntTab
            icon={<AttachMoneyOutlinedIcon sx={{ fontSize: "21px" }} />}
            label="Meu limite"
            {...a11yProps(0)}
          />
          <AntTab
            icon={<FeaturedPlayListOutlinedIcon sx={{ fontSize: "21px" }} />}
            label="Pedidos"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
    </Box>
  );
}
