import { useState, useEffect } from "react";
import MenuDashboard, { Paginas } from "../../../components/MenuDashboard";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Skeleton } from "@mui/material";
import parse from "html-react-parser";

import useAlert from "../../../hooks/useAlert";
import { useAuth } from "../../../hooks/AuthContext";
import api from "../../../api";
import useStyles from "./styles";

export function AlterarSenha() {
  const { user } = useAuth();
  const classes = useStyles();
  const {
    Alert,
    showAlertSuccess: showSuccess,
    showAlertError: showError,
  } = useAlert();
  const initialState = {
    email: "",
    senhaNova: "",
    senhaAtual: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [regrasSenha, setRegrasSenha] = useState("");
  const [showPasswordNovo, setShowPasswordNovo] = useState(false);
  const [showPasswordAtual, setShowPasswordAtual] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function buscaRegraSenha() {
      const responseRegraSenha = await api.get(
        "/cliente/GetTextoRegrasComposicaoSenha"
      );
      if (!responseRegraSenha.data.errorMessage) {
        setRegrasSenha(responseRegraSenha.data.data);
        setLoading(false);
      } else {
        setErrors(responseRegraSenha.data.errorMessage);
        showError(responseRegraSenha.data.errorMessage);
        setLoading(false);
      }
    }
    buscaRegraSenha();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await api.post("/cliente/AlterarSenha", {
      email: user.username,
      senhaAtual: formData.senhaAtual,
      senhaNova: formData.senhaNova,
    });

    if (!response.data.errorMessage) {
      showSuccess("Senha alterada com sucesso");
      setLoading(false);
    } else {
      setErrors(response.data.errorMessage);
      showError(response.data.errorMessage);
      setLoading(false);
    }
  };

  const handleClickShowPasswordNovo = () => {
    setShowPasswordNovo(!showPasswordNovo);
  };

  const handleClickShowPasswordAtual = () => {
    setShowPasswordAtual(!showPasswordAtual);
  };

  return (
    <>
      <Alert />
      <MenuDashboard pagina={Paginas.AlterarSenha}>
        <Grid
          xs={12}
          lg={6}
          className={classes.Grid}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} md={6} lg={6} className={classes.limite}>
            <Typography
              variant="h6"
              style={{
                marginBottom: 20,
                alignItems: "center",
                display: "flex",
                padding: 10,
              }}
            >
              <LockOutlinedIcon className={classes.icon} />
              Alterar senha
            </Typography>

            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              {/* <TextField
                id="outlined-required"
                label="Senha Atual"
                defaultValue=""
              /> */}

              <FormControl
                className={classes.Input}
                sx={{ m: 1, mb: 5 }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Senha Atual
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPasswordAtual ? "text" : "password"}
                  value={formData.senhaAtual}
                  onChange={handleChange}
                  name="senhaAtual"
                  required={true}
                  disabled={loading}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordAtual}
                        edge="end"
                      >
                        {showPasswordAtual ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha Atual"
                />
              </FormControl>

              <FormControl
                className={classes.Input}
                sx={{ m: 1 }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Nova senha
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPasswordNovo ? "text" : "password"}
                  value={formData.senhaNova}
                  onChange={handleChange}
                  name="senhaNova"
                  disabled={loading}
                  required={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordNovo}
                        edge="end"
                      >
                        {showPasswordNovo ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Nova Senha"
                />
                <>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      sx={{ mt: 1, mb: 2 }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ padding: 10 }}
                    >
                      {parse(regrasSenha)}
                    </Typography>
                  )}
                </>

                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={41}
                    sx={{ mt: 3, mb: 2, borderRadius: 1 }}
                  />
                ) : (
                  <Button
                    style={{ marginTop: 35 }}
                    variant="contained"
                    type="submit"
                    onClick={handlerSubmit}
                  >
                    Salvar
                  </Button>
                )}
              </FormControl>
              <br></br>
            </Box>
          </Grid>
        </Grid>
      </MenuDashboard>
    </>
  );
}
