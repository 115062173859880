import api from "./index";
import { configurationVariables } from "../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

// ?############################ Saldos ######################################

const response = { isSuccess: false, errorMessage: "", data: [] };

export const getSaldoCLiente = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `operacaoSaldoMN/GetSaldoByIDCliente?idCliente=${idCliente}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RHGT2");
  }
};

export const addSaldo = async (formData) => {
  try {
    const responseData = await api.post("/operacaoSaldoMN/Insert", null, {
      params: {
        valorReais: formData?.valorReais,
        idTipoOperacaoSaldo: formData?.idTipoOperacaoSaldo,
        observacao: formData?.observacao,
        idBancoNacionalCanalBancario: formData?.idBancoNacionalCanalBancario,
        idCliente: formData?.idCliente,
      },
    });

    return {
      status: responseData.status,
      errorMessage: response.errorMessage,
      data: response.data.data,
    };
  } catch (error) {
    throw new Error(error + " RIO06");
  }
};

export const SaqueSaldo = async (formData) => {
  try {
    const { data: response } = await api.post("/operacaoSaldoMN/Insert", null, {
      params: {
        valorReais: formData?.valorReais,
        idTipoOperacaoSaldo: formData?.idTipoOperacaoSaldo,
        observacao: formData?.observacao,
        idDadoBancarioCliente: formData.idDadoBancarioCliente,
        idCliente: formData?.idCliente,
      },
    });

    return response;
  } catch (error) {
    throw new Error(error + " RIO06");
  }
};

export const getSaldoClienteByIdMoeda = async (idCliente, idMoeda = 0) => {
  const { data: clienteSaldo, status } = await api.get(
    `/operacaoSaldo/GetSaldoByIDCliente`,
    {
      params: {
        idCliente: idCliente,
        idMoeda: idMoeda,
      },
    }
  );

  return clienteSaldo.data;
};

//!usado apenas no react-query
export async function getTipoOperacaoSaldo() {
  try {
    const response = await api.get("tipoOperacaoSaldoMNController/GetAll");
    return response.data.data;
  } catch (error) {
    throw new Error(error + "R0UMD");
  }
}

//!usado apenas no react-query
export const getAllOperacoesByIDClient = async (idCliente) => {
  try {
    const response = await api.get(
      `operacaoSaldoMN/GetAll?idCliente=${idCliente}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error + "R0HJD");
  }
};

export const getBancoNacionalCanalBancario = async () => {
  try {
    const { data: response } = await api.get(
      "BancoNacionalCanalBancario/GetPermiteAdicaoSaldoMN"
    );
    return response;
  } catch (error) {
    throw new Error(error + "R0UMD");
  }
};

//?=================================== documento cliente ================================
export const getDocumentosCliente = async (idCliente, pagina) => {
  try {
    const { data: response } = await api.get(
      `documentoCliente/GetByIDCliente?idCliente=${idCliente}&pagina=${pagina}`
    );
    return response;
  } catch (error) {
    throw new Error(error + "R71DF");
  }
};

export const addDocumentoCliente = async (formData) => {
  try {
    const { data: response } = await api.post("/documentoCliente/Insert", {
      idCliente: formData?.idCliente,
      nomeArquivo: formData?.nomeArquivo,
      base64: formData?.base64,
      idTipoDocumento: formData?.idTipoDocumento,
      tamanho: formData?.tamanho,
      contentType: formData?.contentType,
    });

    // const { data: response } = await api.post("/documentoCliente/Insert", formData);

    return response;
  } catch (error) {
    throw new Error(error + " R141F");
  }
};

//?=================================== tipos documento cliente ================================
export const getTiposDocumentoCliente = async () => {
  try {
    const { data: response } = await api.get("tipoDocumento/GetAll");
    return response;
  } catch (error) {
    throw new Error(error + "R819F");
  }
};

//?=================================== cadastro autenticação ================================

export const autenticacao = async (preCliente) => {
  try {
    const { status, data: response } = await api.post(
      "cadastro/PreCadastroAutenticar",
      preCliente
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " 12AA1");
  }
};

//?=================================== Tipos Mercado ================================
export const getTiposMercado = async () => {
  try {
    const { status, data: response } = await api.get(`/TipoMercado/GetAll`);

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RFGF");
  }
};

//?=================================== forma pagamento ================================
export const sendFeedback = async (formData) => {
  try {
    const responseData = await api.post("/feedBack/SendFeedback", null, {
      params: {
        idCliente: formData.idCliente,
        tipoFeedback: formData.tipoFeedback,
        comentario: formData.comentario,
        base64Print: formData?.base64Print,
      },
    });

    return {
      status: responseData.status,
      errorMessage: response.errorMessage,
      data: response.data.data,
    };
  } catch (error) {
    throw new Error(error + " RIO06");
  }
};

//?=================================== get tipos cadastro permitidos ================================
export const getTiposCadastroPermitidos = async () => {
  try {
    const { data: response } = await api.get(
      `/TipoCadastroPermitido/GetTiposPermitidos?isApp=false`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " RE2FA");
  }
};

//?=================================== get logo ================================
export const getLogo = async () => {
  try {
    const { data: response } = await api.get(
      `/DadoInstitucional/GetLogo?idHotsite=${idHotsite}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " R12DA");
  }
};
