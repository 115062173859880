import { GET_SALDO } from '../types';


const initialState = {
   id: 0,
   idCliente: 0,
   moeda: {},
   saldo: 0,
   saldoPrevisto: 0,
}


const saldoReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_SALDO:
         return {
            id: action.payload.id,
            moeda: { ...action.payload.moeda },
            saldo: action.payload.saldo,
            saldoPrevisto: action.payload.saldoProvisorio,

         };
      default:
         return state;

   }
}

export default saldoReducer;