import React, { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/AuthContext";

const AuthRoute = ({ children }) => {
  const { user } = useAuth();

  if (user !== null) {
    return <Navigate to="/MinhaConta" replace />;
  }
  return children;
};

export default AuthRoute;
