import { makeStyles } from "@mui/styles";


const cardStyle = makeStyles((theme) => ({
   root: {
      position: 'absolute !important',
      top: '0',
      left: '0',
      width: '110%',
      height: '100% !important',
      overflow: 'auto !important',
      backgroundColor: '#fff'
   },
   pais: {
      display: "flex",
      padding: 16,
      marginBottom: 20,
      alignItems: "center",
      borderRadius: 4,
      border: '1px solid #eee',
      cursor: "pointer",
      backgroundColor: "#fcfcfc",
      justifyContent: "space-between",
   },

   disabled: {
      cursor: 'no-drop !important',
   }
}))

export default cardStyle;