
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    criar: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft:15,
        paddingRight:15,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft:15,
        paddingRight:15,
      },
      [theme.breakpoints.up("lg")]: {
        margin: "auto !important",
        
        width: "33em !important",
        maxWidth: "40em !important",
      },
    },
    Titulo: {
      fontWeight: 700,
      paddingBottom: "20px",
      color: "#555",
      paddingTop:30,
    },
    Subtitulo: {
      fontWeight: "normal",
      color: "#777",
      marginBottom: 20,
    },
    Item: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "20px",
    },
  }));
  
  export default useStyles;