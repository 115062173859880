import Grid from "@mui/material/Grid";
import useStyles from "./styles";

export default function EspacoConteudoLateralDireito(props) {
  const classes = useStyles();

  return (
    <Grid
      alignItems="stretch"
      container
      xs={12}
      sm={8}
      md={8}
      direction="row"
      className={classes.box}
    
    >
      {props.children}
    </Grid>
  );
}
