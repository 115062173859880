import api from "../index";

export const getTermoUsoCadastro = async (code) => {
    try {
        const { data: response } = await api.get(`/Cadastro/TermoUsoCadastro`);
        return response.data;
    } catch (error) {
        throw new Error(error + " R46C0");
    }
};
