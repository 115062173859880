import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  Modal,
  Box,
  Typography,
  Divider,
  Tabs,
  Tab,
  Grid,
  Skeleton,
} from "@mui/material";

import {
  Login as LoginIcon,
  Logout as LogoutIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  MonetizationOn as MonetizationOnIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

import { AdicaoSaldo, SaqueSaldo } from "../../../../components";
import OperarAtivos from "../../../../components/OperarAtivos";
import ControlledAccordions from "../../../../components/ControlledAccordions";
import MenuDashboard, { Paginas } from "../../../../components/MenuDashboard";
import { useAuth } from "../../../../hooks/AuthContext";
import {
  getTipoOperacaoSaldo,
  getSaldoCLiente,
} from "../../../../api/chamadasApi";
import { CLIENTESALOD } from "../../../../util/typesReactQuery";
import useAlert from "../../../../hooks/useAlert";
import { formatMoneyInString } from "../../../../util/helpers";
import customStyles from "./style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function Criptomoeda() {
  const { user } = useAuth();
  const classes = customStyles();
  const { Alert, showAlertError, showAlertSuccess } = useAlert();

  const { data: dataTipoOperacao } = useQuery(["teste"], getTipoOperacaoSaldo, {
    staleTime: 1000 * 300,
    refetchOnWindowFocus: false,
  });
  //Pega o saldo do cliente
  const { data: clienteSaldos } = useQuery(
    CLIENTESALOD,
    () => getSaldoCLiente(user.id),
    {
      onSuccess: (data) => {
        if (!data || !data.data) {
          showAlertError(
            "Opa, houve um erro, informe ao suporte Tecnico: Não foram encontrado nenhum cadastro de saldo "
          );
          return;
        }

        if (data.status !== 200) {
          showAlertError("Algo deu errado por favor tente mais tarde - R34RF");
        }

        if (data.errorMessage) {
          showAlertError(
            "Opa, houve um erro, informe ao suporte Tecnico: " +
              data.errorMessage
          );
          return;
        }
      },
    }
  );

  const [operacaoDesejada, setOperacaoDesejada] = useState(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const handlerShowModal = (tipo) => (event) => {
    event.preventDefault();
    setOpen(!open);
    setOperacaoDesejada(tipo);
  };
  const handleClose = () => setOpen(false);

  return (
    <MenuDashboard pagina={Paginas.Cripto}>
      <Alert />
      <Grid className={classes.BlocoCripto} container>
        <Grid item xs={12} className={classes.Saldo}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 500,
                alignItems: "center",
                display: "flex",
                padding: 10,
              }}
            >
              Total
            </Typography>
            {clienteSaldos && clienteSaldos.data ? (
              <Box>
                <Button
                  variant="text"
                  size="small"
                  LinkComponent={Link}
                  to="/Historico"
                >
                  Histórico
                  <ArrowForwardIcon fontSize="small" />
                </Button>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 500,
                    alignItems: "center",
                  }}
                >
                  {formatMoneyInString(clienteSaldos?.data[0]?.saldo)}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  style={{
                    fontWeight: "normal",
                    alignItems: "center",
                  }}
                >
                  Saldo em aprovação:{" "}
                  {formatMoneyInString(clienteSaldos?.data[0]?.saldoProvisorio)}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
              </Box>
            )}
          </Box>
          <Divider />

          <Box className={classes.Options}>
            <Button
              onClick={handlerShowModal("ADD")}
              className={classes.Options}
              variant="contained"
              startIcon={<LoginIcon />}
            >
              Depositar
            </Button>
            <Button
              onClick={handlerShowModal("RET")}
              className={classes.Options}
              color="error"
              variant="outlined"
              startIcon={<LogoutIcon />}
            >
              Saque
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }} className={classes.Saldo}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 500,
                alignItems: "center",
                display: "flex",
                padding: 10,
              }}
            >
              Criptos
            </Typography>
            <Box>
              <Typography
                variant="body"
                color="text.secondary"
                style={{
                  fontWeight: 500,
                  alignItems: "center",
                }}
              >
                Total
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 500,
                  alignItems: "center",
                }}
              >
                0
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                style={{
                  fontWeight: "normal",
                  alignItems: "center",
                }}
              >
                Saldo em aprovação: 0,00
              </Typography>
            </Box>
          </Box>
          <Divider />

          <Box className={classes.Options}>
            <Button
              href="/Criptomoeda/Ativos"
              className={classes.Options}
              variant="contained"
              startIcon={<CurrencyBitcoinIcon />}
            >
              Meus ativos
            </Button>
            <Button
              href="/Criptomoeda/ComprarCripto"
              className={classes.Options}
              variant="outlined"
              startIcon={<MonetizationOnIcon />}
            >
              Comprar Cripto
            </Button>
          </Box>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs
            style={{ width: "100%", marginTop: 35 }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Preços" {...a11yProps(0)} />
            <Tab label="Ativos" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OperarAtivos sx={{ mt: 15 }} />
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={1}>
          <ControlledAccordions />
        </TabPanel>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.CompleteModal} sx={style}>
          {operacaoDesejada === "ADD" && (
            <AdicaoSaldo
              tipoOperacaoSaldo={
                dataTipoOperacao?.find((tipo) => tipo.id === 1) ?? 1
              }
              modalControl={setOpen}
              showAlertError={showAlertError}
              showAlertSuccess={showAlertSuccess}
            />
          )}
          {operacaoDesejada === "RET" && (
            <SaqueSaldo
              tipoOperacaoSaldo={
                dataTipoOperacao?.find((tipo) => tipo.id === 2) ?? 1
              }
              valueSaldo={clienteSaldos?.data[0]?.saldo}
              modalControl={setOpen}
              showAlertError={showAlertError}
              showAlertSuccess={showAlertSuccess}
            />
          )}
        </Box>
      </Modal>
    </MenuDashboard>
  );
}
