import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  containerTable: {
    "& .MuiTableCell-root": {
      fontSize: ".9rem",
      textAlign: "start",
    },
  },

  ListItem: {
    backgroundColor: "#fcfcfc !important",
    margin: 2,
  },

  emfaseText: {
    "& span": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem !important",
      fontWeight: "600 !important",
    },
  },

  ButtonComplete: {
    borderRadius: "8px !important",
    backgroundColor: theme.palette.primary.main + "!important",
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      opacity: 0.8,
    },
  },

  fontBold: {
    fontWeight: 900,
  },
  Filter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  Detalhes: {
    background: "#fcfcfc",
    padding: 15,
    border: "1px solid #ccc",
    minHeight: "60vh",
  },
  Codigo: {
    color: theme.palette.primary.main,
  },
  Card: {
    display: "flex",
    boxShadow: "none !important",
    width: "100%",
    height: "6em",
    borderRadius: "12px",
    background: "#fff !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      boxShadow: "none !important",
      width: "100%",
      height: "3em",

      borderRadius: "12px",
      background: "#fff !important",
    },
  },
}));

export default useStyle;
