export const StepComEntrega = [
    {
        label: "Vitrine",
        description: "",
    },
    {
        label: "Entrega",
        description: "",
    },
    {
        label: "Forma de pagamento",
        description: ``,
    },

    {
        label: "Finalizar",
        description: ``,
    },
];

export const StepSemEntrega = [
    {
        label: "Vitrine",
        description: "",
    },
    {
        label: "Forma de pagamento",
        description: ``,
    },

    {
        label: "Finalizar",
        description: ``,
    },
]