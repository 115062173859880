import { useState, useEffect } from "react";
import { Box, LinearProgress, Skeleton } from "@mui/material";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import useAlert from "../../hooks/useAlert";
import { intervalToDuration, compareAsc } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Subtitulo } from "../";
import useTimerCarrinho from "../../hooks/useTimerCarrinho";
import {Style} from "./style";

const Timer = ({
  dataCadastro = null,
  dataExpiracao = null,
  active = false,
  caminhoRedirect = "/",
  isLoading,
}) => {
  const { Alert, showAlertInfo } = useAlert();
  const navigation = useNavigate();
  const classes = Style();

  const { infoText, errors, minutosRestantes, segundosRestantes, tempoPorcent } = useTimerCarrinho(dataExpiracao, active);

  const redirect = () => {
    navigation(caminhoRedirect, { replace: false });
  }

  useEffect(() => {
    if (!!infoText) {
      showAlertInfo(infoText);
      setTimeout(() => {
        redirect();
      }, 1500)
    }

  }, [infoText])

  return (
    <>
      <Alert />
      {!isLoading && dataExpiracao ? (
        <Box className={classes.Box} sx={{ width: "100%", marginBottom: "1em" }}>
          <Subtitulo sx={{alignItems:'center', display:"flex", justifyContent:"center", fontSize:'13px', mb:1}} className={classes.Subtitulo} align="left">
            <AccessAlarmsIcon fontSize="small" sx={{ mr:1}} /> As taxas vigentes expiram em cerca de{" "}
            {minutosRestantes > 0 &&
              (minutosRestantes > 1
                ? minutosRestantes + " Minutos"
                : minutosRestantes + " Minuto")}
            {minutosRestantes == 0 &&
              (segundosRestantes > 1
                ? segundosRestantes + " Segundos"
                : segundosRestantes + " Segundo")}
          </Subtitulo>
          <LinearProgress
            variant="determinate"
            value={tempoPorcent}
            color={
              tempoPorcent > 30 && tempoPorcent < 60
                ? "warning"
                : tempoPorcent <= 30
                  ? "error"
                  : "primary"
            }
          />
        </Box>
      ) : (
        <>
          <>
            <Skeleton animation="wave" width={"100%"} height={30} />
          </>
        </>
      )}
    </>
  );
};

export default Timer;
