import { useState } from "react";
import PontoRetirada from "../../../../../components/PontoRetirada";

export default function RetiradaLoja({ onChange, idPraca }) {
  const [idPontoRetirada, setIdPontoRetirada] = useState(0);

  const handlerChange = (PontoRetirada) => (event) => {
    setIdPontoRetirada(PontoRetirada?.id);
    onChange(PontoRetirada);
  };

  return (
    <PontoRetirada
      idPraca={idPraca}
      selectedID={idPontoRetirada}
      setSelectedID={handlerChange}
    />
  );
}
