
import { Id } from "react-flags-select";
import api from "../";
import TiposOrigem from '../../util/typesTipoOrigem';

import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

//! Pega Todos os Pedidos
export const getPedidoComercialByID = async (
   idCliente,
   idPedido
) => {
   try {
      if (idPedido && idPedido > 0) {
         const { status, data: response } = await api.get(
            "/PedidoComercial/GetByID",
            {
               params: {
                  idCliente: idCliente,
                  idHotsite: idHotsite,
                  idPedido: idPedido,
               },
            }
         );

         return {
            data: response.data,
            errorMessage: response.errorMessage,
         };
      }
   } catch (error) {
      throw new Error(error + " RFGF");
   }
};

export const getPedidosComercialByIDCliente = async (
   idCliente,
   pagina = 1
) => {
   const { data: pedidos, status } = await api.get(
      `/pedidoComercial/GetPedidosComercialByIDCliente`,
      {
         params: {
            idCliente: idCliente,
            idHotsite: idHotsite,
            pagina: pagina,
         },
      }
   );

   return pedidos.data;
};

//! Pega o pedido de remessa ou recebimento do cliente
export const GetPedidoByID = async (idCliente, idPedido) => {

   try {
      const { data: response, status } = await api.get(`/PedidoComercial/GetByID?idCliente=${idCliente}&idHotsite=${idHotsite}&idPedido=${idPedido}`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "R0E96");
   }
}

//! Limpa o carrinho de remessa
export const LimpaCarrinho = async (idCliente) => {
   try {
      const { data: response } = await api.post(`/CarrinhoRemessa/LimparCarrinho?idCliente=${idCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}`)

      return {
         errorMessage: response.errorMessage,
      }
   } catch (error) {
      throw new Error(error + "RCAAE");
   }
}


