import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Button: {
    padding: "8px !important",
    fontSize: "12px !important",
    borderRadius: "4px !important",
    width: "100%",
    color: "#999 !important",
  },
  img: {
    width: 24,
    marginRight: "20px",
  },
}));

export default useStyles;
