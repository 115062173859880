import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  Bandeira: {
    width: 20,
    marginRight: 6,
    marginLeft: -4,
  },

  bandeiraOuro: {
    width: 25,
    marginRight: 6,
    marginLeft: -4,
  },

  selectDisabled: {
    cursor: "not-allowed",
  },
}));

export default styles;
