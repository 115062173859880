
import NumberFormat from "react-number-format";
import FormCustom from './index';

const FormMoedaCustom = ({ value, prefix, onChange, size, variant, onblur, casasDecimais, fullwidth, disabled }, props) => {
   return (
      <>
         <NumberFormat
            value={value}
            displayType={'text'}
            prefix={prefix ?? ""}
            decimalScale={casasDecimais ?? 2}
            decimalSeparator=","
            thousandSeparator="."
            allowNegative={false}
            allowEmptyFormatting={true}
            allowLeadingZeros={true}
            className={props.className}
            disabled={disabled}
            renderText={(value) => (
               <FormCustom
                  size={size}
                  className={props.className}
                  variant={variant}
                  fullWidth={fullwidth ?? false}
                  value={value}
                  onChange={onChange}
                  onblur={onblur ? onblur : null}
               />
            )}
         />
      </>
   );
}

export default FormMoedaCustom
