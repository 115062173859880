import { MenuDashboard, PaginasMenuDashboard } from "../../../components";
import { Grid, Divider, Typography, Box } from "@mui/material";
import DeviderCustom from "../../../components/DeviderCustom";
import CircleIcon from "@mui/icons-material/Circle";
import useStyles from "./style";
import Pedidos from "../../../components/Pedidos";


export function MeusPedidos() {
  const classes = useStyles();

  return (
    <>
      <MenuDashboard pagina={PaginasMenuDashboard.MeusPedidos}>
        <Grid
          className={classes.Container}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item className={classes.Grid} xs={9} md={8}>
            <Box className={classes.Box}>
              <Typography className={classes.Titulo} variant="h6">
                Pedidos
              </Typography>
              <Box className={classes.Status}>
                <Typography className={classes.Titulo} variant="body1">
                  <CircleIcon color="success" fontSize="small" />
                  Concluído ou em Andamento
                </Typography>
                <Typography className={classes.Titulo} variant="body1">
                  <CircleIcon sx={{m:1}} fontSize="small" color="error" />
                  Cancelado
                </Typography>
             
              </Box>
            </Box>
            <Divider />
            <DeviderCustom />
            <Pedidos isFilter={true} />
          </Grid>
        </Grid>
      </MenuDashboard>
    </>
  );
}
