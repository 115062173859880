import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  MenuItem: {
    display: "block !important",
  },

  TitleNotificacao: {
    fontWeight: "500 !important",
  },
  Menu: {
    backgroundColor: "#00000030",
  },
  customIconButton: {
    transition: 'color 200ms ease-in-out',
    "&:hover": {
      color: theme.palette.primary.main,
    }
  }
}));

export default useStyles;
