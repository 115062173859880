import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Modal from "../Modal/Modal";
import { Link } from "@mui/material";
import StyleTermo from "./StyleTermo";
import parseHtml from "html-react-parser";

export default function TermosdeUso({
  isAceite = false,
  titulo,
  setIsAceite,
  children,
  modalText = "Termos de Uso da Empresa",
}) {
  const [showModal, setShowModal] = useState(false);
  const classes = StyleTermo();

  const handlerOpenModal = (event) => {
    event.preventDefault();
    setShowModal(!showModal);
  };

  const handlerChangeValue = (event) => {
    event.preventDefault();
    setIsAceite(!isAceite);
  };

  return (
    <Box display="flex" justifyContent="start">
      <Modal
        show={showModal}
        hasButtonClose={true}
        close={() => {
          setShowModal(!showModal);
        }}
      >
        <h5>{titulo}</h5>
        <hr />
        {parseHtml(modalText ?? "Gerando Termo Responsabilidade")}
      </Modal>
      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        style={{ marginLeft: -10}}
        className={classes.container}
      >
        <Checkbox
          size="small"
          checked={isAceite}
          onClick={handlerChangeValue}
        />
        <Typography variant="body2">
          <Link variant="body2" underline="none" onClick={handlerOpenModal}>
            {children}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
