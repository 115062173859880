import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   Saldo: {
      height: 'auto',
      padding: 20,
      borderRadius: 8,

      background: "#fff",
      marginTop: '0',
      [theme.breakpoints.up("sm")]: {},
   },
   Options: {
      textTransform: 'capitalize !important',
      marginTop: 30,
      marginRight: "6px !important",

   },
   blocoAtivo: {
      display: 'flex',
      padding: 15,
      borderRadius: 3,
      border: '1px solid #ddd',
      marginTop: 20,
   },
   CompleteModal: {
      width: 500,
      [theme.breakpoints.down("sm")]: {
         width: "100vw",
      },
   },
   BlocoCripto: {
      background: "#fcfcfd",
      padding: 35,
      [theme.breakpoints.down("sm")]: {
         padding: 0,
      },
   }
}));

export default useStyles;