
import VitrineSelectedOrdem from "../../../../../components/Vitrine/TransferenciaInternacional/Recebimento";
import VitrineDadosOrdem from "../../../../../components/Vitrine/TransferenciaInternacional/Recebimento/VitrineOrdem";
import { MenuDashboard, PaginasMenuDashboard, SubMenusDashBoard, BreadCrumbsPaginas } from "../../../../../components";
import Box from '@mui/material/Box';
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Hidden } from '@mui/material';
import { useState } from "react";

export function Vitrine() {

  const [selectedOrdem, setSelectedOrdem] = useState("");

  const breadCrumps = [
    {
      label: "Remessas Internacionais",
      icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    },
    {
      label: "Receber dinheiro do exterior",
      icon: <DoubleArrowIcon sx={{ mr: 0.5, transform: "rotate(180deg)" }} fontSize="inherit" />
    }
  ]

  return (
    <>
      <MenuDashboard pagina={PaginasMenuDashboard.Remessas} subMenuSelecionado={SubMenusDashBoard.Recebimento}>
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>
        {!!selectedOrdem ?
          <VitrineDadosOrdem selectedOrdem={selectedOrdem} setSelectedOrdem={setSelectedOrdem} />
          :
          <VitrineSelectedOrdem setSelectedOrdem={setSelectedOrdem} />
        }
      </MenuDashboard>
    </>
  );
}
