import Turismo from "../../../../../components/Vitrine/Turismo";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Box, Hidden } from "@mui/material";
import TiposPedido from "../../../../../util/typesTipoPedido";
import {
  BreadCrumbsPaginas,
  MenuDashboard,
  PaginasMenuDashboard,
  SubMenusDashBoard,
} from "../../../../../components";
export default function VitrineVendaaCartao() {
  const breadCrumps = [
    {
      label: "Cartão Internacional",
      icon: <CreditCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Comprar Cartão",
      icon: (
        <PaymentsIcon
          sx={{ mr: 0.5, transform: "rotate(180deg)" }}
          fontSize="inherit"
        />
      ),
    },
  ];

  return (
    <>
      <MenuDashboard
        pagina={PaginasMenuDashboard.Cartao}
        subMenuSelecionado={SubMenusDashBoard.CompraCartao}
      >

        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Turismo
          idTipoOperacao={TiposPedido.VendaEspecie}
          isEspecie={false}
          isCompraNovoCartao={true}
        />

      </MenuDashboard>

    </>
  );
}
