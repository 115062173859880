import { useState } from "react";
import MenuDashboard, { Paginas } from "../../../components/MenuDashboard";
import Grid from "@mui/material/Grid";
import { Typography, Box, Hidden, Divider } from "@mui/material";
import useStyles from "./styles";
import {
  LimiteOperacional,
  Saldo,
  Pedidos,
  Carteira,
} from "../../../components";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
export function MinhaConta() {
  const [open, setOpen] = useState(false);

  const handlerShowModal = () => setOpen(!open);

  const classes = useStyles();

  return (
    <>
      <MenuDashboard pagina={Paginas.PaginaInicial}>
        <Hidden smDown>
          <Grid
            container
            className={classes.Grid}
            xs={12}
            xl={8}
            spacing={{ xs: 2, md: 0 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {/* <AlertCadastro /> */}
            <Grid item className={classes.blocoPedidos} xs={7}>
              <Typography variant="h6" sx={{fontWeight:"bold", display:"flex", alignItems:"center"}} color="text"><HomeRoundedIcon color="primary" sx={{mr:2, opacity:0.5}}></HomeRoundedIcon>
                Página Inicial{" "}
              </Typography>
              <Typography sx={{mt:1}} variant="body2" color="text.secondary">
                Aqui você encontra suas últimas atividades e limites.
              </Typography>
              <Grid style={{ display: "flex", marginTop: 30 }}>
                <Saldo />
              </Grid>
              <Divider />
              <Grid className={classes.Bloco}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight:"bold" }}>
                    Últimos pedidos
                  </Typography>
                  

                </Box>
                <Box>
                  
                  <Pedidos isFilter={true} isHome={true} />
                </Box>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem>
    
  </Divider>
            <Grid className={classes.BlocoLimite} item xs={4}>
              <Grid className={classes.Bloco}>
                <LimiteOperacional handlerShowModal={handlerShowModal} />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          {/* <AlertCadastro /> */}
          <Carteira />
        </Hidden>
      </MenuDashboard>
    </>
  );
}
