import { useState } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import DadosBasicos from "../../../../components/Cadastro/DadosBasicos";
import { ButtonCustom } from "../../../../components/Botoes/Button";
import { saveDadosBasicos } from "../../../../api/Cadastro";
import useAlert from "../../../../hooks/useAlert";
import useStyles from "./styles";
import { Box } from "@mui/material";

const DadosPessoais = ({ handlerCancelEdit }) => {
  const { user } = useAuth();
  const classes = useStyles();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [dadosCliente, setDadosCliente] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlerSubimit = async () => {
    try {
      setIsLoading(true);
      const response = await saveDadosBasicos(
        dadosCliente,
        user.idPerfilLimiteOperacional,
        true
      );

      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Alteração salva com sucesso!");
      }
      setIsLoading(false);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  return (
    <>
      <Alert />
      <Grid container sx={{ overflowX: "hidden" }}>
        <Grid
          lg={8}
          container
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid className={classes.Topo}  item>
            <ButtonVoltar
              handleVoltar={handlerCancelEdit}
              mostraTexto={true}
              sx={{ ml: 0, mb: 5 }}
            />
            <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
              Seus dados
            </Typography>
          </Grid>
<Box sx={{pr:1}}>
          <ButtonCustom
            typeButton="primary"
            variant="contained"
            size="small"
            loading={isLoading}
            onClick={handlerSubimit}
          >
            Salvar alterações
          </ButtonCustom>
          </Box>
        </Grid>
        <Grid
          lg={8}
          container
          className={classes.Grid}
          sx={{ ml: 1 }}
          columnSpacing={3}
        >
          <DadosBasicos
            idCliente={user.id}
            idPerfilLimiteOperacional={user.idPerfilLimiteOperacional}
            setDada={setDadosCliente}
            isLoading={isLoading}
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={12}
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end !important",
              width: "100% !important",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DadosPessoais;
