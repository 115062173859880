import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import { ButtonCustom } from "../Botoes/Button";
import FolderIcon from "@mui/icons-material/Folder";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import { useTheme } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import useStyles from "./styles";
import Grid from '@mui/material/Grid'

export default function DocumentoCard({ documento }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          <a className={classes.Card}
            href={documento.base64}
            download={
              documento.descricao === null
                ? documento.tipoDocumento === null
                  ? "Download" + "." + documento.base64.split(";")[0].split("/")[1]
                  : documento.tipoDocumento.descricao +
                  "." +
                  documento.base64.split(";")[0].split("/")[1]
                : documento.descricao + "." + documento.base64.split(";")[0].split("/")[1]
            }
          >
            <Card
              className={classes.Card}
              sx={{
                m: 0,
              }}
            >
              <CardHeader
                avatar={<FolderIcon color="primary" className={classes.Icon} />}
                title={
                  documento.descricao === null
                    ? documento.tipoDocumento === null
                      ? ""
                      : documento.tipoDocumento.descricao
                    : documento.descricao
                }
                subheader={documento.tamanho + " kb"}
                sx={{ width: "100%", }}
              ></CardHeader>

              {documento.base64.split(";")[0].split("/")[1] === "pdf" ||
                documento.base64.split(";")[0].split("/")[1] === "txt" ? (
                <DescriptionIcon />
              ) : documento.base64 !== "" ? (
                <>

                  <Box
                    component="img"
                    src={documento.base64}
                    className={classes.img}
                  />

                </>
              ) : (
                <DescriptionIcon />
              )}

              <CardActions disableSpacing>
                <ButtonCustom variant="text" >
                  <DownloadIcon
                    sx={{
                      fontSize: "1.2rem !important",
                      color: theme.palette.text.primary,
                      mr: theme.spacing(0.7),
                      opacity: "0.5",
                    }}
                  />
                </ButtonCustom>
              </CardActions>
            </Card>
          </a >
        </Grid>
      </Grid>

    </>
  );
}
