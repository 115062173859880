import { makeStyles } from "@mui/styles";

const ip = " !important";

export const Style = makeStyles((theme) => ({
  //  Box:{
  //     position:"fixed",
  //     top:100,
  //     width:"90%" + ip,
  //     [theme.breakpoints.up("sm")]: {
  //         position:"relative",
  //     width:"100%"
  //     }
  //  }
  Subtitulo: {
    fontSize: "12px" + ip,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px" + ip,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
}));
