import { CampoOperacao, Subtitulo } from "../";
import { useQuery } from "react-query";
import { getValorSedex } from "../../api/Endereco";
import { useEffect, useState } from "react";
import { GETVALORSEDEX } from "../../util/typesReactQuery";
import useAlert from "../../hooks/useAlert";
import { useAuth } from "../../hooks/AuthContext";
import { formatAmountDecimaHouse, formatMoneyInString } from "../../util/helpers";
import { Alert, Skeleton } from "@mui/material";

const Sedex = ({ idPraca, isOuro, valorTotalMN }) => {
  const {
    Alert: AlertCustom,
    showAlertSuccess,
    showAlertInfo,
    showAlertError,
  } = useAlert();
  const { user } = useAuth();
  const [cep, setCep] = useState("");
  const [sedexSimulacao, setSedexSimulacao] = useState();
  const [isLoading, setIsloading] = useState(false);

  const { refetch } = useQuery(
    [GETVALORSEDEX, "sedex"],
    () => getValorSedex(cep, idPraca, isOuro, valorTotalMN),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage) {
          setIsloading(false);
          showAlertError(
            `Ops, algo deu errado: Não foi possivel informar o valor da entrega relacionada ao seu CEP - R2AF1 `
          );
        }

        if (data.data.information.length > 0)
          showAlertInfo(data.data.information)

        let valorAcrescentado = (
          data.data.valorFrete +
          data.data.valorFreteSeguro +
          data.data.valorFreteLogistica);
        setSedexSimulacao(valorAcrescentado);
      },
      onError: (error) => {
        showAlertError(`Ops, algo deu errado: ${error} - R5E9A `);
        setIsloading(false);
      },
      onSettled: () => {
        setTimeout(() => {
          setIsloading(false);
        }, 500);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    if (cep.length == 9 && !cep.includes("_")) {
      setIsloading(true);
      refetch();
    }
  }, [cep]);

  const handlerChangeValue = (event) => {
    setCep(String(event.target.value));
  };

  return (
    <>
      <AlertCustom />
      {isLoading ? (
        <Skeleton animation="wave" width={"100%"} height={50} />
      ) : (
        <CampoOperacao
          format="#####-###"
          mask="_"
          fullWidth
          decimalSeparator={false}
          name="sedex"
          value={cep}
          size="small"
          label={"Informe o CEP"}
          onChange={handlerChangeValue}
          enable={true}
        />
      )}

      <br />

      {isLoading ? (
        <Skeleton animation="wave" width={"100%"} height={50} />
      ) : (
        <Subtitulo>
          <Alert severity="info">
            {sedexSimulacao
              ? `Valor da sua entrega é:  ${formatMoneyInString(sedexSimulacao)}`
              : "Aqui será informado o valor da sua simulação, mas primeiro, informe o seu CEP."}{" "}
          </Alert>

          <Alert severity="info">
            O valor do sedex pode sofre alteração de acordo com o valor final da operação.
          </Alert>
        </Subtitulo>
      )}
    </>
  );
};

export default Sedex;
