const TiposItemCarrinho = {
    VendaCartao: 1,
    VendaEspecie: 2,
    RecargaCartao: 3,
    CompraSaldoCartao: 4,
    CompraEspecie: 5,
    RecebimentoExterior: 6,
    RemessaExterior: 7,
    AdicionarSaldo: 8,
    ResgatarSaldo: 9,
    VendaOuro: 10
}

export default TiposItemCarrinho;