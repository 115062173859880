import { useState } from "react";
import BandeiraBrasil from "../../../../assets/images/bandeiras/brasil.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  useTheme,
  Link,
  Skeleton,
  Alert,
} from "@mui/material";

import { Info as InfoIcon } from "@mui/icons-material";

import {
  ButtonAcaoGrande,
  Termo,
  Seletor,
  CampoOperacao,
  TooltipInformacao,
  PositionTooltip,
  CampoImg,
  ListItemVitrineSelecionado,
  Modal,
} from "../../..";

import SeletorMoeda from "../../../Campos/SeletorMoeda";

import { Style } from "./styles";

import useAlert from "../../../../hooks/useAlert/index";

import {
  formatMoneyInString,
  formatMoneyInStringForm,
  verificaPedidoDiferente,
} from "../../../../util/helpers";

import Tarifa from "../../Tarifa";
import { useAuth } from "../../../../hooks/AuthContext";
import { useMutation, useQuery } from "react-query";
import { RemoveItemByIdMoeda } from "../../../../api/Carrinho/Turismo";

import usePedido from "../../../../hooks/usePedido";
import { LimpaCarrinho } from "../../../../api/PedidoComercial";
import TiposItemCarrinho from "../../../../util/typesTipoItemCarrinho";
import TiposPedido from "../../../../util/typesTipoPedido";
import Sedex from "../../../Sedex";
import { Panel } from "rsuite";
import { getTermoResponsabilidade } from "../../../../api/TermoResponsabilidade/index";
import { GETTERMORESPONSABILIDADE } from "../../../../util/typesReactQuery";
import VitrineVendaaCartao from "../../../../pages/Carrinho/Turismo/Vitrine/VitrineVendaCartao";
import ButtonAcaoGrandeOutlined from "../../../Botoes/ButtonAcaoGrande copy";
import { Text } from "html-react-parser";

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export function VitrineBase({
  vitrine,
  itemSelected,
  onChangeItem,
  onChangeValorMe,
  onAddCart,
  itemCarrinho,
  isErrorTermo = false,
  isErrorTermoResp = false,
  onChangePraca,
  onChangeTermo,
  isTermoResponsabilidade,
  isTermoAceite,
  onChangeTermoRespon,
  loadingButton = false,
  setLoadingButton,
  disabledButton,
  onBlurValueChangeItem,
  isCompraNovoCartao = false,
}) {
  const classes = Style();
  const theme = useTheme();
  const {
    Alert: CustomAlert,

    showAlertError,
    showAlertSuccess,
  } = useAlert();

  const { user } = useAuth();
  const { itensPedido: listItemCarrinho } = usePedido();
  const [modalShow, setModalShow] = useState(false);

  const [modalMessage, setModalMessage] = useState("");
  const [showModalVitrine, setShowModalVitrine] = useState(false);
  const [termoResponsabilidade, setTermoResponsabilidade] = useState("");

  const handlerTermoAceite = (aceito) => {
    onChangeTermo(aceito);
  };

  const handlerTermoResponsabilidade = (aceito) => {
    onChangeTermoRespon(aceito);
  };

  const mutationRemoveItemComercial = useMutation(LimpaCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R776E `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2000);
    },
  });

  useQuery([GETTERMORESPONSABILIDADE], () => getTermoResponsabilidade(), {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum termo responsabilidade - RADLH`
        );
        return;
      }
      if (data.errorMessage) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RGE5A`
        );
        return;
      }

      setTermoResponsabilidade(data.data);
    },
    onError: (error) => {
      showAlertError(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - REZM4`
      );
    },
    refetchOnWindowFocus: true,
  });

  const handleManipulationEventAdd = (event) => {

    let text = verificaPedidoDiferente(itemCarrinho, listItemCarrinho);

    if (!!text) {
      setModalMessage(text);
      setModalShow(true)
      return;
    }

    onAddCart(event, false);
  };

  const handlerModalOptions = (res) => (event) => {
    if (res === "sim") {
      setLoadingButton(true);
      mutationRemoveItemComercial.mutate(user.id);
      setTimeout(() => {
        onAddCart(event, false);
        setLoadingButton(false);
      }, 1000);
    }

    setModalShow(false);
  };

  const validaOperacoes = () => {
    if (listItemCarrinho) {
      if (
        listItemCarrinho?.itens.length > 0 &&
        listItemCarrinho?.praca &&
        listItemCarrinho.praca.id == itemCarrinho?.idPraca &&
        listItemCarrinho?.itens?.some(
          (i) =>
            i?.idTipoItemCarrinho != TiposItemCarrinho.RemessaExterior &&
            i?.idTipoItemCarrinho != TiposItemCarrinho.RecebimentoExterior &&
            i?.idTipoOperacaoCarrinho == itemCarrinho.idTipoOperacaoCarrinho &&
            i?.idTipoOperacaoCarrinho != TiposPedido.VendaOuro
        )
      )
        return true;
      else return false;
    }

    return false;
  };

  const validaContemSedex = () => {

    if (isCompraNovoCartao) {
      if (itemCarrinho.idTipoItemCarrinho === TiposItemCarrinho.VendaCartao) {
        let pracaSelecionada = vitrine?.pracas?.find(item => item.id === itemCarrinho.idPraca);

        if (!pracaSelecionada?.dadosSedex?.habilitaEntregaSedexCartao) {
          return false;
        }
        return true;
      }

    }
    return false;
  };


  return (
    <>
      <CustomAlert />
      <Modal
        show={modalShow}
        close={() => setModalShow(false)}
        sx={{ border: "" }}
      >
        <ErrorOutlineIcon
          color="primary"
          fontSize="large"
          sx={{ margin: "auto", width: "100%" }}
        />
        <Typography
          sx={{
            display: "flex",
            alignContent: "center",
            gap: ".3rem",
            textAlign: "center !important",
            mb: 3,
          }}
        >
          {modalMessage}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <ButtonAcaoGrande
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="primary"
            texto="Sim"
            loading={loadingButton}
            Handle={handlerModalOptions("sim")}
          />
          <ButtonAcaoGrandeOutlined
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="error"
            texto="não"
            variant="outlined"
            loading={loadingButton}
            Handle={handlerModalOptions("não")}
          />
        </Box>
      </Modal>
      <Grid className={classes.Carrinho} xs={12}>
        <Grid
          container
          xs={12}
          md={8}
          sm={6}
          lg={6}
          sx={{
            margin: "auto",
            mt: 0,
            alignItems: "center",
            maxWidth: "460px !important",
            display: "flex",
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Item>
              <FormControl className={classes.FormControl} size="small">
                {vitrine && vitrine.pracas ? (
                  <Seletor
                    setSelect={onChangePraca}
                    list={vitrine.pracas}
                    size="small"
                    label="Qual sua região?"
                    variant="outlined"
                    fullWidth={true}
                    value={itemCarrinho.idPraca}
                  />
                ) : (
                  <Skeleton width="auto" height={50} animation="wave" />
                )}
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={7} md={7} lg={7}>
            <Item>
              <CampoOperacao
                className={classes.TextField}
                casasDecimais={2}
                prefix={""}
                size="normal"
                fullwidth
                enable={true}
                variant="outlined"
                value={itemSelected.valorMe}
                onChange={onChangeValorMe}
                onBlur={onBlurValueChangeItem}
              />
            </Item>
          </Grid>
          <Grid item xs={5} md={5} lg={5}>
            <Item sx={{ display: "flex" }}>
              {vitrine &&
                vitrine.itensVitrine &&
                vitrine.itensVitrine.length > 0 && (
                  <FormControl sx={{ minWidth: "100%" }}>
                    <SeletorMoeda
                      list={vitrine.itensVitrine}
                      value={itemCarrinho.idItemVitrine}
                      setSelect={onChangeItem}
                      size="normal"
                      fullWidth={true}
                    />
                  </FormControl>
                )}
            </Item>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Item>
              <Tarifa
                casasDecimais={itemSelected.moeda.numeroCasaDecimais}
                impostos={itemCarrinho.impostos}
                simbolo={itemSelected.moeda.simbolo}
                taxa={itemCarrinho.valorTaxaAplicada}
              />
            </Item>
          </Grid>
          <Grid item xs={7} md={7} lg={7} sx={{ mt: 3 }}>
            <Item>
              <CampoOperacao
                className={classes.TextField}
                label="Valor em reais"
                value={formatMoneyInStringForm(itemCarrinho.valorTotalMN)}
                id="reddit-input"
                variant="outlined"
                size="normal"
                fullwidth
                enable={false}
                casasDecimais={2}
              />
            </Item>
          </Grid>
          <Grid xs={5} md={5} lg={5} sx={{ mt: 3 }}>
            <Item sx={{ pb: 0 }}>
              {" "}
              <CampoImg
                sx={{ mt: 0 }}
                disabled
                fullWidth={false}
                className={classes.select}
                size="normal"
                value="BRL"
                id="reddit-input"
                variant="outlined"
                img={BandeiraBrasil}
              ></CampoImg>
            </Item>
          </Grid>
          <Grid sx={{ mt: 1, mb: 0 }} xs={12} md={12} lg={12}>
            {" "}
            <Typography
              className={classes.dFlex}
              sx={{ ml: 2 }}
              variant="body1"
            >
              <TooltipInformacao
                sx={{ marginRight: 1, marginLeft: 2 }}
                positionStart={PositionTooltip.left}
                descricao="O Valor Efetivo Total (VET) representa o custo de uma operação de câmbio em reais por moeda estrangeira, englobando a taxa de câmbio, as tarifas e tributos incidentes sobre essa operação."
              >
                <InfoIcon
                  sx={{ fontSize: 20, mr: 1 }}
                  color="primary"
                ></InfoIcon>
              </TooltipInformacao>{" "}
              Com isso, 1 {itemSelected.moeda.simbolo} ={" "}
              {formatMoneyInString(itemCarrinho.valorVet)} (VET)
            </Typography>
          </Grid>

          <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12} lg={12}>
            {validaContemSedex() && (
              <>
                <Panel
                  header="Simule uma entrega via sedex"
                  collapsible
                  bordered
                >
                  <Sedex idPraca={itemCarrinho.idPraca} isOuro={itemCarrinho.idTipoOperacaoCarrinho == TiposPedido.VendaOuro} />
                </Panel>
              </>
            )}
          </Grid>

          <Grid xs={12} md={12} lg={12}>
            <Grid sx={{ mt: 1, mb: 1 }}>
              <ButtonAcaoGrande
                sx={{ zIndex: theme.zIndex.mobileStepper }}
                loading={loadingButton}
                variant="contained"
                Handle={handleManipulationEventAdd}
              >
                + Adicionar Moeda no carrinho
              </ButtonAcaoGrande>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ mt: 1, mb: 1, maxWidth: "500px" }}>
          {validaOperacoes() && (
            <>
              <ListItemVitrineSelecionado
                carrinho={listItemCarrinho}
                loadingButton={loadingButton}
                setLoadingButton={setLoadingButton}
                onAddCart={onAddCart}
              />

              <Grid xs={12} md={12} lg={12} sx={{ ml: 1 }}>
                <Box
                  className={
                    isTermoResponsabilidade
                      ? [classes.AlertaTermos, classes.isAceite]
                      : isErrorTermoResp
                        ? [classes.AlertaTermos, classes.isErrorTermo]
                        : classes.AlertaTermos
                  }
                >
                  <Termo
                    modalText={termoResponsabilidade?.conteudoHtml}
                    isAceite={isTermoResponsabilidade}
                    titulo="Termos de Responsabilidade"
                    setIsAceite={handlerTermoResponsabilidade}
                  >
                    <Typography color="text.secondary" variant="subtitle1">
                      {" "}
                      Ao continuar, você aceita os
                      <Link style={{ cursor: "pointer" }}>
                        {" "}
                        Termos de Responsabilidade.{" "}
                      </Link>
                    </Typography>
                  </Termo>
                </Box>
              </Grid>

              <Grid xs={12} md={12} lg={12} sx={{ ml: 1 }}>
                <Box
                  className={
                    isTermoAceite
                      ? [classes.AlertaTermos, classes.isAceite]
                      : isErrorTermo
                        ? [classes.AlertaTermos, classes.isErrorTermo]
                        : classes.AlertaTermos
                  }
                >
                  <Termo
                    modalText={vitrine?.termoUso}
                    isAceite={isTermoAceite}
                    titulo="Termos de Uso"
                    setIsAceite={handlerTermoAceite}
                  >
                    <Typography color="text.secondary" variant="subtitle1">
                      {" "}
                      Ao continuar, você aceita os
                      <Link style={{ cursor: "pointer" }}> Termos de Uso </Link>
                      da nossa empresa brasileira autorizada pelo Banco Central
                      do Brasil.
                    </Typography>
                  </Termo>
                </Box>
              </Grid>

              <Grid sx={{ ml: 2, mr: 2 }}>
                <ButtonAcaoGrande
                  sx={{ zIndex: theme.zIndex.mobileStepper }}
                  loading={loadingButton}
                  variant="contained"
                  Handle={(event) => onAddCart(event, true)}
                >
                  Continuar pedido
                </ButtonAcaoGrande>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
