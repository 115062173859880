import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function NetworkError() {
  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Typography variant="h1" component="h2">
          ERRO DE CONEXÃO
        </Typography>
      </Grid>
    </>
  );
}
