import React, { useState } from "react";
import MenuDashboard, { Paginas } from "../../../../components/MenuDashboard";
import PropTypes from "prop-types";
import { getAllSaldo } from "../../../../redux/actions/saldo.action";
import { useAuth } from "../../../../hooks/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import selectSaldo from "../../../../redux/selectors/saldo.selector";
import { getTipoOperacaoSaldo } from "../../../../api/chamadasApi";
import Alert from "../../../../components/Snack/Snack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CompraCripto from "../../../../components/ControlledAccordions";
import AdicaoReais from "../../../../components/OperacaoSaldo/AdicaoReais";
import SaqueSaldo from "../../../../components/OperacaoSaldo/SaqueSaldo";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "react-query";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ButtonAcaoGrande from "../../../../components/Botoes/ButtonAcaoGrande";
import FormMoedaCustom from "../../../../components/Forms/FormMoedas";
import { Link } from "react-router-dom";
import imageCripto from "../../../../assets/images/Criptomoedas/bitcoin.png";
import useStyles from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function ComprarCripto() {
  const { user } = useAuth();
  const clienteSaldos = useSelector(selectSaldo);
  const dispatch = useDispatch();

  const queryKey = ["@CLIENT_SALDO"];
  const { data: dataTipoOperacao } = useQuery(["teste"], getTipoOperacaoSaldo, {
    staleTime: 1000 * 300,
  });

  useQuery(queryKey, () => dispatch(getAllSaldo(user.id)));

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("teste");
  const [tipoAlert, setTipoAlert] = useState("success");

  const [operacaoDesejada] = useState(null);

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <MenuDashboard pagina={Paginas.Cripto}>
        <Grid
          className={classes.BlocoCripto}
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} className={classes.Saldo}>
            <Box style={{ display: "flex" }}>
              {" "}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
                <InputBase placeholder="Pesquisar" />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                ></IconButton>
              </Paper>
              <Grid>
                <Button
                  className={classes.Button}
                  disabled
                  size="small"
                  variant="outlined"
                >
                  Ver Preços
                </Button>
                <Link className={classes.Link} to="/Criptomoeda/Ativos">
                  {" "}
                  <Button
                    className={classes.Button}
                    size="small"
                    variant="outlined"
                  >
                    Meus ativos
                  </Button>
                </Link>
              </Grid>
            </Box>
            <CompraCripto sx={{ mt: 15 }} />
          </Grid>
          <Grid item xs={5} className={classes.Saldo}>
            <Box style={{ display: "flex" }}>
              <Typography
                sx={{ mb: 3, mr: 2 }}
                color="text.secondary"
                variant="h6"
              >
                Comprar
              </Typography>
              <Typography
                sx={{ mb: 3, fontWeight: "200" }}
                color="text.secondary"
                variant="h6"
              >
                1,651
              </Typography>
            </Box>
            <Typography style={{ fontWeight: "500" }} color="primary">
              Simulação
            </Typography>
            <FormMoedaCustom size="medium" prefix={"R$"} placeholder="R$" />
            <Typography
              variant="h6"
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
                paddingRight: 5,
              }}
            >
              ≈ 0,00000000
              <Box width="20" src={imageCripto} />
            </Typography>
            <Typography variant="body1">PAGAR COM:</Typography>
            <Box className={classes.SaldoConta}>
              <Typography color="text.secondary">Saldo em Conta</Typography>
              <Typography>
                {" "}
                {clienteSaldos.totalEmMN.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  fontWeight: "500",
                })}
              </Typography>
            </Box>

            <ButtonAcaoGrande texto="Comprar" />
          </Grid>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.CompleteModal} sx={style}>
            {operacaoDesejada === "ADD" && (
              <AdicaoReais
                tipoOperacaoSaldo={
                  dataTipoOperacao?.find((tipo) => tipo.id === 1) ?? 1
                }
                queryKey={queryKey}
                modalControl={setOpen}
                handlerShowAlert={setShowAlert}
                handlerTipoAlert={setTipoAlert}
                handlerTextAlert={setAlertText}
              />
            )}
            {operacaoDesejada === "RET" && (
              <SaqueSaldo
                tipoOperacaoSaldo={
                  dataTipoOperacao?.find((tipo) => tipo.id === 2) ?? 1
                }
                queryKey={queryKey}
                modalControl={setOpen}
                handlerShowAlert={setShowAlert}
                handlerTipoAlert={setTipoAlert}
                handlerTextAlert={setAlertText}
              />
            )}
          </Box>
        </Modal>
      </MenuDashboard>
    </>
  );
}
