import { makeStyles } from "@mui/styles";


const useStyle = makeStyles((theme) => ({
   icon: {
      fontSize: '5rem !important',
      textAlign: 'center !important'
   },

   container: {
      height: '65vh !important',
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      justifyContent: 'center'
   }

}));

export default useStyle;
