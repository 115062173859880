import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "../Snack/Snack";
import ImageCripto from "../../assets/images/Criptomoedas/bitcoin.png";
import Negative from "../../assets/images/Criptomoedas/negative.png";
import useStyles from "./styles";

export default function OperarAtivos() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText] = useState("");
  const [tipoAlert] = useState("success");
  const classes = useStyles();

  return (
    <div style={{ marginTop: 0, width: "100%" }}>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <Typography variant="h6" className={classes.Titulo}></Typography>

      <br></br>
      <Grid container className={classes.Precos} lg={12}>
        <Box className={classes.blocoPrecos}>
          <Box className={classes.img} component="img" src={ImageCripto} />
          <Box className={classes.Cripto}>
            <Grid>
              <Typography className={classes.Titulo} variant="body1">
                BTC
              </Typography>
              <Typography variant="subtitle">Bitcoin</Typography>
            </Grid>
            <Box component="img" src={Negative} />
            <Grid>
              <Typography color="text.secondary" variant="body1">
                Saldo total:
              </Typography>
              <Typography className={classes.Titulo} variant="subtitle">
                0,000000
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </div>
  );
}
