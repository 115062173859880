import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0 !important",
    "& .Mui-error": {
      color: "orange",
    },
    "& .MuiFormHelperText-root": {
      color: "orange",
    },
  },
}));

export default useStyles;
