import { useAuth } from "../../../../hooks/AuthContext";
import {  Grid, Typography } from "@mui/material";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import { ClienteEndereco } from "../../../../components";

const MeusEnderecos = ({ handlerCancelEdit }) => {
  const { user } = useAuth();

  return (
    <div style={{ padding: "15px" }}>
      
        <ButtonVoltar sx={{m:0, pl:0}} handleVoltar={handlerCancelEdit} mostraTexto={true} color="primary" />
      <Typography
        variant="h6"
        color="text"
        style={{ marginLeft: "0.5rem", marginBottom: 3 }}
      >
        Seus endereços
      </Typography>
      <Grid container item lg={8} md={12} sm={12} xs={12}>
      <Typography variant="body1" color="text.secondary" sx={{ ml: 1, mb:3 }}>
        Mantenha seus endereços atualizados, para melhor usabilidade da
        plataforma.
      </Typography>
     
      <ClienteEndereco idCliente={user.id} />
      </Grid>
    </div>
  );
};

export default MeusEnderecos;
