import { Card, CardContent } from "@mui/material"
import Subtitulo from "../Textos/Subtitulo"




const CardCustom = ({ children }) => {
    return (
        <Card  sx={{  borderRadius: 2, background: '#fcfcfc', minHeight: 'auto', maxWidth: '70vh', boxShadow:'none'}}>
            <CardContent
                sx={{
                    maxHeight: '80vh',
                    background: '#fcfcfc',
                    overflow: 'auto'
                }}
            >
                {children}

            </CardContent>
        </Card>
    )
}

export default CardCustom;