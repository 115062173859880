import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({

    List: {
        width: "100%",
        maxWidth: "100%",
        bgcolor: "background.paper",
        maxHeight: "auto",
        overflow: "auto",
        position: "relative",
        paddingRight: "10px",
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            maxHeight: "55vh",
            overflow: "auto",
            position: "relative",
            paddingRight: "10px",

        }
    },


    ListItemText: {
        display: 'block',
        [theme.breakpoints.up("sm")]: {
            display: 'flex',
        },
    },
    ListText: {
        minWidth: '11em',
        [theme.breakpoints.up("sm")]: {
            minWidth: '14em',
        },
    }
}));

export default useStyles;
