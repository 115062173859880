import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "../Snack/Snack";
import ImageCripto from "../../assets/images/Criptomoedas/bitcoin.png";
import Negative from "../../assets/images/Criptomoedas/negative.png";

import useStyles from "./styles";

export default function ControlledAccordions() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText] = useState("");
  const [tipoAlert] = useState("success");

  const classes = useStyles();

  return (
    <div style={{ marginTop: 0, width: "100%" }}>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <Typography
        variant="h6"
        style={{ fontWeight: 500, color: "#000" }}
      ></Typography>

      <br></br>
      <Grid container className={classes.Precos} lg={12}>
        <Box className={classes.blocoPrecos}>
          <Box className={classes.img} component="img" src={ImageCripto} />
          <Box className={classes.Cripto}>
            <Grid>
              <Typography
                style={{ fontWeight: "500" }}
                className={classes.tituloMoeda}
                variant="body1"
              >
                BTC
              </Typography>
              <Typography variant="subtitle">Bitcoin</Typography>
            </Grid>
            <Box
              component="img"
              style={{ width: "100px", height: "30px" }}
              src={{ Negative }}
            ></Box>

            <Grid>
              <Typography className={classes.tituloMoeda} variant="body1">
                R$: <strong>1,651</strong>
              </Typography>
              <Typography color="text.secondary" variant="subtitle">
                24h -<span style={{ color: "red" }}>1,12%</span>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </div>
  );
}
