import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  campo: {
    margin: "10px",
    padding: 10,
  },
  box: {
    borderRadius: 5,
    paddingLeft: 8,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 5,
    paddingLeft:30,
    paddingRight:30,
    width: "100%",
    },
  },
}));

export default useStyle;
