
export const StepEnvio = [
    {
        label: "Vitrine",
        description: "",
        link: "/carrinho/transferencia-internacional/envio/vitrine",
    },
    {
        label: "Beneficiário",
        description: "",
    },
    {
        label: "Forma de pagamento",
        description: ``,
    },
    {
        label: "Finalizar",
        description: ``,
    },
]

export const StepRecebimento = [
    {
        label: "Vitrine",
        description: "",
    },

    {
        label: "Forma de pagamento",
        description: ``,
    },
    {
        label: "Finalizar",
        description: ``,
    },
]