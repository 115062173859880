import { Card, CardContent, Grid, Skeleton, useTheme } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";

const Loading = () => {
   const theme = useTheme()
   return (
      <Grid container sx={{ mt: theme.spacing(3), padding: theme.spacing(7), height: '80vh' }} justifyContent="center" alignItems='center' >
         <Grid item md={6} sm={12}>
            <Skeleton height={50} width={80} animation="wave" />
            <Skeleton height={50} width={300} animation="wave" />
            <Skeleton height={50} width={180} animation="wave" />
            <br />
            <Skeleton height={50} width={300} animation="wave" />
            <br />
            <Skeleton height={50} width={300} animation="wave" />
         </Grid>
         <Grid item md={6} sm={12}>
            <Card variant="outlined" sx={{ borderColor: theme.palette.primary.main, borderWidth: '2px' }}>
               <CardContent>
                  <Skeleton height={50} width={300} animation="wave" />
                  <hr />
                  <br />
                  <Skeleton height={200} width={200} animation="wave" />
                  <Skeleton height={50} width={300} animation="wave" />
                  <Skeleton height={50} width={300} animation="wave" />
                  <Skeleton height={50} width={300} animation="wave" />
               </CardContent>
            </Card>
         </Grid>
      </Grid>
   )
}

export default Loading;