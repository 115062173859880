import Envio from "../../../../../components/Vitrine/TransferenciaInternacional/Envio";
import {
 
  MenuDashboard,
  PaginasMenuDashboard,
  SubMenusDashBoard,
  BreadCrumbsPaginas,
} from "../../../../../components";
import { Hidden, Box } from '@mui/material'



import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export function Vitrine() {
  const breadCrumbs = [
    {
      label: "Remessas Internacionais",
      icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Enviar dinheiro para o exterior",
      icon: <DoubleArrowIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  return (
    <>
      <MenuDashboard
        pagina={PaginasMenuDashboard.Remessas}
        subMenuSelecionado={SubMenusDashBoard.Envio}
      >
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>
        <Envio />
      </MenuDashboard>
    </>
  );
}
