import api from '../index';

export const getTermoResponsabilidade = async (code) => {
    try {
        const { data: response, errorMessage } = await api.get(`/TermoResponsabilidade/Get`);

        return {
            data: response.data,
            errorMessage,
        }
    } catch (error) {
        throw new Error(error + " RL0EO");
    }
};
