import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({


   card: {
      borderWidth: '1px !important',
      borderRadius:2,
       maxWidth:'35em',
   },

   qrCode: {
      background:"#fff",
      height: theme.spacing(20),
      marginTop:10,
   },
   listaInformacoes: {
      listStyle: 'none',
      padding: 0,

   },

   itemList: {
     
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth:'100vw',
      padding:"10px",
      [theme.breakpoints.up("sm")]: {
         
     
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth:'100vw',
      }

   },
   iconButton: {
      height: 22
   }
}));

export default useStyle;
