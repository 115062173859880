import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  optionsMenu: {
    "& p:first-child": {
      fontWeight: "600 !important",
    },
    "& p": {
      fontSize: ".875rem !important",
    },
  },
  campo: {
    marginRight: "10px",
    padding: 10,
    color: "blue",
  },
}));

export default useStyle;
