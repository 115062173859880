import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    Span: {
      color: "red",
    },
    AccordionStyle: {
      background: "#eee !important",
    },
    blocoPrecos: {
      width: "100%",
      cursor: "pointer",
      alignItems: "center",
      display: "flex",
      padding: 15,
      borderRadius: 8,
      border: "1px solid #bbb",
      background: "#fff",
      marginTop: 20,
    },
    Cripto: {
      marginLeft: "20px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    tituloMoeda: {
      fontWeight: "500",
    },
    Precos: {
      maxHeight: "40em",
      overflowY: "auto",
    },
    Titulo: {
      fontWeight: 500,
      color: "#333",
    },
    img: {
      width: 30,
    },
  }));

  export default useStyles;