import api from "../../index";

const idOrigem = 3;

export const getAllItensCarrinho = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoGeral/GetAllItensCarrinhos?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R2AC0");
  }
};
