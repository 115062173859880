import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
   List: {
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
         marginLeft: 15,

      }

   },
   Grid: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      padding: 20,
   },


   checked: {
      border: '1.50px solid ' + theme.palette.primary.main,
   },


   iconCheck: {
      color: theme.palette.primary.main + " !important"
   }


}));

export default styles;
