import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonAcaoGrande from "../../../Botoes/ButtonAcaoGrande";
import Modal from "../../../Modal/Modal";

import { Style } from "./styles";

function Itens({ search }) {
  const navigation = useNavigate();
  const classes = Style();
  const [showModal, setShowModal] = useState(false);
  const [seguros, setSeguros] = useState([
    {
      codigo: "AC150",
      descricao: "AC 150 + COVID-19",
      valorTotalReais: "142,43",
      valorMaximoCobertura: "35.000",
    },
    {
      codigo: "AC35",
      descricao: "AC 35 + COVID-19",
      valorTotalReais: "142,43",
      valorMaximoCobertura: "35.000",
    },
    {
      codigo: "AC60",
      descricao: "AC 60 + COVID-19",
      valorTotalReais: "163,06",
      valorMaximoCobertura: "35.000",
    },
  ]);
  const [seguroSelecionado, setSeguroSelecionado] = useState({});

  const init = async () => {
    try {
    } catch (error) { }
  };

  useEffect(() => {
    init();
  }, []);

  const handlerBuy = async (seguro) => {
    navigation("/SeguroViagem/Passageiros");
  };

  return (
    <>
      <Grid className={classes.Container}>
        <Grid sx={{ margin: "auto", display: 'flex', justifyContent: "space-between ", mb: 2 }} item xs={11} lg={7}>
          <Typography>Recomendações de Seguro Viagem</Typography></Grid>
        {seguros &&
          seguros.map((s) => {
            return (
              <>
                <Grid className={classes.Item} sx={{ margin: "auto", display: 'flex', justifyContent: "space-between " }} item xs={11} lg={7}>
                  <Grid>
                    <Typography>{s.descricao}</Typography>
                    <br />
                    <Typography>
                      Valor máximo global de cobertura: USD{" "}
                      {s.valorMaximoCobertura}
                    </Typography>
                    <Typography
                      onClick={() => setShowModal(!showModal)}
                      sx={{ cursor: "pointer" }}
                      color="text.secondary"
                    >
                      <span className={classes.span}>Visualizar Coberturas</span>
                    </Typography>
                  </Grid> <Grid>
                    <ButtonAcaoGrande
                      Handle={() => handlerBuy(s)}
                      texto="Contratar"
                      className={classes.buttonSearch}
                    />
                  </Grid>
                </Grid><br></br>
              </>
            );
          })}
      </Grid>
      <Box display="flex" justifyContent="start">
        <Modal
          show={showModal}
          hasButtonClose={true}
          close={() => {
            setShowModal(!showModal);
          }}
        >
          <p>O que é Lorem Ipsum?</p>
          <p>
            Lorem Ipsum é simplesmente uma simulação de texto da indústria
            tipográfica e de impressos, e vem sendo utilizado desde o século
            XVI, quando um impressor desconhecido pegou uma bandeja de tipos e
            os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum
            sobreviveu não só a cinco séculos, como também ao salto para a
            editoração eletrônica, permanecendo essencialmente inalterado. Se
            popularizou na década de 60, quando a Letraset lançou decalques
            contendo passagens de Lorem Ipsum, e mais recentemente quando passou
            a ser integrado a softwares de editoração eletrônica como Aldus
            PageMaker.
          </p>
        </Modal>
      </Box>
    </>
  );
}

export default Itens;
