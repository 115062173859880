import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function snack({ show = false, close, text = "", severity = "success" }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={3000}
      onClose={close}
    >
      <Alert onClose={close} severity={severity}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export default snack;
