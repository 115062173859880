
import Turismo from "../../../../../components/Vitrine/Turismo";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Money from "@mui/icons-material/PaidOutlined";
import { Box, Hidden } from "@mui/material";
import { BreadCrumbsPaginas, MenuDashboard, PaginasMenuDashboard, SubMenusDashBoard } from "../../../../../components";
import TiposPedido from '../../../../../util/typesTipoPedido';

export default function VitrineCompraEspecie() {
  const breadCrumps = [
    {
      label: "Moedas em espécie",
      icon: <Money sx={{ mr: 0.5 }} fontSize="inherit" />
    },
    {
      label: "Comprar Moeda",
      icon: <AddShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    }
  ]
  return (
    <>
      <MenuDashboard pagina={PaginasMenuDashboard.Especie} subMenuSelecionado={SubMenusDashBoard.CompraMoeda}>

        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Turismo idTipoOperacao={TiposPedido.VendaEspecie} isEspecie={true} />
      </MenuDashboard>
    </>
  );
}
