import React from "react";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import Copyright from "../Copyright";

export default function Rodape(){
    return(
        <Hidden smUp>
        <Grid
          item
          md={1}
          sm={1}
          xs={false}
          sx={{
            mx: 5,
          }}
        >
          <Copyright />
        </Grid>
      </Hidden>
    )
}