import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/AuthContext";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();
  if (user == null) {
    return <Navigate to="/Login" replace />;
  }
  else if (
    user.idTipoStatusCliente === 6 &&
    location.pathname !== "ClienteLimiteOperacional"
  ) {
    return <Navigate to="/ClienteLimiteOperacional" replace />;
  } 
  else {
    return children;
  }
};

export default PrivateRoute;
