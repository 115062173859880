import { Id } from "react-flags-select";
import api from "../";

import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getPedidosTurismoByIDCliente = async (idCliente, pagina = 1) => {
  const { data: pedidos, status } = await api.get(
    `/pedidoTurismo/GetPedidosTurismoByIDCliente`,
    {
      params: {
        idCliente: idCliente,
        idHotsite: idHotsite,
        pagina: pagina,
      },
    }
  );

  return pedidos.data;
};

// //! Pega o pedido de turismo do cliente
export const GetPedidoByID = async (idCliente, idPedido) => {
  try {
    const { data: response, status } = await api.get(`/PedidoTurismo/GetByID?idCliente=${idCliente}&idHotsite=${idHotsite}&idPedido=${idPedido}`)

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    }
  } catch (error) {
    throw new Error(error + "R773C");
  }
}


// //! Pega o pedido do ouro
export const GetPedidoOuro = async (idCliente, idPedido) => {
  try {
    const { data: response, status } = await api.get(`/PedidosOuro/GetByID?idCliente=${idCliente}&idHotsite=${idHotsite}&idPedido=${idPedido}`)

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    }
  } catch (error) {
    throw new Error(error + "R773C");
  }
}

