import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "4px !important",
    },
    "&:hover fieldset": {
      borderRadius: "4px !important",
    },
  },
});

export default function CampoMask({
  name,
  value,
  fullWidth,
  sx,
  handlerChange,
  label,
  size,
  enable,
  mask,
  isLoading = false,
}) {
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          height={40}
          key={1}
          sx={sx}
          animation={false}
        />
      )}
      {!isLoading && (
        <InputMask
          onChange={handlerChange}
          mask={mask}
          value={value}
          disabled={false}
          maskChar="_"
        >
          {() => (
            <FormControl
              size="medium"
              sx={{ width: "100%" }}
              variant="outlined"
            >
              <CssTextField
                sx={sx}
                margin="normal"
                required
                fullWidth={fullWidth ?? true}
                id={label}
                label={label}
                size={size ?? "medium"}
                defaultValue=" "
                name={name}
                value={value}
                autoFocus
                onChange={handlerChange}
                //helperText={isError ? `${label} inválido!` : ""}
                error={isError}
                className={classes.root}
                disabled={!enable}
              />
            </FormControl>
          )}
        </InputMask>
      )}
    </>
  );
}
