import { makeStyles } from "@mui/styles";
const important = " !important";

const useStyles = makeStyles((theme) => ({
  AvatarReais: {
    background: theme.palette.primary.light + important,
  },
  AvatarCripto: {
    background: theme.palette.warning.main + important,
  },
}));

export default useStyles;
