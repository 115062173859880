import Tooltip from '@mui/material/Tooltip';

export const TooltipInformacao = ({ children, positionStart, descricao, arrow = true, tempoEntrada, tempoSaida, sx = { marginRight: 1 } }) => {


   return (
      <Tooltip
         sx={{ ...sx }}
         className='null'
         enterDelay={tempoEntrada ?? 0}
         leaveDelay={tempoSaida ?? 200}
         placement={positionStart}
         title={descricao}
         arrow={arrow}
         describeChild={true}
      >
         {children}
      </Tooltip>
   )
}

export const PositionTooltip = {
   top: "top-start",
   bottom: "bottom-start",
   left: "left-start",
   right: "right-start"
}