import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveLocalStorageCarrinhoTemp } from './LocalStorage'


export default function PreLogin() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()


    useEffect(() => {

        let newArrayParams = [];

        searchParams.forEach((value, key) => {
            newArrayParams.push({ [key]: value });
        })

        let objParams = Object.assign({}, ...newArrayParams);
        let natureza = { id: objParams?.Nid ?? 0, descricao: objParams?.Ndescricao ?? "" }
        let formObj = { ...objParams, natureza: { ...natureza } }


        saveLocalStorageCarrinhoTemp(formObj);

        if (newArrayParams.length > 0) {
            navigate("/login", false)
        }

    }, [])

    return;
}