import { useState } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import DadosBasicos from "../../../../components/Cadastro/DadosBasicos";
import { ButtonCustom } from "../../../../components/Botoes/Button";
import { desativarConta } from '../../../../api/ContaCliente/index'
import useAlert from "../../../../hooks/useAlert";
import useStyles from "./styles";
import { Alert, AlertTitle } from "@mui/material";

const MinhaConta = ({ handlerCancelEdit }) => {
    const { user, signOut } = useAuth();
    const classes = useStyles();
    const { Alert: AlertCustom, showAlertSuccess, showAlertError } = useAlert();
    const [isLoading, setIsLoading] = useState(false);

    async function deletaUsuario(event) {
        event.preventDefault();
        setIsLoading(() => true)
        try {
            let response = await desativarConta(user.id)

            if (response?.data?.errorMessage)
                throw new Error(response.data.errorMessage)


            showAlertSuccess("Removemos o seu cadastro do nosso banco de dados.");

            setTimeout(() => {
                signOut()
            }, 1500)

        } catch (error) {
            console.error(error.message)
            showAlertError(`Ops, algo deu errado: ${error.message} - R5YF`)
        }
        finally {
            setTimeout(() => {
                setIsLoading(() => false)
            }, 2000)
        }


    }

    return (
        <>
            <AlertCustom />
            <Grid container sx={{ overflowX: "hidden" }}>
                <Grid
                    lg={8}
                    container
                    xs={12}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid sx={{ mt: 2 }} item>
                        <ButtonVoltar
                            handleVoltar={handlerCancelEdit}
                            mostraTexto={true}
                            sx={{ ml: 1, mb: 5 }}
                        />
                        <Typography variant="h6" sx={{ ml: 3, mb: 2 }}>
                            Minha Conta
                        </Typography>
                    </Grid>{" "}
                </Grid>
                <Grid
                    lg={8}
                    container
                    className={classes.Grid}
                    sx={{ ml: 2, mr:2 }}
                >
                    <Alert severity="warning">
                        <AlertTitle>Atenção</AlertTitle>
                        Informamos que, de acordo com as diretrizes do Banco Central, não é
                        possível realizar a exclusão de sua conta caso haja operações
                        vinculadas. De acordo com as diretrizes do Banco Central, temos a
                        obrigação de manter registros completos de todas as operações realizadas
                        em nossa corretora de câmbio, inclusive os dados pessoais dos clientes
                        envolvidos. Essas informações são essenciais para o cumprimento das
                        regulamentações e relatórios obrigatórios.
                    </Alert>


                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={12}
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "flex-end !important",
                            width: "100% !important",
                            ml: 0,
                            mt: 2
                        }}
                    >
                        <ButtonCustom
                            typeButton="primary"
                            variant="error"
                            size="small"
                            onClick={deletaUsuario}
                            loading={isLoading}
                        >
                            Deletar usuario
                        </ButtonCustom>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
};

export default MinhaConta;
