const estadosCivis = [
    {
        id: 1,
        descricao: "Solteiro",
    },
    {
        id: 2,
        descricao: "Casado",
    },
    {
        id: 3,
        descricao: "Divorciado",
    },
    {
        id: 4,
        descricao: "Viúvo",
    },
    {
        id: 5,
        descricao: "Não Informado",
    },
];

export default estadosCivis;