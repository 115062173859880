import React from "react";
import Button from "@mui/material/Button";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import useStyles from "./styles";
import CloseIcon from '@mui/icons-material/Close';

export default function ButtonVoltar({ handleVoltar, mostraTexto, iconClose, sx, color, children }) {
  const classes = useStyles();
  
  const renderIcon = () => {
    if (iconClose) {
      return <CloseIcon color={color} />;
    } else {
      return <ChevronLeftOutlinedIcon color={color} sx={{ fontSize: '28px' }} />;
    }
  };

  return (
    <Button
      sx={sx}
      variant="text"
      size="large"
      onClick={handleVoltar}
      color={color}
      className={!!children ? classes.ButtonWithText : classes.Button}
    >
      {renderIcon()}
      {mostraTexto && (children ? ` ${children}` : " Voltar")}
    </Button>
  );
}
