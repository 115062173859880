import {  useEffect, useState } from "react";
import RegiaoEntrega from "../../../../../components/RegiaoEntrega";

export default function Delivery({ idPraca, isVenda, isEspecie, onChange, onChangeTipoDelivery }) {

  const [regiaoEntrega, setRegiaoEntrega] = useState(0)
  const [tipoDelivery, setTipoDelivery] = useState(0)
  const [valorFrete, setValorFrete] = useState(0)

  const handlerRegiaoEntrega = (regiao) => {
    setRegiaoEntrega(regiao);
  };

  const handlerTipoDelivery = (tipo) => {
    setTipoDelivery(tipo);
  };

  const handlerValorFrete = (valor) => {
    setValorFrete(valor);
  };


  useEffect(() => {

    onChange(regiaoEntrega, tipoDelivery);

  }, [regiaoEntrega, tipoDelivery, valorFrete])





  return (
    <RegiaoEntrega
      idPraca={idPraca}
      isVenda={isVenda}
      isEspecie={isEspecie}
      handlerSelectRegiaoEntrega={handlerRegiaoEntrega}
      handlerSelectTipoDelivery={handlerTipoDelivery}
      handlerChangeValorFrete={handlerValorFrete}
    />
  );
}
