import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import usuarioReducer from "./reducers/usuarioReducer";
import saldoReducer from "./reducers/saldo.reducer";
import operacaoCambio from './reducers/OperacaoCambio.reducer';

const middleware = [thunk];

const reducers = combineReducers({
  usuario: usuarioReducer,
  saldoUsuario: saldoReducer,
  operacaoCambio,
});


const store = createStore(
  reducers,
  applyMiddleware(...middleware),
);


export default store;
