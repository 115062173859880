import { useState } from "react";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import CampoEmail from "../Campos/CampoEmail";
import { useMutation } from "react-query";
import useAlert from "../../hooks/useAlert";
import { EnviarEmailRemetenteExterior } from "../../api/FormaPagamento";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export default function DisparaEmail({
  showModal,
  setShowModal,
  moeda,
  showEmailError,
  showEmailSuccess,
}) {
  // const [email, setEmail] = useState("");
  const { Alert, showAlertError, showAlertSuccess, showAlertInfo } = useAlert();

  const mutationSendEmail = useMutation(EnviarEmailRemetenteExterior, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      showAlertSuccess(
        "Estamos encaminhando os dados para o e-mail, por favor aguarde"
      );
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R926F `
      );
    },
  });

  // const handlerChangeEmail = (event) => {
  //     setEmail(event.target.value);
  // };

  const handlerSendEmail = ({ email }) => {
    if (!!!email) {
      showEmailError(
        "O campo e-mail deve ser informado."
      );
      return;
    }

    // showEmailSuccess(
    //   "O e-mail com as informações bancárias será enviado dentro de alguns minutos"
    // );

    let dados = {
      email,
      // idMoeda: itemVitrine?.moeda?.id,
      idMoeda: moeda.id,
    };
    mutationSendEmail.mutate(dados);
    setShowModal(!showModal);
  };

  const schema = z.object({
    email: z
      .string()
      .nonempty("O email é obrigatorio")
      .email("Por Favor inserir um e-mail valido"),
  });

  const createEmailForm = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const { handleSubmit, control } = createEmailForm;

  return (
    <>
      <Alert />
      <FormProvider {...createEmailForm}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <CampoEmail
              size="small"
              label="Email"
              name={name}
              value={value}
              handlerChange={onChange}
            />
          )}
          name="email"
        />
      </FormProvider>
      <ButtonAcaoGrande
        Handle={handleSubmit(handlerSendEmail)}
        color="primary"
        fullWidth
      >
        Enviar e-mail
      </ButtonAcaoGrande>
    </>
  );
}
