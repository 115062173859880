import { useEffect } from "react";
import Titulo from "../../components/Textos/Titulo";
import { BreadCrumbsPaginas, MenuDashboard } from "../../components";
import { Box, Grid } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import useStyle from "./style";
import parseHtml from "html-react-parser";
import useLojaFechada from "../../hooks/useLojaFechada";
import useAlert from "../../hooks/useAlert";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
export default function LojaFechada({ route, navigation }) {
  const classes = useStyle();

  const { errors, lojaFechada } = useLojaFechada();
  const { Alert, showAlertError } = useAlert();

  useEffect(() => {
    if (errors) showAlertError(errors);
  }, [errors]);

  const breadCrumps = [
    {
      label: "Carrinho",
      icon: <ShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Loja Fechada",
      icon: <DoDisturbAltIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];




  return (
  <>
    <MenuDashboard pagina={-1}>
      <Alert />
      <Box
        sx={{

          pl: 3,
          pb: 2,
          width: '100%',
          height: 'auto',
          background: "#fff",
        }}
      >
        <BreadCrumbsPaginas list={breadCrumps} />
      </Box>
      <Grid
        sx={{

          width: '100%',
          height: 'auto',
          pl: 3,
          pr: 3
        }}
      >
        <Grid item xs={12} className={classes.container}>
          {lojaFechada &&
            <>
              <ManageHistoryIcon color="primary" className={classes.icon} />
              <Titulo align="center" color="black" sx={{ mt: 3 }}>
                <b>Loja fechada</b>
                <br />

              </Titulo>
              <Titulo align="center" color="black" sx={{ mt: 3, fontSize: 20 }}>

                {lojaFechada.horarioRestrito ? "Nosso atendimento é de " : ""}
                {parseHtml(lojaFechada.mensagemLojaFechada)}
              </Titulo>
            </>
          }
        </Grid>
          </Grid>
      </MenuDashboard>
    </>
  );
}
