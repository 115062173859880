import { useState } from 'react'
import { useAuth } from "../../../../../hooks/AuthContext";
import { PedidoConcluidoPagamentoEscolhido, MenuDashboard, PaginasMenuDashboard, LoadingPedidoRealizadoPagamentoEscolhido } from '../../../../../components'
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { GetPedidoByID } from '../../../../../api/PedidoComercial';
import useAlert from "../../../../../hooks/useAlert";
import { PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO } from '../../../../../util/typesReactQuery'



export default function ConfirmacaoRecebimento() {
  const { user } = useAuth();
  const { Alert, showAlertError } = useAlert();
  const { idPedido } = useParams()
  const [errorMessage, setErrorMessage] = useState("")


  const { data: Pedido } = useQuery([`${PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO}${idPedido}`], () => GetPedidoByID(user.id, idPedido), {
    onSuccess: (data) => {
      if (!data || !data?.data) {
        showAlertError(
          "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhum pedido foi encontrado - RBF76 "
        );
        return;
      }

      if (data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R3256 `
        );
        return;
      }

    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  R98CC`
      );
    },
    refetchOnWindowFocus: false,
  })



  return (
    <>
      <Alert />
      <MenuDashboard pagina={PaginasMenuDashboard.Remessas}>
        {!!Pedido?.data ?
          <PedidoConcluidoPagamentoEscolhido pedido={Pedido.data} setErrorMessage={setErrorMessage} />
          :
          <LoadingPedidoRealizadoPagamentoEscolhido />
        }
      </MenuDashboard>
    </>
  );
}
