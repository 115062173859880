import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
Texto:{
    marginLeft:'15px !important',
    [theme.breakpoints.up("sm")]: {
        marginLeft:'15px !important',
      
    }
    
}
}));

export default useStyles;
