

import api from "../index";

export const getDadosBancariosCliente = async (idCliente, apenasPix) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - R7E01");

        const { data: response, status } = await api.get(
            `DadoBancario/GetByIdCliente?idCliente=${idCliente}&apenasPIX=${apenasPix}`
        );

        if (status !== 200) throw new Error(response.errorMessage);

        return {
            isSuccess: true,
            errorMessage: response.errorMessage,
            data: response.data,
            status,
        };
    } catch (error) {
        return { isSuccess: false, errorMessage: error.errorMessage + " RC381" };
    }
};

export const SaveNewDadosBancarios = async (dadosBancarios) => {
    try {


        const { data: response, status } = await api.post(`DadoBancario/Save`, dadosBancarios);

        if (status !== 200) throw new Error(response.errorMessage);

        return {
            isSuccess: true,
            errorMessage: response.errorMessage,
            data: response.data,
            status,
        };
    } catch (error) {
        return { isSuccess: false, errorMessage: error.errorMessage + " R662E" };
    }
};

export const desativaAtivaDadosBancarios = async (idCliente, idDadosBancarios) => {
    try {

        const { data: response, status } = await api.post(
            `DadoBancario/DesativaAtivaByID?idCliente=${idCliente}&idDadoBancario=${idDadosBancarios}`
        );

        if (status !== 200) throw new Error(response.errorMessage);

        return {
            isSuccess: true,
            errorMessage: response.errorMessage,
            data: response.data,
            status,
        };
    } catch (error) {
        return { isSuccess: false, errorMessage: error.errorMessage + " RC381" };
    }
};