import { Box, Grid, Skeleton } from "@mui/material";


export default function SkeletonBeneficiario() {
   return (
      <Grid container>
         <Grid item xs={12}>
            <Skeleton height={50} animation="wave" />
         </Grid>
         <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
            <Skeleton height={50} animation="wave" width={200} />
         </Grid>
         <Grid item xs={12}>
            <Skeleton height={80} animation="wave" />
         </Grid>
         <Grid item xs={12}>
            <Skeleton height={80} animation="wave" />
         </Grid>
      
      </Grid>
   )
}