import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Titulo: {
    paddingLeft: "20px !important",
  },
  Subtitulo: {
    paddingLeft: "20px !important",
  },
  Grid: {
    margin: "auto !important",
    borderRadius: "10px",
  },
  Container: {
    background: "#fff",
  },

  check: {
    color: theme.palette.success.main + " !important",
  },

  optionsLido: {
    flexBasis: "80%",
    background: 'none !important',
    marginBottom: '.35rem !important',
    "& :hover": {
      color: theme.palette.success.main,
      transition: 'all 300ms ease-in-out',
    },
    [theme.breakpoints.up("sm")]: {
   
      flexBasis: "20%",

    }
  }


}));

export default useStyles;
