import api from "../index";

export const getMoedaByID = async (idMoeda) => {
    const { data: moeda, status } = await api.get(`/Moeda/GetByID?idMoeda=${idMoeda}`);

    return {
        status,
        data: moeda.data,
        errorMessage: moeda.errorMessage,
    };
};