import Grid from "@mui/material/Grid";
import { Button, Skeleton, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "react";
import GetAppIcon from '@mui/icons-material/GetApp';
import useStyles from "./style";


export default function CampoDocumento({ doc, setData, isLoading }) {
  const [formData, setFormData] = useState(doc);
  const classes = useStyles();

  const handlerChangeDoc = (event) => {
    let file = event.target.files[0];
    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });

      setData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {

    };
  };

  return (
    <>
      <Grid container sx={{ pl:0, mb: 4, display:"flex" }}>
        <Grid item className={classes.Item} xs={12} md={6} lg={6} mr={1} mt={1}>
          {
            isLoading ?
              <Skeleton animation="wave" width={300} height={40} sx={{ m: 2 }} />
              :
              <Typography variant="body1" color="text.secondary" sx={{ mt: 0, m: 2, mb: 0, alignItems:'center', display:"flex" }}>{doc.tipoDocumento}    {formData.tamanho > 0 && <DoneIcon sx={{ml:2}} />}
              </Typography>
          }
        
          {
            isLoading ?
              <Skeleton animation="wave" width={200} height={60} sx={{ m: 2 }} />
              :
              <Button
                sx={{ mt: 0, m: 2 }}
                variant="contained"
                component="label"
                size="small"
                onChange={handlerChangeDoc}
              >
                Escolher arquivo <GetAppIcon sx={{ ml: 1 }} fontSize="small" />
                <input type="file" accept="image/*" hidden />
                
              </Button>
          }

        </Grid>
        <Grid
          container
          xs={12}
          md={2}
          mt={1}
          lg={2}
          mr={1}
          sx={{ alignItems: "center", justifyContent: "flex-end" }}
        ></Grid>

      </Grid>
    </>
  );
}
