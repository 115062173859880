import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
   Valor: {
      display: "flex",
      padding: 0,
      alignItems: "center",
      borderRadius: 8,

      backgroundColor: "#FFF",
      justifyContent: "space-between",
   },
   Bandeira: {
      width: 20,
      marginLeft: 8,
   },
   Taxa: {
      marginBottom: '4px',
      
   },
   IconButton: {
      color: theme.palette.error.main + " !important",
      opacity: .6,
      '&:hover': {
         opacity: 1,
      }
   }
}));

export default style;