import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  limite: {
    background: "#fff",
    height: "auto",
    margin: "auto",
    width: "100%",
    paddingLeft: 0,
    [theme.breakpoints.up("sm")]: {
      margin: "auto !important",

      maxWidth: '20em',
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",

      maxWidth: '35em !important',
    },
  },
  Input: {
    width: "100%",
  },
  icon: {
    background: "#eeeeee",
    padding: 12,
    fontSize: "50px !important",
    marginRight: 10,
    borderRadius: "50%",
  },
  Grid: {
    paddingTop: "10%",
    padding: 15,
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '1rem !important',
      paddingRight: "2rem !important"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "50em",
    },
  },
}));

export default useStyles;