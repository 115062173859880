import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    customIconButton: {
        transition: 'color 200ms ease-in-out',
        "&:hover": {
            color: theme.palette.primary.main,
        }
    },

}));

export default useStyles;
