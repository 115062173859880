import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import { MenuDashboard, CampoTexto } from "../../components";

export default function DadosCliente() {
  const classes = useStyles();

  const [dadosPessoais, setDadosPessoais] = useState([
    {
      label: "Razão social",
      propriedade: "RazaoSocial",
      tipoControle: "Texto",
      valor: "",
      obrigatorio: true,
    },
    {
      label: "Inscrição Social",
      propriedade: "InscricaoSocial",
      tipoControle: "Texto",
      valor: "",
      obrigatorio: true,
    },
    {
      label: "Inscrição Social 1",
      propriedade: "InscricaoSocial 1",
      tipoControle: "Texto",
      valor: "",
      obrigatorio: true,
    },
  ]);

  const handlerChangeValue = (e) => {
    const newData = dadosPessoais.map((d) => {
      if (d.propriedade === e.target.name) {
        return { ...d, valor: e.target.value };
      }
      return d;
    });

    setDadosPessoais(newData);
  };

  return (
    <>
      <MenuDashboard pagina={"DadosCliente"}>
        <Grid
          container
          xs={12}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            width: "100vw",
            margin: "auto !important",
            alignItems: "center !important",
          }}
          style={{ padding: "20px", ml:5}}
        >
          <br />
          <br />
          <Typography variant="h6">Seus dados</Typography>
          {dadosPessoais.map((m, i) => (
            <CampoTexto
              enable={true}
              key={i}
              sx={{ mt: 0 }}
              size="small"
              fullWidth={false}
              label={m.label}
              name={m.propriedade}
              maximoDigitos={m.maxLength}
              value={m.valor}
              handlerChange={handlerChangeValue}
              className={classes.campo}
            />
          ))}
        </Grid>
      </MenuDashboard>
    </>
  );
}
