import { useState, useEffect } from "react";
import FormularioLimiteOperacional from "../../components/Cadastro/FormularioLimiteOperacional";
import { MenuDashboard } from "../../components";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import { getAllLimiteOperacional } from "../../api/LimiteOperacional";

export default function ClienteLimiteOperacional() {
  const [idPerfilLimiteOperacional, setIdPerfilLimiteOperacional] = useState(0);
  const [perfisLimiteOperacional, setPerfisLimiteOperacional] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { Alert, showAlertError } = useAlert();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setIsLoading(true);

    try {
      var response = await getAllLimiteOperacional(user.idTipoCliente);

      setIdPerfilLimiteOperacional(user.idPerfilLimiteOperacional);
      setPerfisLimiteOperacional(response);
    } catch (error) {
      showAlertError(error.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Alert />
      <MenuDashboard>
        {idPerfilLimiteOperacional > 0 && (
          <FormularioLimiteOperacional
            idPerfil={idPerfilLimiteOperacional}
            perfisLimiteOperacional={perfisLimiteOperacional}
            label="Complete seu Cadastro"
            title="Para ter acesso as funcionalidade da plataforma é necessário concluir seu cadastro"
          />
        )}
      </MenuDashboard>
    </>
  );
}
