import { useState } from "react";
import DocumentoCard from "../../components/DocumentoCard";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "../../components/Snack/Snack";
import { addComprovantePedido } from "../../api/Cambio";
import DownloadIcon from '@mui/icons-material/Download';
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { useMutation, useQueryClient } from "react-query";
import useStyles from "./style";

const AnexarComprovante = ({
  documentos,
  loading,
  userID,
  idPedido,
  permiteUpload = true,
}) => {
  const initialStateDoc = {
    base64: "",
    nomeArquivo: "",
    idCliente: userID,
    contentType: "",
    tamanho: 0,
    idPedido: idPedido,
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [tipoAlert, setTipoAlert] = useState("success");

  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [formData, setFormData] = useState(initialStateDoc);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const mutation = useMutation(addComprovantePedido, {
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        setTipoAlert("error");
        setShowAlert(true);
        setAlertText(response.errorMessage);
      } else {
        setTipoAlert("success");
        setShowAlert(true);
        setAlertText("Documento anexado com sucesso");
        setFormData(initialStateDoc);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      setTipoAlert("error");
      setShowAlert(true);
      setAlertText(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.refetchQueries(PEDIDOCOMPROVANTES);
    },
  });

  const handlerOpenDoc = (event) => {
    event.preventDefault();
    setOpenAddDoc(!openAddDoc);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.error("Error: ", error);
    };
  };

  const handlerChangeDoc = (event) => {
    let file = event.target.files[0];
    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const handlerEnviarDoc = (event) => {
    event.preventDefault();
    mutation.mutate(formData);
  };

  const classes = useStyles();
  return (
    <>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <Grid item xs={12} className={classes.container}>
        <Grid
          item
          xs={10}
          container
          sx={{ pl: 2, display: "flex", justifyContent: "space-between" }}
        ></Grid>

        {documentos === undefined ||
          (documentos.data.length === 0 && (
            <Typography
              color="text.secondary"
              sx={{
                fontSize: ".875rem",
                mb: theme.spacing(0),
                textAlign: "center",
                marginBottom:2,
                marginTop:2,
              }}
            >
              Ainda não há comprovantes anexados neste pedido
            </Typography>
          ))}

        <Box className={classes.Box}>
          {permiteUpload && (
            <>
              <Button
                component="label"
                size="small"
                variant="outlined"
                sx={{ alignItems: 'center', display: 'flex' }}
                onChange={handlerChangeDoc}
              >
                <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
                Adicionar comprovante
                <input type="file" accept="image/*" hidden />
              </Button>
              <Typography color="text.secondary" textAlign='center' sx={{ mt: 1, mb: 1 }} variant="subtitle1">
                Seu arquivo não deve ultrapassar 5MB
              </Typography>
              {formData.tamanho > 0 && (
                <Box
                  sx={{
                    margin: "auto", justifyContent: "center", display: "flex", maxHeight: "60px",
                    maxWidth: "130px",
                  }}
                  component="img"
                  src={formData.base64}

                />
              )}
            </>
          )}
          {formData.tamanho > 0 && (
            <Box sx={{ margin: "auto", mt: 1, justifyContent: "center", display: "flex" }}>
              <Button
                variant="contained"
                component="label"
                size="small"
                onClick={handlerEnviarDoc}
              >
                Enviar comprovante
              </Button>
            </Box>
          )}
        </Box>

        <Grid container sx={{ pl: 2 }}>
          {loading ? (
            <>
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={1}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={2}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={3}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
            </>
          ) : (
            documentos !== undefined &&
            documentos.data.length > 0 &&
            documentos.data.map(
              (doc, index) =>
                doc.base64 !== "" && (
                  <Grid item xs={12} lg={12}>
                    <Box
                      fullWidth
                      mb={2}
                      sx={{
                        width: "100%",
                        display: "flex !important",
                        justifyContent: "center !important",
                      }}
                      key={index}
                    >
                      <DocumentoCard documento={doc} key={index} />
                    </Box>
                  </Grid>
                )
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AnexarComprovante;
