import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Icon: {
    opacity: 0.5,
    marginRight: 4,
  },
  media: {
    opacity: 0.4,
    height: "10em",
    background: "#000",
    // paddingTop: "56.25%",
  },
  Card: {
    display: "flex",
    boxShadow: "none !important",
    width: "100%",
    height: "5em",
    borderRadius: "12px",
    background: "#fcfcfc !important",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      boxShadow: "none !important",
      width: '100%',
      height: "auto",
      borderRadius: "12px",
      background: "#fcfcfc !important",
    },
  },

  img: {
    opacity: 0.4,
    width: 30,
    height: "100%",
    borderRadius: 2,
    alignItems: "center",
    objectFit: 'cover !important',
    [theme.breakpoints.down("sm")]: {
      width: 50,

    }
  }


}));

export default useStyles;