import { useState } from "react";
import { TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "4px !important",
    },
    "&:hover fieldset": {
      borderRadius: "0 !important",
    },
  },
});

export default function CampoImg({
  name,
  value,
  fullWidth,
  sx,
  handlerChange,
  label,
  size,
  img,
  enable,
}) {
  const [isError, setIsError] = useState(false);

  //useEffect(() => {
  //setIsError(value.length <= 0);
  //}, [value]);

  return (
    <CssTextField
      sx={sx}
      margin="normal"
      required
      fullWidth={fullWidth ?? true}
      id={label}
      label={label}
      size={size ?? "medium"}
      name={name}
      value={value}
      autoFocus
      onChange={handlerChange}
      //helperText={isError ? `${label} inválido!` : ""}
      error={isError}
      disabled={!enable}
      InputProps={{
        startAdornment: (
          <InputAdornment display="none">
            <Box component="img" sx={{ width: 20, mr:1 }} src={img}></Box>
          </InputAdornment>
        ),
      }}
    />
  );
}
