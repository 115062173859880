import api from "../../index";

// ? Busca se a loja está aberta ou fechada
export const GetFuncionamnentoLoja = async () => {
   try {
      const { data: response, status } = await api.get(`HorarioFuncionamento/Get`,);

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      };

   } catch (error) {
      throw new Error(error + "5448");
   }
};


