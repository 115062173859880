import { makeStyles } from "@mui/styles";

const important = "!important";

const useStyles = makeStyles((theme) => ({
  listaCabecalho: {
    display: "flex",
    justifyContent: "space-around !important",
    width: "100%",
    padding: "0 !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-around !important",
      width: "100%",
      padding: "0 !important",
    },
  },

  containerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    "& button:first-child": {
      marginTop: "2rem",
    },
  },

  Bandeira: {
    width: 25,
    height: 25,

    marginRight: 15,
  },

  IconButton: {
    background: "none !important",
    color: theme.palette.error.main + "!important",
  },
  Titulo: {
    paddingBottom: 0,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 12,
      fontSize: "16px !important",
    },
  },
  Grid: {
    display: "block",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      padding: 0,

      margin: "auto",
    },
  },
  descricao: {
    marginTop: 15,

    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  IconeOuro: {
    width: 25,
    marginRight: 5,
  },

  Step: {
    background: "#f7f7f7",
    padding: 6,
    maxHeight: "40px !important",
    minHeight: "40px !important",
    minWidth: "50%",
    maxWidth: "50%",
    alignItems: "center",
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      width: "25%",
      maxHeight: "40px !important",
      minHeight: "40px !important",
      minWidth: "25%",
      maxWidth: "25%",
    },
  },
  Botao: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
  },

  Number: {
    textAlign: "center",
    background: "#000",
    padding: "3px",
    borderRadius: 20,
    width: 20,
    height: 20,
    fontSize: "10px !important",
    color: "white !important",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      background: "#000",
      padding: "3px",
      borderRadius: 20,
      width: 20,
      height: 20,
      fontSize: "10px !important",
      color: "white !important",
    },
  },

  TextStep: {
    fontSize: "12PX !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px !important",
    },
  },

  Step2: {
    background: "#ccc",
    padding: 6,
    maxHeight: "40px !important",
    minHeight: "40px !important",
    minWidth: "50%",
    maxWidth: "50%",
    alignItems: "center",
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      minWidth: "25%",
      maxWidth: "25%",

      maxHeight: "40px !important",
      minHeight: "40px !important",
    },
  },
  // IconButton: {
  //     color: theme.palette.error
  // }
}));

export default useStyles;
