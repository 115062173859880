import FormularioLimiteOperacional from "../../components/Cadastro/FormularioLimiteOperacional";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/AuthContext";
import { getLimitesPerfilLimiteOperacional } from "../../api/LimiteOperacional";
import { MenuDashboard, PaginasMenuDashboard } from "../../components";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import useAlert from "../../hooks/useAlert";
import { Box } from "@mui/material";

export default function ClienteLimiteOperacionalPlus() {
  const [idPerfilLimiteOperacional, setIdPerfilLimiteOperacional] = useState(0);
  const [perfisLimiteOperacional, setPerfisLimiteOperacional] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();

  useEffect(() => {
    init(user?.id);
  }, []);

  const init = async (idCliente) => {
    setIsLoading(true);

    try {
      const response = await getLimitesPerfilLimiteOperacional(idCliente);

      if (!response) {
        setIdPerfilLimiteOperacional(0);
        setPerfisLimiteOperacional(null);
        // showAlertError("Não existem ");
      } else {
        if (response.errorMessage && response.errorMessage.length > 0) {
          showAlertError(response.errorMessage);
        } else {
          setIdPerfilLimiteOperacional(response[0]?.id);
          setPerfisLimiteOperacional(response);
        }
      }
    } catch (error) {
      showAlertError(error.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Alert />
      <MenuDashboard pagina={PaginasMenuDashboard.AumentoLimites}>
        {idPerfilLimiteOperacional && idPerfilLimiteOperacional > 0 ? (
          <>
            <FormularioLimiteOperacional
              idPerfil={idPerfilLimiteOperacional}
              perfisLimiteOperacional={perfisLimiteOperacional}
              label="Aumentar Limite"
              title="Para realizar operações com valor superior ao seu perfil é necessário fornecer informações para o seu cadastro."
            />
          </>
        ) : (
          <Box sx={{ margin: "auto", height: '100vh', pt: 12 }}>
            <PersonOffIcon color="primary" sx={{ margin: 'auto', fontSize: 40, textAlign: 'center', justifyContent: 'center', display: 'flex', mt: 3 }}></PersonOffIcon>
            <Typography sx={{ mt: 3, textAlign: 'center' }}>
              Não existem outras opções de perfil de limite operacional para
              serem selecionados no momento.
            </Typography>
          </Box>
        )}
      </MenuDashboard>
    </>
  );
}
