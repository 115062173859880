import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import useStyles from "./styles";
import { getCep } from "../../../api/Endereco";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "0 !important",
    },
    "&:hover fieldset": {
      borderRadius: "0 !important",
    },
  },
});

export default function CampoCep({
  name,
  value,
  handlerChange,
  label,
  size,
  setEndereco,
  idCliente,
  idTipoEndereco,
  fullWidth,
  recarregar = true,
}) {
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const valueOrigin = value.replace(/\D+/g, "");
    const isValid = valueOrigin.length === 8;
    setIsError(!isValid);
    if (isValid && recarregar) {
      handlerSetEndereco(valueOrigin);
    }
  }, [value]);

  const handlerSetEndereco = async (valueOrigin) => {
    var { data } = await getCep(valueOrigin, idCliente, idTipoEndereco);
    setEndereco(data);
  };

  return (
    <InputMask
      onChange={(event) => handlerChange(event)}
      value={value}
      mask="99999-999"
      unmask={true}
      maskChar="_"
    >
      {(props) => (
        <CssTextField
          margin="normal"
          required
          fullWidth={fullWidth}
          id={label}
          label={label}
          size={size}
          name={name}
          value={value}
          onChange={props?.handlerChange}

          className={classes.root}
        />
      )}
    </InputMask>
  );
}
