import api from "../../index";
import { configurationVariables } from "../../../environment/index";

const idOrigem = 3; //LojaCambio;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getVitrine = async () => {
  return await api.get("/CarrinhoSeguroViagem/GetVitrineSeguroViagem");
};

export const addItemVitrine = async (request) => {


  const obj = {
    idCliente: request.idCliente,
    idOrigem: idOrigem,
    idHotsite: idHotsite,
    IdDestino: request.idDestino,
    dataPartida: request.dataDe,
    dataRetorno: request.dataAte,
    passageiros: request.passageiros
  };



  return await api.post("/CarrinhoSeguroViagem/AddVitrineItemSeguroViagem", obj);
};

export const addPassageiros = async (request) => {
  return await api.post(
    "/CarrinhoSeguroViagem/AddPassageirosSeguroViagem",
    request
  );
};

export const addPagamento = async (request) => {
  return await api.post(
    "/CarrinhoSeguroViagem/AddPagamentoSeguroViagem",
    request
  );
};
