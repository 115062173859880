import { useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import MenuDashboard, { Paginas } from "../../../components/MenuDashboard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListItemButton from "@mui/material/ListItemButton";
import RoomIcon from "@mui/icons-material/Room";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import RightArrowIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import useStyles from "./styles";
import DadosPessoais from "./dadosPessoais";
import MeusEnderecos from "./endereco";
import Documentos from "./documentos";
import MeusDadosBancarios from "./MeusDadosBancarios";
import MinhaConta from "./MinhaConta";

const Panels = {
  OptionsEditions: 0,
  editDados: 1,
  editEnderecos: 2,
  editDocumentos: 3,
  editDadosBancarios: 4,
  editMinhaConta: 5
};



const painelsMenus = [
  {
    title: "Dados Pessoais",
    text: "Mantenha os dados pessoais atualizados.",
    icon: <PersonIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: Panels.editDados,

  },
  {
    title: "Endereço",
    text: "Mantenha o endereco atualizado, para não haver conflito em suas operações.",
    icon: <RoomIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: Panels.editEnderecos,

  },
  {
    title: "Documentos",
    text: "Confira os documentos de seu cadastro ou insira novos documentos.",
    icon: <InsertDriveFileIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: Panels.editDocumentos,
  },
  {
    title: "Dados bancários",
    text: "Confira os dados bancários de seu cadastro ou insira novos dados.",
    icon: <AccountBalanceIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: Panels.editDadosBancarios,
  },
  {
    title: "Minha Conta",
    text: "Configurar sua conta na loja.",
    icon: <ManageAccountsIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: Panels.editMinhaConta,
  },
]

export const MeusDados = () => {
  const cssStyles = useStyles();
  const [panel, setPanel] = useState(Panels.OptionsEditions);
  const theme = useTheme();

  const hendlerChangePanel = (id) => (event) => {
    setPanel(id);
  };

  return (
    <>
      <MenuDashboard pagina={Paginas.MeusDados}>
        <Grid
          container
          xs={12}
          spacing={{ xs: 0, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            margin: "auto !important",
            alignItems: "center !important",
          }}
        >
          {panel === Panels.OptionsEditions && (
            <Grid item xs={8}>
              <List>
                {
                  painelsMenus.map((item, index) => (
                    <ListItem key={index} sx={{ mb: 2 }} disablePadding>
                      <ListItemButton
                        // id={Panels.editDados.toString()}
                        onClick={hendlerChangePanel(item.idTipo)}
                      >
                        <ListItemText
                          sx={{ display: "flex" }}
                          className={cssStyles.optionsMenu}
                          primary={
                            <>
                              <p>
                                {item.icon}{" "}
                              </p>
                            </>
                          }
                          secondary={
                            <>
                              <Grid sx={{ ml: 1, mt: 1 }}>
                                <Typography sx={{ lineHeight: 0 }}>
                                  {item.title}
                                </Typography>
                                <br></br>
                                <Typography
                                  variant="subtitle"
                                  sx={{ lineHeight: "15px" }}
                                  color="text.secondary"
                                >
                                  {item.text}
                                </Typography>{" "}
                              </Grid>
                            </>
                          }
                        />
                        <ListItemIcon
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <RightArrowIcon sx={{ fontSize: ".93rem" }} />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  ))
                }

              </List>
            </Grid>
          )}
        </Grid>

        {panel === Panels.editDados && (
          <DadosPessoais
            handlerCancelEdit={hendlerChangePanel(Panels.OptionsEditions)}
          />
        )}
        {panel === Panels.editEnderecos && (
          <MeusEnderecos
            handlerCancelEdit={hendlerChangePanel(Panels.OptionsEditions)}
          />
        )}
        {panel === Panels.editDocumentos && (
          <Documentos
            handlerCancelEdit={hendlerChangePanel(Panels.OptionsEditions)}
          />
        )}
        {panel === Panels.editDadosBancarios && (
          <MeusDadosBancarios
            handlerCancelEdit={hendlerChangePanel(Panels.OptionsEditions)}
          />
        )}
        {panel === Panels.editMinhaConta && (
          <MinhaConta
            handlerCancelEdit={hendlerChangePanel(Panels.OptionsEditions)}
          />
        )}
      </MenuDashboard>
    </>
  );
};
