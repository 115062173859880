import { Box, Hidden } from "@mui/material";
import { BreadCrumbsPaginas } from "../../../../../components";
import PaymentsIcon from "@mui/icons-material/Payments";
import Money from "@mui/icons-material/PaidOutlined";
import TiposPedido from '../../../../../util/typesTipoPedido'

import MenuDashboard, {
  Paginas,
  SubMenus
} from "../../../../../components/MenuDashboard";
import Turismo from "../../../../../components/Vitrine/Turismo";

export default function VitrineCompraEspecie() {
  const breadCrumbs = [
    {
      label: "Moedas em espécie",
      icon: <Money sx={{ mr: 0.5 }} fontSize="inherit" />
    },
    {
      label: "Vender Moeda",
      icon: <PaymentsIcon sx={{ mr: 0.5, transform: "rotate(180deg)" }} fontSize="inherit" />
    }
  ]

  return (
    <>
      <MenuDashboard pagina={Paginas.Especie} subMenuSelecionado={SubMenus.VendaMoeda}>
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>
        <Turismo idTipoOperacao={TiposPedido.CompraEspecie} isEspecie={true} />
      </MenuDashboard>
    </>
  );
}
