import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "getmoney",
  UserStorage: "155hawdar2516JHb3GSCAWVbNQ==",
  UserToken: "561234+VAWDGHAWD1415wadfg+515==",
  UrlLoja: "https://dev-ecommerce-gmy-api.primecase.com.br/api",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#087b3e",
      light: "#B799F2",
      //lighter: "#ece6f7",
      dark: darkenColor("#087b3e", 10),
    },
    secondary: {
      main: "#7E7296",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
