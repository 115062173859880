import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    Container: {
        padding: 12,
        margin: "auto",
        maxWidth: '94vw',
        justifyContent: "center",
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up("sm")]: {
            maxWidth: "100em !important",
            padding:8,
            margin: "auto",
            alignItems: 'center',
            display: 'flex',
            marginTop: '4%',
            height: "auto",
        }
    },
    texto: {
        maxWidth: '100vw !important',
        padding: '15px !important',
        [theme.breakpoints.up("sm")]: {
            maxWidth: '37em !important',
        }
    },

    Titulo: {
        fontSize: "16px !important",
        fontWeight: '600 !important',
        color: "black",
        alignItems: "center",
        display: "flex",
        textAlign: "left",
        paddingTop: 5,
    },

    divAnexo: {
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            width: '70%',
        }

    }

}));

export default useStyle;
