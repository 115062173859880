import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  optionsMenu: {
    "& p:first-child": {
      fontWeight: "600 !important",
    },
    "& p": {
      fontSize: ".875rem !important",
      display:"flex",
      alignItems:'center',
    },
  },
}));

export default useStyle;
