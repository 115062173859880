import api from "../index";
import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

//?=================================== forma pagamento ================================
export const atualizarFormaPagamento = async (formData) => {
    try {
        const { data: response } = await api.post(
            "/PedidosCambio/AtualizarFormaPagamento",
            null,
            {
                params: {
                    idCliente: formData.idCliente,
                    idPedido: formData.idPedido,
                    idFormaPagamento: formData.idFormaPagamento,
                },
            }
        );
        return {
            data: response.data,
            errorMessage: response.errorMessage,
        };
    } catch (error) {
        throw new Error(error + " R7JD");
    }
};




//?=================================== cancelar pedido ================================
export const cancelarPedidoCambio = async (formData) => {
    try {
        const { status, data: response } = await api.post(
            "/PedidosCambio/Cancelar",
            null,
            {
                params: {
                    idCliente: formData.idCliente,
                    idPedido: formData.idPedido,
                    motivoCancelamento: formData.motivoCancelamento,
                },
            }
        );
        return {
            status,
            data: response.data,
            errorMessage: response.errorMessage,
        };
    } catch (error) {
        throw new Error(error + " RKFS");
    }
};


//?=================================== comprovante pedido ================================
export const getComprovantePedidoByIDPedido = async (idCliente, idPedido) => {
    try {
        const { data: response } = await api.get(
            `/ComprovantePedido/GetByIDPedido?idCliente=${idCliente}&idPedido=${idPedido}`
        );
        return response;
    } catch (error) {
        throw new Error(error + "R18DF");
    }
};

export const addComprovantePedido = async (formData) => {
    try {
        const { data: response } = await api.post("/ComprovantePedido/Insert", {
            idCliente: formData?.idCliente,
            idPedido: formData?.idPedido,
            nomeArquivo: formData?.nomeArquivo,
            base64: formData?.base64,
            tamanho: formData?.tamanho,
            contentType: formData?.contentType,
        });

        return response;
    } catch (error) {
        throw new Error(error + " R1KDI");
    }
};





//?=================================== Meus Pedidos ================================
export const getPedidosCambioByIDCliente = async (
    idCliente,
    pagina = 1
) => {

    const { data: pedidos, status } = await api.get(
        `/pedidosCambio/GetPedidosCambioByIDCliente`,
        {
            params: {
                idCliente: idCliente,
                idHotsite: idHotsite,
                pagina: pagina,
            },
        }
    );


    if (!!pedidos.errorMessage)
        throw new Error(pedidos.errorMessage)

    return pedidos.data;
};


export const getPedidosOuroByIDCliente = async (
    idCliente,
    pagina = 1
) => {
    const { data: pedidos, status } = await api.get(
        `/pedidosOuro/GetPedidosOuroByIDCliente`,
        {
            params: {
                idCliente: idCliente,
                idHotsite: idHotsite,
                pagina: pagina,
            },
        }
    );



    if (!!pedidos.errorMessage)
        throw new Error(pedidos.errorMessage)

    return pedidos.data;
};