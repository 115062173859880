import { Breadcrumbs, Typography } from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import useStyle from './style'

// ? modelo a ser seguido
/*
  list = [
     {
        label:"texto",
        icon:<Component/>
     },
     {
        label:"texto"
        icon:<Component/>
     }
  ]
*/

export default function BreadCrumbs({ list }) {

  const classes = useStyle();

  return (
    <Box
      className={classes.container}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {list.map((item, index) =>
          item.link ? (
            <Link underline="hover" color="inherit" href={item.link}>
              <Typography
                key={item.label}
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                {item.icon}
                {item.label}
              </Typography>
            </Link>
          ) : (
            <Typography
              key={item.label}
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {item.icon}
              {item.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
}
