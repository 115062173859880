import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Button: {
    padding: "9px !important",
    fontSize: "12px !important",
    borderRadius: "4px !important",
  },
}));
export default useStyles;
