import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Container: {
    height: "100vh",
  },
  Item: {
    margin: "auto !important",
    maxWidth: "33em !important",
  },
  CloseIcon: {
    marginLeft: "95%",
    marginBottom: "15px !important",
    cursor: "pointer",
    fontSize: "18px !important",
  },
  Titulo: {
    marginBottom: "20px !important",
    color: theme.palette.text,
  },
  Subtitulo: {
    fontWeight: "light",
    color: "#666",
    marginBottom: "20px !important",
    display: "flex",
  },
  FormControl: {
    width: "100% !important",
    marginBottom: "2em !important",
    marginTop: "1em !important",
  },
  BoxImg: {
    my: 3,
    backgroundColor: "none",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  Link: {
    marginLeft: 3,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
