import { useState, useEffect, Fragment } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import BasicModal from "../Modal/Modal";
import Seletor from "../Seletor";
import { useAuth } from "../../hooks/AuthContext";
import { useTheme } from "@mui/material/styles";
import useStyles from "./styles";
import {
  getPagadorRecebedorByIDClienteIDpaisIDTipo,
  getAllTiposPagadorRecebedor,
  desativaAtivaByID,
} from "../../api/RecebedorEstrangeiro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputLabel from "@mui/material/InputLabel";
import New from "./New";
import TiposPagadorRecebedor from '../../util/typesTipoPagadorRecebedor'
import { Box, Skeleton, Typography, Hidden, Grid } from "@mui/material";
import { LoadingBeneficiario } from "../";
import useAlert from "../../hooks/useAlert";
import { useMutation, useQuery } from "react-query";
import { PAGADORRECEBEDORIDPAISIDCLIENTEIDTIPO } from "../../util/typesReactQuery";
import { stringAvatar } from '../../util/helpers'

export default function PagadorRecebedor({
  idPais = 0,
  isOperacao = true,
  paises,
  onNext,
  loadingSelect = false,
  alertError
}) {
  const classes = useStyles();

  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [loadingBeneficiarios, setLoadingBeneficiarios] = useState(true);
  const [showModalNew, setShowModalNew] = useState(false);
  const [ErrorNewBeneficiario, setErrorNewBeneficiario] = useState();
  const idRecebedor = TiposPagadorRecebedor.Recebedor;

  const [selectedIDPais, setSelectedIDPais] = useState(idPais);
  const theme = useTheme();
  const { user } = useAuth();

  const { refetch: refetchRecebedor } = useQuery(PAGADORRECEBEDORIDPAISIDCLIENTEIDTIPO, () => getPagadorRecebedorByIDClienteIDpaisIDTipo(user.id, selectedIDPais, idRecebedor), {
    onSuccess: (data) => {
      if (!data || !!data.errorMessage)
        showAlertError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum Recebedor cadastrado em sistema - RE2B7 `)

      setBeneficiarios((prev) => ([...data.data]))

      setLoadingBeneficiarios(false)

    },
    onError: (error) => {
      showAlertError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R6FA4 `)
    }
  })


  useEffect(() => {
    if (selectedIDPais > 0)
      refetchRecebedor();

  }, [selectedIDPais]);



  const handlerSaveNew = (itemNew) => {
    showAlertSuccess("Pagador/Recebedor adicionado com sucesso!");
    // setTimeout(() => {
    setShowModalNew(!showModalNew);
    beneficiarios.push(itemNew);
    // }, 100);
  };

  const mutationDesativaAtiva = useMutation(desativaAtivaByID, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      // setLoading(true);
      if (!!data.errorMessage) {
        showAlertError(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RAEA5`);
        return;
      }


      if (!!!data) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possível ativar/desativar este beneficiário - R5A3A`
        );
        return;
      }

      showAlertSuccess("Alteração feita com sucesso!");

    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RF084 `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchRecebedor();
    },
  });

  async function desativaAtiva(idCliente, idPagadorRecebedor) {

    let dado = {
      idCliente: idCliente,
      idPagadorRecebedor: idPagadorRecebedor,
    }

    mutationDesativaAtiva.mutate(dado);
  }

  return (
    <>
      <Alert />
      {!loadingSelect ? (
        <>
          <Seletor
            setSelect={setSelectedIDPais}
            list={paises}
            size="small"
            label="País de destino"
            fullWidth
            value={selectedIDPais}
          />

          {selectedIDPais > 0 && (
            <InputLabel
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                color: theme.palette.primary.main,
                cursor: "pointer",
                marginBottom: 20,
                marginTop: 20,
                fontSize: 16,
                textAlign: "end",
              }}
              onClick={() => setShowModalNew(true)}
            >
              {`+ Adicionar Novo`}
            </InputLabel>
          )}
          <List dense className={classes.List} sx={{}}>
            {!loadingBeneficiarios &&
              beneficiarios &&
              beneficiarios?.map((b, index) => {
                return (
                  <Fragment key={b.id}>
                    {
                      b.id > 0 &&
                      (isOperacao ? (
                        b.ativo && (
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemButton
                              sx={{
                                paddingRight: 1,
                              }}
                              onClick={(event) => onNext(event, b)}
                            >
                              <ListItemAvatar>
                                <Avatar {...stringAvatar(b.nome, "large")} />
                              </ListItemAvatar>
                              <ListItemText
                                multiline
                                id={b.id}
                                primary={b.nome}
                                style={{ fontWeight: "bold" }}
                              />

                              <List className={classes.ListText}>
                                {b?.propriedades?.length > 0 && !!b?.propriedades[0]?.value && (
                                  <>
                                    <ListItemText
                                      className={classes.ListItemText}
                                      primary={
                                        <Typography
                                          color="text.secondary"
                                          sx={{ ml: 1, fontSize: 13 }}
                                        >
                                          {b.propriedades[0]?.label !== null
                                            ? b.propriedades[0].label
                                            : b.propriedades[0].descricao}
                                        </Typography>
                                      }
                                      secondary={
                                        <Typography
                                          color="text.secondary"
                                          sx={{
                                            ml: 1,
                                            fontSize: 13,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {b.propriedades[0].value}
                                        </Typography>
                                      }
                                    />
                                    {b?.propriedades?.length > 1 && !!b?.propriedades[1]?.value && (
                                      <ListItemText
                                        className={classes.ListItemText}
                                        primary={
                                          <Typography sx={{ ml: 1, fontSize: 13 }}>
                                            {b.propriedades[1].label !== null
                                              ? b.propriedades[1].label
                                              : b.propriedades[1].descricao}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography
                                            sx={{
                                              ml: 1,
                                              fontSize: 13,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {b.propriedades[1].value}
                                          </Typography>
                                        }
                                      />
                                    )}
                                  </>
                                )}


                              </List>
                              <Divider />
                              <ArrowForwardIosIcon
                                sx={{ color: "#444", fontSize: 14, marginLeft: 2 }}
                              />
                            </ListItemButton>
                          </ListItem>
                        )
                      ) : (
                        <ListItem sx={{ pb: 2 }} key={b.id}>
                          <ListItemAvatar>
                            <Avatar {...stringAvatar(b.nome, "large")} />
                          </ListItemAvatar>
                          <ListItemText
                            multiline
                            id={b.id}
                            primary={

                              <><Grid container sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}><Typography sx={{ fontWeight: 'bold', lineHeight: '1px' }}>{b.nome}</Typography> <Hidden smUp>
                                <Button
                                  component="label"
                                  size="small"
                                  onClick={() => {
                                    desativaAtiva(user.id, b.id);
                                  }}
                                >
                                  {b.ativo ? "Desativar" : "Ativar"}
                                </Button></Hidden></Grid></>

                            }
                            style={{ fontWeight: "bold" }}
                            secondary={<Hidden smUp>
                              <List key={b.id}>
                                {b.propriedades.map(
                                  (prop, index) =>
                                    prop.value !== null && (
                                      <>
                                        <ListItemText
                                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                                          key={index}
                                          primary={<Typography sx={{ fontSize: 12 }}>
                                            {prop.label !== null
                                              ? prop.label
                                              : prop.descricao}
                                          </Typography>}
                                          secondary={<Typography sx={{ ml: 1, fontSize: 12, fontWeight: 'bold' }} color="text.secondary">{prop.value}</Typography>}
                                        />
                                      </>
                                    )
                                )}
                              </List>
                            </Hidden>}
                          />
                          <Hidden smDown>
                            <List key={b.id}>
                              {b.propriedades.map(
                                (prop, index) =>
                                  prop.value !== null && (
                                    <>
                                      <ListItemText
                                        sx={{ display: "flex", }}
                                        key={index}
                                        primary={
                                          <Typography color="text.secondary" sx={{ ml: 1, fontSize: 13 }}>
                                            {prop.label !== null
                                              ? prop.label
                                              : prop.descricao}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography color="text.secondary"
                                            sx={{ ml: 1, fontSize: 13, fontWeight: "bold" }}

                                          >
                                            {prop.value}
                                          </Typography>
                                        }
                                      />
                                    </>
                                  )
                              )}
                            </List>
                          </Hidden>
                          <Hidden smDown>
                            <Button
                              component="label"
                              size="small"
                              onClick={() => {
                                desativaAtiva(user.id, b.id);
                              }}
                            >
                              {b.ativo ? "Desativar" : "Ativar"}
                            </Button>
                          </Hidden>

                        </ListItem>
                      ))
                    }
                  </Fragment>
                );
              })}

            {loadingBeneficiarios &&
              [...Array(3)].map((_, index) => (
                <Skeleton height={90} index={index} />
              ))}
          </List>
        </>
      ) : (
        <LoadingBeneficiario />
      )}

      <BasicModal
        show={showModalNew}
        close={() => setShowModalNew(!showModalNew)}
        error={ErrorNewBeneficiario}
      >
        <New
          idCliente={user.id}
          idPais={selectedIDPais}
          setError={setErrorNewBeneficiario}
          idTipoPagadorRecebedor={idRecebedor}
          onSetNewPagadorRecebedor={handlerSaveNew}
        />
      </BasicModal>
    </>
  );
}
