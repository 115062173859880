import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Saldo: {
    height: "auto",

    borderRadius: 8,

    background: "#fff",
    marginTop: "0",
    [theme.breakpoints.up("sm")]: {},
  },
  Options: {
    textTransform: "capitalize !important",
    marginTop: 30,
    marginRight: "6px !important",
  },
  SaldoConta: {
    background: "#fcfcfd",
    border: "1px solid #ddd",
    borderRadius: 8,
    padding: 15,
  },
  blocoAtivo: {
    display: "flex",
    padding: 15,
    borderRadius: 3,
    border: "1px solid #ddd",
    marginTop: 20,
  },
  CompleteModal: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  Button: {
    marginRight: "12px !important",
    marginLeft: "12px !important",
    borderRadius: "8px !important",
    padding: "10px !important",
  },

  BlocoCripto: {
    background: "#fcfcfd",
    padding: 35,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  Link: {
    textDecoration: "none",
  },
}));

export default useStyles;
