import { Box, Grid, Hidden } from "@mui/material";
import { BreadCrumbsPaginas, MenuDashboard, Titulo } from "../../components";
import useStyle from './style'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';

const CarrinhoVazio = () => {

   const classes = useStyle();

   const breadCrumps = [
      {
         label: "Carrinho",
         icon: <ShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      },
      {
         label: "Carrinho vazio",
         icon: <RemoveShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      }
   ]



   return <>
      <MenuDashboard pagina={-1}>
         <Hidden smUp>
            <Box sx={{ pl: 2 }}>
               <BreadCrumbsPaginas list={breadCrumps} />
            </Box>
         </Hidden>

         <Hidden smDown>
            <Box sx={{ pt: 2 }}>
               <BreadCrumbsPaginas list={breadCrumps} />
            </Box>
         </Hidden>
         <Grid
            sx={{
               mt: 3,
               width: '100%',
               height: 'auto',
               pl: 3,
               pr: 3
            }}
         >
            <Grid item xs={12} className={classes.container}>
               <RemoveShoppingCartOutlinedIcon color="primary" className={classes.icon} />
               <Titulo sx={{mt:2}} color="text" align="center">Seu carrinho está vazio

               </Titulo>
            </Grid>
         </Grid>

      </MenuDashboard>
   </>
};


export default CarrinhoVazio;