import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  ListItem: {
    backgroundColor: "#fcfcfc !important",
    margin: 2,
  },

  emfaseText: {
    "& span": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem !important",
      fontWeight: "600 !important",
    },
  },

  fontBold: {
    fontWeight: 900,
  },

  Detalhes: {
    background: "#fcfcfc",
    padding: 15,
    border: "1px solid #ccc",
    minHeight: "60vh",
  },
  Codigo: {
    color: theme.palette.primary.main,
  },
  Card: {
    display: "flex",
    boxShadow: "none !important",
    width: "100%",
    height: "6em",
    borderRadius: "12px",
    background: "#fff !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      boxShadow: "none !important",
      width: "100%",
      height: "3em",

      borderRadius: "12px",
      background: "#fff !important",
    },
  },
  PedidoCambio: {
    cursor:'pointer',
    // border: "1px solid #eee",
    marginBottom: 15,
    // boxShadow: "1px 3px 1px #eee",
    "&:hover": {
     background:"#fcfcfc",
    },
  },
}));

export default useStyle;
