import axios from "axios";
import ApiUrlCall from "../util/ApiUrlCall";
import { configurationVariables } from "../environment/index";

const api = axios.create({ baseURL: ApiUrlCall });

function deleteAndResetLoacalStorage() {
  localStorage.removeItem(configurationVariables.UserStorage);
  localStorage.removeItem(configurationVariables.UserToken);
  window.location.href = "/login";
}

api.interceptors.request.use((req) => {
  let token = localStorage.getItem(configurationVariables.UserToken);
  req.headers.Authorization = `Bearer ${token ?? ""}`;
  return req;
});

// TODO: Fazer modificação de como verificar se o usuario foi alterado pelo local storage.
api.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      console.error("Usuario informado está invalido");
      deleteAndResetLoacalStorage();
      return;
    }

    if (response.data.errorMessage === "Usuário inválido") {
      console.error("Usuario informado está invalido");
      deleteAndResetLoacalStorage();
      return;
    }
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      deleteAndResetLoacalStorage();
    }
  }
);

export default api;
