import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  Container: {
    margin: 'auto'
  },
  Item:{
   padding:20,
   border:'1px solid #888',
   background:"#fcfcfc",
   borderRadius:10,
  }
}));
