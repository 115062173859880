//Visão corretora

const TiposPedido = {
   VendaEspecie: 1,
   CompraEspecie: 2,
   Remessa: 3,
   Recebimento: 4,
   SeguroViagem: 5,
   VendaOuro: 8,
}

export default TiposPedido;