import { Box, Grid, Skeleton } from "@mui/material"



const LoadingFormaPagamento = () => {
   return (
      <Grid container>
         <Grid item xs={12}>
            <Skeleton height={80} animation="wave" />
         </Grid>
         <Grid item xs={12}>
            <Skeleton height={80} animation="wave" />
         </Grid>
         <Grid item xs={12}>
            <Skeleton height={80} animation="wave" />
         </Grid>
         

      </Grid>
   )
}

export default LoadingFormaPagamento;
