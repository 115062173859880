import { useNavigate } from "react-router-dom";
import OpcoesPagamento from "../../../../../components/OpcoesPagamento";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { useAuth } from "../../../../../hooks/AuthContext";
import { addPagamento } from "../../../../../api/Carrinho/TranferenciaInternacional";
import { Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import useAlert from "../../../../../hooks/useAlert";
import { getlocalStorageTimerCarrinho, saveLocalStorageTimerCarrinho } from '../../../../../util/LocalStorage'
import { Timer } from "../../../../../components";
import usePedido from "../../../../../hooks/usePedido";
import { StepRecebimento } from '../../../../../util/StepsComercial'

export function Pagamento() {
  const idTipoPedido = 4;
  const navigation = useNavigate();
  const { user } = useAuth();
  const { Alert, showAlertError } = useAlert();
  const { itensPedido } = usePedido();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const [dataLocal, setDataLocal] = useState("");

  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();

  useEffect(() => {


    if (!!itensPedido?.dataExpiracao) {
      saveLocalStorageTimerCarrinho(itensPedido?.dataExpiracao);
      setDataLocal(
        itensPedido?.dataExpiracao
      );
    }
  }, [itensPedido]);


  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada)
      showAlertError(errorLojaFechada)
      return;
    }

    if (lojaFechada && lojaFechada.isFechada)
      navigation(
        "/LojaFechada", false
      );

  }, [lojaFechada, errorLojaFechada])

  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    setLoadingAddPagamento(true);

    const response = await addPagamento(user.id, pagamento);

    if (response && !response.errorMessage) {
      setTimeout(() => {
        navigation("/carrinho/transferencia-internacional/recebimento/itemRecebimento");
      }, 2000);
    }
    setTimeout(() => {
      setLoadingAddPagamento(false);
    }, 2100);
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/recebimento/vitrine");
  };


  return (
    <div>
      <Alert />
      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={true}
        activeStep={1}
        steps={StepRecebimento}
      >

        {
          !!dataLocal &&
          <Timer active={!!dataLocal} dataExpiracao={dataLocal} caminhoRedirect={"/CarrinhoVazio"} />
        }


        {loadingAddPagamento ?
          (
            <Skeleton animation="wave" height={50} width={200} />
          )
          :
          (
            <Typography
              style={{ fontSize: "18pt", marginBottom: "20pt" }}
            >
              Informe como deseja receber
            </Typography>
          )
        }

        <OpcoesPagamento
          idTipoPedido={idTipoPedido}
          onNext={handlerNext}
          loadingAddPagamento={loadingAddPagamento}
        />
      </CarrinhoPage>
    </div>
  );
}
