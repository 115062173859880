import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  criar: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",
      width: "33em !important",
      maxWidth: "40em !important",
    },
  },
  Voltar: {
    width: "18px !important",
    cursor: "pointer",
    marginTop: "15px",
    marginLeft: "25px",
  },
}));

export default useStyles;
