import { Box, Grid, Typography } from "@mui/material/";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Rodape from "../Rodape";
import useStyles from "./styles";

export default function BoxFinalizacao({ handleBack, titulo, message, icon }) {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.criar} xs={12}>
        <Grid item className={classes.criar} lg={6}>
          <Grid
            item
            xs={12}
            alignItems="center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
            justifyContent="center"
          >
            <ArrowBackIosNewIcon
              onClick={handleBack}
              className={classes.Voltar}
            />
          </Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {icon}
            <Typography
              style={{
                fontWeight: 700,
                marginBottom: 20,
                color: "#555",
              }}
              component="h1"
              variant="h5"
            >
              {titulo}
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                color: "#777",
                marginBottom: 20,
              }}
              component="h1"
              variant="body1"
            >
              {message}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Rodape />
    </>
  );
}
