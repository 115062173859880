import { valueToPercent } from "@mui/base";
import { formatMoneyInString } from './helpers'

// Calculo da moeda nacional
export const calculaMoedaNacionalIOFVET = (valorME, taxa, aliquotaIOF, tarifaAdministrativa, valorIOFCupom = 0, aliquotaIR = 0) => {

   let valorMNsemIOF = valorME * taxa;
   let valorIOF = valorIOFCupom <= 0 ? (valorMNsemIOF * (aliquotaIOF / 100)) : valorIOFCupom;
   let valorIR = aliquotaIR <= 0 ? aliquotaIR : (valorMNsemIOF * (aliquotaIR / 100));
   let valorMNComIOF = valorMNsemIOF + valorIOF + valorIR;
   let valorComTaxaIOF = valorMNComIOF + tarifaAdministrativa;
   let vet = valorComTaxaIOF / valorME;

   return {
      vetValor: vet,
      valorMoedaNacional: valorComTaxaIOF,
      valorIOF,
      valorIR,
   }
}

export const calculoMoedaNacionalRecebimento = (valorME, taxa, aliquotaIOF, tarifaAdministrativa, valorIOFCupom = 0, aliquotaIR = 0) => {

   let valorMNsemIOF = valorME * taxa;
   let valorIOF = valorIOFCupom <= 0 ? (valorMNsemIOF * (aliquotaIOF / 100)) : valorIOFCupom;
   let valorIR = aliquotaIR <= 0 ? aliquotaIR : (valorMNsemIOF * (aliquotaIR / 100));
   let valorMNComIOF = (valorMNsemIOF - valorIOF) - valorIR;
   let valorComTaxaIOF = valorMNComIOF - tarifaAdministrativa;
   let vet = valorComTaxaIOF / valorME;

   return {
      vetValor: vet,
      valorMoedaNacional: valorComTaxaIOF,
      valorIOF,
      valorIR,
   }
}


// validação da escala da moeda
export const validaEscala = (escala, value) => {
   let message = "";
   let valueNumber = parseFloat(value.replace('.', '').replace(',', '.'));
   let escalaNumber = parseFloat(escala.replace('.', '').replace(',', '.'))

   if (valueNumber % escalaNumber !== 0)
      message = `O valor da moeda estrangeira deve ser um valor múltiplo de  ${escala} `;

   return message;
}


export default function brlFormatter(value, currencyFormat) {
   if (!Number(value)) return "";

   value = parseFloat((value / 100)).toFixed(2)

   const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: currencyFormat,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
   }).format(value);

   return `${amount}`;
}