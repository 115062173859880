import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({

   Valor: {
      display: "flex",
      marginBottom: 20,
      alignItems: "center",
      justifyContent: "space-between",
   },

   FormaPagamento: {
      display: "flex",
      padding: 20,
      marginBottom: 20,
      alignItems: "center",
      borderRadius: 4,
      border:'1px solid #eee',
      cursor: "pointer",
      backgroundColor: "#fff",
      justifyContent: "space-between",
      '&:hover': {
         background:'#fcfcfc',
       },
   },

   checkedPay: {
      border: `2px solid  ${theme.palette.primary.main}`,
   },

   Titulo: {
      fontWeight: "500",
      paddingBottom: 40,
      color: "#444",
   },

}))

export default styles;