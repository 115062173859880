import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";

const DeviderCustom = () => {
  const theme = useTheme();

  return <Grid item xs={12} sx={{ mt: theme.spacing(3) }}></Grid>;
};

export default DeviderCustom;
