import { makeStyles } from "@mui/styles";

const CampoOperacaoStyle = makeStyles((theme) => ({
  root: {
    "& input:disabled": {
      cursor: "not-allowed",
      borderRadius: "0 !important",
    },
  },
}));

export default CampoOperacaoStyle;
