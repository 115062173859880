import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  Box: {
   
    padding: 3,
    overflowX: "hidden",
    overflowY: "scroll",
    [theme.breakpoints.up("sm")]: {
      
      overflowX: "hidden",
      overflowY: "scroll",
    },
  },
  // container: {

  //   background: "#fff",
  //   display: "block",
  // },

  head: {
    ml: 1,
    padding: 10,
    alignItems: "center",
    mt: 4,
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 35,
      alignItems: "center",

      display: "flex",
      maxWidth: "70em !important",
      justifyContent: "space-between",
    },
  },
  seletor: {
    padding: '0 !important',
    [theme.breakpoints.up("sm")]: {
      padding: '0 !important',
    },
  },
  Rodape: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    position: 'fixed',
    bottom: '2rem',
    left: '50%',
    transform: 'translateX(-50%)',

    [theme.breakpoints.up("sm")]: {
      position: "relative",
      display: "flex",
      marginTop: '1.5rem',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiStep-horizontal': {
        height: '1.5rem !important',
        padding: 0,
      }

    },
  },
  Topo: {

    padding: '2px !important',

    [theme.breakpoints.up("sm")]: {

      paddingTop: '15px !important',
      paddingLeft: '15px !important',
      paddingBottom:'10px',


    },
  },
  Botoes: {
    marginLeft: "1em",
    marginRight: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up("sm")]: {

      display: 'flex',
      justifyContent: 'space-between',

    },
  },
}));

export default useStyle;
