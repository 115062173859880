import { makeStyles } from "@mui/styles";
import invite from "../../../assets/images/invite.jpg";
import invitemobile from "../../../assets/images/invitemobile.jpg";

const useStyles = makeStyles((theme) => ({
  ListItem: {
    paddingBottom: 15,
    width: "100%",
    height: "5em",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
  Button: {
    minWidth: "10em !important",
    fontSize: 12,
    padding: "3px !important",
    [theme.breakpoints.up("sm")]: {
      minWidth: "12em !important",
      padding: "10px !important",
    },
  },

  Card: {
    padding: 30,
    borderRadius: 20,
    minHeight: "80vh",
    background: "#fcfcfc",
    backgroundColor:'#eee',
    // backgroundImage: `url(${invitemobile})`,
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      padding: 30,
      borderRadius: 20,
      backgroundColor:'#eee',
    //   boxShadow:'0px 2px 6px 5px #f5f5f5',
      // backgroundImage: `url(${invite})`,
      backgroundSize: "cover",
      minHeight: "30em",
      background: "#fcfcfc",
      margin: 20,
    },
  },
}));

export default useStyles;
