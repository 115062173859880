// getLuminance.js

function getLuminance(hexColor) {
    // Convert the hexadecimal value into RGB components
    const red = parseInt(hexColor.substr(1, 2), 16);
    const green = parseInt(hexColor.substr(3, 2), 16);
    const blue = parseInt(hexColor.substr(5, 2), 16);
  
    // Calculate the luminance with the appropriate formula
    return (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
  }
  
  export default getLuminance;
  