import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  Valor: {
    display: "flex",
    padding: 10,
    marginBottom: 20,
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "#fcfcfd",
    justifyContent: "space-between",
  },

  PontoRetirada: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "#eee",
    justifyContent: "space-between",
  },

  EspacoDescricaoPontoRetirada: {
    display: "block",
    clear: "both",
    padding: 20,
    marginBottom: 20,
    alignItems: "left",
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    justifyContent: "space-between",
  },
  checked: {
    border: `2px solid  ${theme.palette.primary.main}`,
  },

  Titulo: {
    fontWeight: "500",
    paddingBottom: 40,
    color: "#444",
  },
  EnderecoBox: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "#eee",
    justifyContent: "space-between",
 },

 checked: {
    border: `2px solid  ${theme.palette.primary.main}`,
 },
}));

export default styles;
