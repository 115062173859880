import api from "../index";

//?=================================== forma pagamento ================================

export const desativarConta = async (idCliente) => {
    const data = await api.delete(
        `/Cliente/DeletarCliente?idCliente=${idCliente}`
    );

    return {
        data: data?.data,
        errorMessage: data.errorMessage
    }

}

export const isTokenAtivo = async () => {
    const data = await api.get(
        `/cliente/IsTokenAtivo`
    );

    return {
        data: data?.data?.data ?? false,
        status: data?.data?.status ?? 0,
        errorMessage: data?.errorMessage
    }

}



