import { Grid, Skeleton } from "@mui/material";

const ItemVitrine = () => {
   return (<>
      {
         new Array(6).fill(1).map((i, index) => (
            <Skeleton key={index} sx={{ p: '0 !important', m: '0 !important' }} height={50} width={"100%"} animation="wave" />
         ))
      }

   </>);
}

export default ItemVitrine;