import "./styles.scss";

export default function CartaoViagemVisual({
  numero,
  focused,
  placeholders,
}) {
  return (
    <div key="Cards" className="rccs">
      <div style={{ height: "100px", margin:"auto" }}>
        {/* esta é a parte da frente do cartão */}
        {/* <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__issuer" />
          <div
            className={[
              "rccs__number",
              numero.replace(/ /g, "").length > 16 ? "rccs__number--large" : "",
              focused === "number" ? "rccs--focused" : "",
              numero.substr(0, 1) !== "•" ? "rccs--filled" : "",
            ]
              .join(" ")
              .trim()}
          >
            {numero}
          </div>
          <div
            className={[
              "rccs__name",
              focused === "name" ? "rccs--focused" : "",
              nome ? "rccs--filled" : "",
            ]
              .join(" ")
              .trim()}
          >
            {nome || placeholders.name}
          </div>
          <div className="rccs__chip" />
        </div> */}
        <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__stripe" />
          <div className="rccs__signature" />
          <div
            className={[
              "rccs__number",
              numero.replace(/ /g, "").length > 16 ? "rccs__number--large" : "",
              focused === "number" ? "rccs--focused" : "",
              numero.substr(0, 1) !== "•" ? "rccs--filled" : "",
            ]
              .join(" ")
              .trim()}
          >
            {numero}
          </div>
          {/* <div
              className={[
                'rccs__cvc',
                focused === 'cvc' ? 'rccs--focused' : '',
              ].join(' ').trim()}
            >
              {cvc}
            </div> */}
          <div className="rccs__issuer" />
        </div>
      </div>
    </div>
  );
}
