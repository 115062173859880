import React from "react";
import VitrineSeguroViagem from "../../../components/Vitrine/VitrineSeguroViagem";

function VitrineSeguro() {
  return (
    <div>
      <VitrineSeguroViagem />
    </div>
  );
}

export default VitrineSeguro;
