import { makeStyles } from "@mui/styles";

const ip = " !important";

export const Style = makeStyles((theme) => ({
  Grid: {

  
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
      
      maxWidth:'100vw !important',
    },
    [theme.breakpoints.up("xl")]: {
        justifyContent: "center",
   

        maxWidth:'90vw !important',
      
      },
  },

  Box: {
    [theme.breakpoints.up("xs")]: {
   
      
      },
 

    [theme.breakpoints.up("xl")]: {
       
      
      },
  },
  Operar: {
    [theme.breakpoints.up("xs")]: {
   
    
      },
 

    [theme.breakpoints.up("xl")]: {
       
      
      },
  },

  FormControl: {
    [theme.breakpoints.up("xs")]: {

      width:"100%",
      },
 

    [theme.breakpoints.up("lg")]: {
       width:"60% !important"
      
      },
  },

}));
