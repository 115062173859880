import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Grid: {
   width:'100vw !important',
    background: "#fff",
  },
}));

export default useStyles;
