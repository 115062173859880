import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
   Valor: {
      display: "flex",
      padding: 0,
      alignItems: "center",
      borderRadius: 8,
    
      backgroundColor: "#FFF",
      justifyContent: "space-between",
   },
   Bandeira: {
      width: 20,
      marginRight:8,
   },
   Taxa:{
      marginBottom:'9px',
   }
}));

export default style;