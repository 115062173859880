import { useState } from "react";
import PagadorRecebedor from "../../components/PagadorRecebedor";
import { getAllPaisesPossuiPagadorRecebedorParametros } from "../../api/Pais";
import MenuDashboard from "../../components/MenuDashboard";
import TiposPagadorRecebedor from '../../util/typesTipoPagadorRecebedor'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { LoadingBeneficiario } from "../../components";
import { useQuery } from "react-query";
import { PAISPAGADORRECEBEDOR } from "../../util/typesReactQuery";
import useAlerts from '../../hooks/useAlert';

export default function RecebedorEstrangeiro() {
  const [paises, setPaises] = useState(null);
  const [loadingSelect] = useState(false);
  const { Alert, showAlertError } = useAlerts();

  useQuery(PAISPAGADORRECEBEDOR, () => getAllPaisesPossuiPagadorRecebedorParametros(TiposPagadorRecebedor.Recebedor), {
    onSuccess: (data) => {
      if (!data || !!data.errorMessage)
        showAlertError(`Ops, algo deu errado, 
        por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum pais cadastrado em sistema - R99DC `)

      setPaises((prev) => ([...data.data]))

    },
    onError: (error) => {
      showAlertError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R9756 `)
    }
  })

  return (
    <div>
      <>
        <Alert />
        <MenuDashboard pagina={"PagadorRecebedor"}>
          <Grid
            container
            xs={12}
            lg={6}

            sx={{
              p: 2,
              margin: "auto !important",

              alignItems: "center !important",
            }}

          >
            <br />
            <br />
            <Typography sx={{ mb: 3, mt: 2 }} variant="h6">Beneficiario</Typography>

            {!!paises?.length ? (
              <>
                <PagadorRecebedor
                  idPais={paises[0].id}
                  paises={paises}
                  isOperacao={false}
                  onNext={() => { }}
                  loadingSelect={loadingSelect}
                />
              </>
            ) : (
              <LoadingBeneficiario />
            )}
          </Grid>
        </MenuDashboard>
      </>
    </div>
  );
}
