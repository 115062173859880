import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { TooltipInformacao, PositionTooltip } from "../../TooltipInformacao";
import InfoIcon from "@mui/icons-material/Info";
import { Skeleton } from "@mui/material";
import {
  formatMoneyInStringForm,
  formatMoneyInString,
  formatMoney,
} from "../../../util/helpers";

export default function Tarifa({
  impostos,
  tarifaAdministrativa,
  taxa,
  simbolo,
  casasDecimais,
  isIofRecebimento = false
}) {



  return (
    <>
      <Box sx={{}}>
        {impostos &&
          impostos.map((i) => {
            return (
              <Typography
                sx={{ ml: 1, mt: 1, alignItems: "center", display: "flex" }}
                color="text.secondary"
                variant="body1"
              >
                {
                  isIofRecebimento ?

                    <Box display="flex" alignItems="center">
                      <TooltipInformacao
                        positionStart={PositionTooltip.left}
                        descricao="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros."
                      >
                        <InfoIcon sx={{fontSize:20, mr:1}} color="primary"></InfoIcon>
                      </TooltipInformacao>{" "}
                       {i.descricao} ({i.aliquota}%)

                    </Box>
                    :
                    <Box display="flex" alignItems="center">
                      <TooltipInformacao
                        positionStart={PositionTooltip.left}
                        descricao="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros."
                      >
                        <InfoIcon sx={{fontSize:20,  mr:1}} color="primary"></InfoIcon>
                      </TooltipInformacao>{" "}
                      {i.descricao} ({i.aliquota}%): R$ {formatMoneyInStringForm(i.valor, 2)}
                    </Box>

                }

              </Typography>
            );
          })}

        <Box />
        {!!tarifaAdministrativa && (
          <Typography
            sx={{ ml: 1, mt: 1, alignItems: "center", display: "flex" }}
            color="text.secondary"
            variant="body1"
          >

            <Box display="flex" alignItems="center">
              <TooltipInformacao
                positionStart={PositionTooltip.left}
                descricao="Valor cobrado para realizar as remessas para o exterior. A taxa varia de acordo com o banco."
              >
                <InfoIcon sx={{fontSize:20,  mr:1}} color="primary"></InfoIcon>
              </TooltipInformacao>{" "}
              {"Tarifa administrativa"}: R$ {formatMoney(tarifaAdministrativa, 2)}
            </Box>

          </Typography>
        )}

        <Typography
          sx={{  alignItems: "center", display: "flex", ml:1,mt:1 }}
          color="text.secondary"
          variant="body1"
        >
          {taxa && taxa > 0 && (
            <Box display="flex" alignItems="center">
              <TooltipInformacao
                positionStart={PositionTooltip.left}
                descricao="*Taxa calculada sem IOF"
              >
                <InfoIcon sx={{fontSize:20,  mr:1}} color="primary"></InfoIcon>
              </TooltipInformacao>{" "}
              {simbolo}: {formatMoneyInString(taxa).replace(".", ",")}
            </Box>
          )}
        </Typography>
      </Box>
    </>
  );
}
