import { useState, useEffect } from "react";
import { VitrineBase } from "./VitrineBase";
import Typography from "@mui/material/Typography";
import { getVitrineOuro, getValoresItemOuro } from "../../../api/Vitrine";
import { addItemOuro } from "../../../api/Carrinho/Ouro";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert/index";
import { LoadingVitrine } from "../../";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../util/helpers";
import TiposPedido from "../../../util/typesTipoPedido";
import TiposItemCarrinho from "../../../util/typesTipoItemCarrinho";
import usePedido from "../../../hooks/usePedido";
import { formatMoneyStringInDecimal } from "../../../util/helpers";
import {
  VITRINEOURO,
  GETVALORESITEMSOURO,
} from "../../../util/typesReactQuery";
import { useQuery } from "react-query";
import TermosdeUso from "../../Termos";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { verificaPedidoDiferente } from '../../../util/helpers'

export default function Ouro({ idTipoOperacao, isEspecie }) {
  const {
    Alert,
    showAlertSuccess,
    showAlertError,
    showAlertInfo,
    showAlertWarning,
  } = useAlert();

  const { user } = useAuth();
  const navigation = useNavigate();
  const [termoAceito, setTermoAceito] = useState(false);
  const [termoRespon, setTermoRespon] = useState(false);
  const [vitrine, setVitrine] = useState(null);
  const [itemVitrine, setItemVitrine] = useState({ valorMe: "" });
  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [isErrorTermo, setIsErrorTermo] = useState(false);
  const [isErrorTermoResp, setIsErrorTermoResp] = useState(false);
  const [valorItemCarrinho, setValorItemCarrinho] = useState();
  const { refetchPedido, itensPedido: pedido } = usePedido();

  const [itemCarrinho, setItemCarrinho] = useState({
    idItemVitrine: 0,
    valorMe: 0,
    valorMn: 0,
    impostos: [],
    valorVet: 0,
    idFinalidade: 0,
    idVitrine: 0,
    idHotsite: user?.idHotsite ?? 0,
    valorTaxa: 0,
    idTaxa: 0,
    idPraca: 0,
    idTipoCartao: 0,
    moeda: {},
  });

  const configInitialvalue = (obj) => {
    if (obj) {
      if (obj.valorInicial > 0) {
        let valor = String(obj.valorInicial);
        return valor;
      }

      if (obj.valorMinimo > 0) {
        let valor = String(obj.valorMinimo);
        return valor;
      }
      if (obj.escala > 0) {
        let valor = String(obj.escala);
        return valor;
      }
    }

    return "00";
  };

  const init = async (response) => {
    setLoadingButton(true);
    try {
      let valotInicial = configInitialvalue(response.itensVitrine[0]);

      setVitrine(response);
      setItemVitrine({ ...response.itensVitrine[0], valorMe: valotInicial });
    } catch (error) {
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2200);
    }
  };

  const { isFetching: isLoadingVitrine } = useQuery(
    [VITRINEOURO],
    () => getVitrineOuro(user.id),
    {
      onSuccess: (data) => {
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhuma vitrine de ouro - RD925`
          );
          return;
        }
        if (data.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RP51Y`
          );
          return;
        }

        init(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RBDGP`
        );
      },
      onSettled: () => {
        setTimeout(() => {
          setLoadingButton(false);
        }, 2200);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: refetchImpostos, isFetching: isLoadingImpostos } = useQuery(
    [GETVALORESITEMSOURO],
    () =>
      getValoresItemOuro(
        vitrine?.id ?? 0,
        itemVitrine?.id ?? 0,
        vitrine?.idPraca ?? 0,
      ),
    {
      onSuccess: (data) => {

        if (data?.errorMessage) {
          console.error(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RJEYV`
          );
          return;
        }

        setValorItemCarrinho(data?.data);

      },
      onError: (error) => {
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - R3RPP`
        );
      },


      refetchOnWindowFocus: true,
      refetchInterval: 5000 * 60,
    }
  );

  const isValidacoesPreCalculo = (valor) => {
    if (formatMoneyStringInDecimal(valor) == 0) {
      setDisabledButton(true);
      if (valor != "")
        showAlertInfo(`Só é possível comprar itens com valor acima de 0`);
      return false;
    }

    if (String(valor)?.slice(-2).includes(",")) {
      setDisabledButton(true);
      return false;
    }

    if (
      valorItemCarrinho?.escala > 0 &&
      formatMoneyStringInDecimal(valor ?? 0) % valorItemCarrinho?.escala > 0
    ) {
      setDisabledButton(true);
      showAlertInfo(
        `Só é possível comprar itens em escala de ${valorItemCarrinho?.escala}`
      );
      return true;
    }

    if (
      formatMoneyStringInDecimal(valor ?? 0) < valorItemCarrinho?.valorMinimo
    ) {
      setDisabledButton(true);
      if (!!valor) {
        showAlertInfo(
          `Não é possível solicitar a quandidade de ${valor}, poís o mínimo permitido é ${valorItemCarrinho?.valorMinimo}`
        );
      }
      return true;
    }

    setDisabledButton(false);
    return true;
  };

  useEffect(() => {
    if (itemVitrine) {
      let item = {
        idItemVitrine: itemVitrine?.id,
        valorMe: formatMoneyStringInDecimal(
          itemVitrine?.valorMe ?? itemVitrine.valorInicial
        ),
        valorMn: 0,
        impostos: [],
        valorVet: 0,
        idTipoOperacaoCarrinho: vitrine?.idTipoOperacao,
        idPraca: vitrine?.idPraca,
        idVitrine: vitrine?.id,
        idHotsite: user?.idHotsite ?? 0,
        idTipoCartao: itemVitrine?.idTipoCartao ?? 0,
        moeda: itemVitrine?.moeda,
        indisponivel: itemVitrine?.indisponivel,
        indisponivelText: itemVitrine?.indisponivelText,
        isCompra: false,
      };

      handlerCalculate(item);
    }
  }, [itemVitrine, vitrine, valorItemCarrinho]);

  useEffect(() => {
    setItemVitrine({
      ...itemVitrine,
      valorMe: configInitialvalue(itemVitrine),
    });
  }, [itemVitrine?.id]);

  useEffect(() => {
    setLoadingButton(true);
    refetchImpostos();
  }, [vitrine?.idPraca, itemVitrine?.id]);

  const handlerCalculate = async (i) => {
    try {
      if (!i.idVitrine) return;

      if (!valorItemCarrinho) {
        // await getItemCarrinhoValor(i);
        setLoadingButton(true);
        refetchImpostos();
        return;
      }

      let item = i;

      if (!isValidacoesPreCalculo(item?.valorMe)) {
        return;
      }

      item.indisponivel = valorItemCarrinho?.indisponivel;
      item.indisponivelText = valorItemCarrinho?.indisponivelText;

      item.impostos = valorItemCarrinho?.impostos;
      item.idTaxa = valorItemCarrinho?.idTaxa;
      item.valorTaxaAplicada = formatMoney(
        valorItemCarrinho?.valorTaxa,
        item.moeda.numeroCasaDecimais
      );

      const valorBaseMn = formatMoney(item.valorMe * item.valorTaxaAplicada, 2);

      // let valorMn = formatMoney(valorBaseMn, 2);

      let valorMnComImposto = parseFloat(valorBaseMn);
      let idTipoItemCarrinho = 0;

      if (vitrine.idTipoOperacao == TiposPedido.VendaOuro) {
        item.isCompra = false;
        idTipoItemCarrinho = TiposItemCarrinho.VendaOuro;

        item?.impostos?.map((i) => {
          const valorImpostoBase = formatMoney(
            (valorMnComImposto * i.aliquota) / 100,
            2
          );
          i.valor = formatMoney(valorImpostoBase, 2);
          valorMnComImposto += parseFloat(valorImpostoBase);
        });
      }

      if (vitrine.idTipoOperacao == TiposPedido.CompraEspecie) {
        item.isCompra = true;
        if (isEspecie) idTipoItemCarrinho = TiposItemCarrinho.CompraEspecie;
        else idTipoItemCarrinho = TiposItemCarrinho.CompraSaldoCartao;

        item.impostos.map((i) => {
          const valorImpostoBase = formatMoney(
            (valorMnComImposto * i.aliquota) / 100,
            2
          );
          i.valor = formatMoney(valorImpostoBase, 2);
          valorMnComImposto -= parseFloat(valorImpostoBase);
        });
      }

      item.valorMn = formatMoney(valorBaseMn, 2);
      item.valorVet = formatMoney(
        valorMnComImposto / item.valorMe,
        item.moeda.numeroCasaDecimais
      );

      item.idCliente = user.id;
      item.valorTotalMN = valorMnComImposto;
      item.idTipoItemCarrinho = idTipoItemCarrinho;

      setItemCarrinho(item);

      if (item.indisponivel) throw new Error(item.indisponivelText);
    } catch (error) {
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setLoadingButton(false)
      }, 1500);
    }


  };

  const handlerAddCart = async (event, isContinueOperacao = true) => {
    event.preventDefault();
    setLoadingButton(true);

    try {
      if (isContinueOperacao) {
        if (!termoAceito) {
          setIsErrorTermo(true);
          throw new Error("É necessário aceitar o termo de uso.");
        }

        if (!termoRespon) {
          setIsErrorTermoResp(true);
          throw new Error("É necessário aceitar o termo de responsabilidade.");
        }

        setTimeout(() => {
          navigation(`/carrinho/turismo/entrega/${true}`);
        }, 2200);

        return;
      }

      // let text = verificaPedidoDiferente(itemCarrinho, pedido);



      if (itemCarrinho.indisponivel)
        throw new Error(itemCarrinho.indisponivelText);

      const response = await addItemOuro(
        itemCarrinho.idCliente,
        itemCarrinho.idPraca,
        idTipoOperacao,
        itemCarrinho
      );

      if (response && !!response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      if (response) {
        refetchPedido();
      }
    } catch (error) {
      setTermoAceito(false);
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2200);
    }
  };

  const handlerChangePraca = (id) => {
    setItemCarrinho({ ...itemCarrinho, idPraca: id });
    setVitrine({ ...vitrine, idPraca: id });
  };

  const handlerChangeItem = (event) => {

    setItemVitrine({
      ...vitrine.itensVitrine.filter((i) => i.id === event.target.value)[0],
    });
  };

  const handlerChangeValueMe = (event) => {
    let valor = event.target.value;

    setItemVitrine({
      ...itemVitrine,
      valorMe: valor,
    });
  };

  return (
    <>
      <Alert />
      {(loadingButton || isLoadingImpostos || isLoadingVitrine) && (
        <LoadingVitrine />
      )}
      {!loadingButton &&
        !isLoadingVitrine &&
        !isLoadingImpostos &&
        <>
          {
            !!vitrine && itemCarrinho.moeda.id !== undefined ?
              <Box sx={{ mt: '3%' }}>
                <VitrineBase
                  vitrine={vitrine}
                  onChangeItem={handlerChangeItem}
                  onAddCart={handlerAddCart}
                  itemSelected={itemVitrine}
                  itemCarrinho={itemCarrinho}
                  onChangePraca={handlerChangePraca}
                  onChangeValorMe={handlerChangeValueMe}
                  onChangeTermo={setTermoAceito}
                  isTermoResponsabilidadeAceite={termoRespon}
                  isTermoUsoAceite={termoAceito}
                  onChangeTermoRespon={setTermoRespon}
                  isErrorTermo={isErrorTermo}
                  isErrorTermoResp={isErrorTermoResp}
                  loadingButton={loadingButton}
                  setLoadingButton={setLoadingButton}
                  disabledButton={disabledButton}
                />
              </Box>
              :
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "80vh", marginTop: "-1rem", background: "#fcfcfc" }}
              >
                <Grid item>
                  <Card>
                    <CardHeader subheader="❌ Comunicado importante" />
                    <CardContent>
                      <Typography variant="body1">
                        A vitrine não esta disponivel para operações no momento.
                        <br />
                        Para mais detalhes, entre em contato conosco.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
          }
        </>
      }
    </>
  );
}
