import React from "react";
import TextField from "@mui/material/TextField";

export default function Forms() {
  return (
    <>
      <TextField size="small"></TextField>
    </>
  );
}
