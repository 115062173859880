import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export function FeedbackSuccessStep({ onFeedbackRestartRequested }) {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            mt: 8,
            justifyContent: "center",
          }}
        >
          <VolunteerActivismOutlinedIcon sx={{ fontSize: 45 }} />
        </Box>
        <Box
          sx={{
            display: "flex",

            justifyContent: "center",
          }}
        >
          <Typography
            color="black"
            sx={{ fontWeight: 500, fontSize: "14pt", mt: 3 }}
            variant="body1"
          >
            Obrigado pelo seu feedback!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <ColorButton
            sx={{ mt: 3 }}
            size="small"
            variant="contained"
            onClick={onFeedbackRestartRequested}
          >
            Quero enviar outro
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
