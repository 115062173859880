import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  CardContent: {
    maxHeight: "100%",

    [theme.breakpoints.up("sm")]: {
      maxHeight: "100%",
      
    },
  },
  Card: {
    borderRadius: 2,
    background: "#fff",

    maxHeight: "58vh",
    minWidth: "20vw",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 2,
      background: "#fff",
      minHeight: "20vh",
      marginBottom: 60,
      maxHeight: "100%",
      minWidth: "20vw",
      overflow: "auto",
    },
  },
  titleCard: {
    color: "#000",
    fontWeight: "BOLD",

    justifyContent: "space-between",
    alignItems: "center",
  },

  customBotao: {
    fontSize: "12px !important",
    padding: "4px !important",
    margin: "10px !important",

    width: "70% !important",
    // color: `${theme.palette.primary.main}  !important`,
    //         transition: theme.transitions.create('all', { duration: theme.transitions.duration.complex, easing: theme.transitions.easing.easeInOut }),
    "&:hover": {
      color: `${theme.palette.primary.main}  !important`,
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "12px !important",
      padding: "4px !important",
      margin: "10px !important",

      width: "35% !important",
      // color: `${theme.palette.primary.main}  !important`,
      //         transition: theme.transitions.create('all', { duration: theme.transitions.duration.complex, easing: theme.transitions.easing.easeInOut }),
      "&:hover": {
        color: `${theme.palette.primary.main}  !important`,
        backgroundColor: "transparent !important",
      },
    },
  },
}));

export default useStyles;
