import { useState, useEffect } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import CampoOperacao from "../../Campos/CampoOperacao";
import Subtitulo from "../../Textos/Subtitulo";
import { Grid, Skeleton } from "@mui/material";
import { getParticipacaoExterna } from "../../../api/Cadastro";
import CampoNumber from "../../Campos/CampoNumber";
import useStyle from "./styles";
import {
  formatMoneyToDecimal,
  formatMoneyInStringForm,
  formatMoneyStringInDecimal,
  removePontoString,
} from "../../../util/helpers";
import CustomGrid from "../../GridCadastroCustom";

export default function ParticipacaoExternaItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getParticipacaoExterna(
      idCliente,
      idPerfilLimiteOperacional
    );

    setDadosForm(response);
    setDada(response);
  };

  const handlerChangeValuePercentual = (value, propriedade) => {
    const { campos } = dadosForm;

    // Encontra o objeto com a propriedade 'label' igual ao valor fornecido
    const updatedObjects = campos.map((obj) => {
      if (obj.propriedade === propriedade) {
        return {
          ...obj,
          valor: value,
        };
      }
      return obj;
    });

    setDadosForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente["campos"] = updatedObjects;
      return newCliente;
    });
  };

  const handlerChangeValue = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  return (
    <>
     
      <div className={classes.box}>


      {isLoading ? (
        <Skeleton animation="wave" width={"10%"} height={30} sx={{  }} />
      ) : (
        <Subtitulo
          sx={{ mb: 3 }}
          color="primary"
          label="Participação Externa"
        />
      )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {dadosForm.campos &&
            dadosForm.campos.map((c, i, array) => (
              <CustomGrid index={i} array={array} md={4}>
                <>
                  {c.tipoControle === "Texto" && (
                    <CampoTexto
                      isLoading={isLoading}
                      key={i}
                      enable={c.enable}
                      sx={{ mt: 0}}
                      variant="filled"
                      size="small"
                      fullWidth={true}
                      defaultValue=" "
                      label={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      maximoDigitos={c.maxLength}
                      handlerChange={handlerChangeValue}
                      className={classes.campo}
                    />
                  )}

                  {c.tipoControle === "Numero" && (
                    <CampoNumber
                      isLoading={isLoading}
                      key={i}
                      isMonetario={c.tipoMascara === "Monetaria"}
                      enable={c.enable}
                      sx={{ mt: 0, m: 2, ml: 0, width: "100%" }}
                      variant="outlined"
                      size="small"
                      fullWidth={false}
                      label={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      onChange={handlerChangeValuePercentual}
                      className={classes.campo}
                      maximoDigitos={c.maxLength}
                      required={c.obrigatorio}
                    />
                  )}
                </>
              </CustomGrid>
            ))}
        </Grid>

        {/*// <CampoMask
        //   enable={true}
        //   sx={{ mt: 0, m: 2 }}
        //   variant="filled"
        //   size="small"
        //   fullWidth={false}
        //   label="Cpf"
        //   name="numeroDocumentoIdentificacao"
        //   value={dadosForm.numeroDocumentoIdentificacao}
        //   handlerChange={handlerChangeValue}
        //   className={classes.campo}
        //   mask="999.999.999-99"
        // />
            */}
      </div>
    </>
  );
}
