import { makeStyles } from "@mui/styles";

const imp = " !important";

const useStyle = makeStyles((theme) => ({
  checked: {
    border: `1px solid ${theme.palette.primary.main}` + imp,
  },
  container_valorSaldoSaque: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1) + "!important",
    marginBottom: theme.spacing(1) + "!important",
    "& button": {
      padding: 0,
      opacity: 0.7,
      transition: "opacity 400ms ease-in-out",
      "&:hover": {
        opacity: 1,
      },
    },
  },
}));

export default useStyle;
