import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 12,
    margin: 8,
    paddingTop: 8,
  },
  Box: {
    borderRadius: 8,
    textAlign: "left",
    padding: 5,
    margin: 0,
    width: "100%",
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      zIndex: 2,
    },
  },
}));
export default useStyles;
