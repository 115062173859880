import { makeStyles } from "@mui/styles";

const imp = " !important";
const useStyles = makeStyles((theme) => ({
  Button: {

    textTransform: "inherit !important",

    marginBottom: theme.spacing(1) + imp,

    "&:hover": {
      background: "inherit" + imp,
    },
  },

  ButtonWithText: {
    textTransform: "inherit !important",
    color: theme.palette.text.primary + imp,
    marginBottom: theme.spacing(1) + imp,
    transition: theme.transitions.create('all', { duration: theme.transitions.duration.complex, easing: theme.transitions.easing.easeInOut }),
    "&:hover": {
      color: theme.palette.primary.main + imp,
      background: "inherit" + imp,
    },
  }


}));

export default useStyles;
