import { List, ListItem, ListItemText } from "@mui/material";
import { Subtitulo } from "../";

const Delivery = ({ regiaoEntrega, pontoRetirada, endereco }) => {
  return (
    <>
      <List sx={{ width: "100%" }}>
        {/* <Subtitulo>Endereço destino</Subtitulo> */}

        {regiaoEntrega && (
          <ListItem
            sx={{ marginTop: "1rem" }}
            secondaryAction={<ListItemText primary={"Delivery"} />}
            disablePadding
          >
            <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
          </ListItem>
        )}

        {pontoRetirada && (
          <ListItem
            sx={{ marginTop: "1rem" }}
            secondaryAction={<ListItemText primary={"Retirada em loja"} />}
            disablePadding
          >
            <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
          </ListItem>
        )}

        {!regiaoEntrega && !pontoRetirada && (
          <>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={"Sedex"} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
            </ListItem>
          </>
        )}

        {!!endereco && (
          <>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={
                <ListItemText
                  primary={`${endereco.logradouro} - ${endereco.numero}`}
                />
              }
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Logradouro:</ListItemText>
            </ListItem>

            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={endereco.bairro} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Bairro:</ListItemText>
            </ListItem>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={endereco.cidade} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Cidade:</ListItemText>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

export default Delivery;
