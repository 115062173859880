import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

// const CssTextField = styled(CheckBox)({
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderRadius: "2px !important",
//     },
//     "&:hover fieldset": {
//       borderRadius: "2px !important",
//     },
//   },
// });

export default function CampoCheck({
  name,
  index,
  value,
  fullWidth,
  sx,
  handleChangeCheck,
  label,
  size,
  enable,
  isLoading = false,
  required,
  descricao,
  autoFocus,
}) {
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (required) setIsError(value === null || value.length <= 0);
  }, [value]);

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          height={40}
          sx={sx}
          animation={false}
          className={classes.root}
        />
      )}
      {!isLoading && (
        <FormGroup >
          <Typography>{descricao}</Typography>
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                fullWidth={fullWidth ?? true}
                value={value}
                disabled={!enable}
                checked={value}
                className={classes.root}
                onChange={handleChangeCheck}
                error={isError}
                inputProps={{ "aria-label": "controlled" }}
                name={`${name}`}
              />
            }
            label={label}
          />
        </FormGroup>

     
      )}
    </>
  );
}
