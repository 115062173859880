import * as React from "react";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../hooks/AuthContext";
import { useQuery } from "react-query";
import { CLIENTESALOD } from "../../util/typesReactQuery";
import useAlert from "../../hooks/useAlert";
import useStyles from "./styles";
import List from "@mui/material/List";
import { getSaldoCLiente } from "../../api/chamadasApi";

export default function Saldo() {
  const { user } = useAuth();

  const classes = useStyles();
  const { Alert, showAlertError, showAlertSuccess } = useAlert();

  const { data: clienteSaldos } = useQuery(
    CLIENTESALOD,
    () => getSaldoCLiente(user.id),
    {
      onSuccess: (data) => {
        if (!data || !data.data) {
          showAlertError(
            "Opa, houve um erro, informe ao suporte Tecnico: Não foram encontrado nenhum cadastro de saldo "
          );
          return;
        }

        if (data.status !== 200) {
          showAlertError("Algo deu errado por favor tente mais tarde - R34RF");
        }

        if (data.errorMessage) {
          showAlertError(
            "Opa, houve um erro, informe ao suporte Tecnico: " +
              data.errorMessage
          );
          return;
        }
      },
    }
  );
  return (
    <Grid
      style={{ display: "flex" }}
      container
      className={classes.Grid}
      xs={12}
      xl={8}
      spacing={{ xs: 2, md: 0 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {" "}
      <List
        sx={{
          width: "100%",

          bgcolor: "background.paper",
        }}
      >
       {/*  <ListItem
          alignItems="center"
          secondaryAction={
            <React.Fragment>
              <Typography color="text.secondary" edge="end">
                Em aprovação:
              </Typography>
              <Typography color="text.secondary" edge="end">
                {formatMoneyInString(clienteSaldos?.data[0]?.saldoProvisorio)}
              </Typography>
            </React.Fragment>
          }
        >
          <ListItemAvatar>
            <Avatar className={classes.AvatarReais} variant="rounded">
              <MonetizationOnOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Saldo em Reais"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="h6"
                  color="text.primary"
                >
                  {formatMoneyInString(clienteSaldos?.data[0]?.saldo)}
                </Typography>
              </React.Fragment>
            }
          />
          </ListItem> */}

       {/*  <ListItem
          alignItems="center"
          secondaryAction={
            <React.Fragment>
              <Typography color="text.secondary" edge="end">
                Em aprovação:
              </Typography>
              <Typography color="text.secondary" edge="end">
                {formatMoneyInString(clienteSaldos?.data[0]?.saldoProvisorio)}
              </Typography>
            </React.Fragment>
          }
        >
          <ListItemAvatar>
            <Avatar className={classes.AvatarCripto} variant="rounded">
              <CurrencyBitcoinOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Saldo em Criptomoeda"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="h6"
                  color="text.primary"
                >
                  $ 0,00
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>*/}
      </List>
    </Grid>
  );
}
