import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  CardContent,
  Typography,
  IconButton,
  Skeleton,
  Button,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import { useNavigate } from "react-router-dom";
import useStyle from "./style";
import { Titulo, ButtonAcaoGrande, Subtitulo } from "../";
import { formatMoneyInString } from "../../util/helpers";
import useAlert from "../../hooks/useAlert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TiposItemCarrinho from "../../util/typesTipoItemCarrinho";
import { Box } from "@material-ui/core";
import { TooltipInformacao, PositionTooltip } from "../../components";
import { useMutation } from "react-query";
import { LimpaCarrinho } from "../../api/PedidoComercial";
import { RemoveItemByIdMoeda } from "../../api/Carrinho/Turismo";
import { RemoveItemByOuroIdMoeda } from "../../api/Carrinho/Ouro";
import { useAuth } from "../../hooks/AuthContext";
import { useState, useEffect } from "react";
import barGold from "../../assets/images/bargold.ico";

const TipoItem = {
  turismo: 1,
  comercial: 2,
  ouro: 3,
};

function ItensCart({ pedido, url, urlVitrine, refetchPedido }) {
  const { Alert, showAlertInfo, showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();
  const navigation = useNavigate();
  const classes = useStyle();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleContinueOperaco = (event) => {
    event.preventDefault();

    if (pedido) {
      navigate(url);
      return;
    }

    showAlertInfo("Não consta nenhum pedido");
  };

  const handlerComprarMais = (event) => {
    event.preventDefault();

    navigate(urlVitrine);
  };

  const TituloPagina = () => {
    if (pedido?.itens.length > 0) {
      let resp = verificaTipoItemCarrinho();
      return resp?.texto;
    } else return "";
  };

  const verificaTipoItemCarrinho = () => {
    let obj = {
      tipo: "",
      texto: "",
    };

    if (pedido?.itens?.length > 1) {
      obj.texto = "Operacão de Turismo";
      obj.tipo = TipoItem.turismo;
      return obj;
    }

    switch (pedido.itens[0].idTipoItemCarrinho) {
      case TiposItemCarrinho.RemessaExterior:
        obj.texto = "Envio para o Exterior";
        obj.tipo = TipoItem.comercial;
        break;
      case TiposItemCarrinho.RecebimentoExterior:
        obj.texto = "Recebimento do Exterior";
        obj.tipo = TipoItem.comercial;
        break;
      case TiposItemCarrinho.VendaEspecie:
        obj.texto = "Compra de moeda estrangeira";
        obj.tipo = TipoItem.turismo;
        break;

      case TiposItemCarrinho.VendaCartao:
        obj.texto = "Compra de um novo cartão";
        obj.tipo = TipoItem.turismo;
        break;

      case TiposItemCarrinho.RecargaCartao:
        obj.texto = "Recarga do Cartão";
        obj.tipo = TipoItem.turismo;
        break;
      case TiposItemCarrinho.CompraEspecie:
        obj.texto = "Venda da sua moeda estrangeira";
        obj.tipo = TipoItem.turismo;
        break;
      case TiposItemCarrinho.CompraSaldoCartao:
        obj.texto = "Venda de Saldo do cartão";
        obj.tipo = TipoItem.turismo;
        break;
      case TiposItemCarrinho.VendaOuro:
        obj.texto = "Compra de ouro";
        obj.tipo = TipoItem.ouro;
        break;
      default:
        obj.texto = "";
        break;
    }

    return obj;
  };

  useEffect(() => {
    if (pedido?.itens?.length == 0)
      setTimeout(() => {
        navigation("/CarrinhoVazio", {
          replace: false,
        });
      }, 2000);
  }, [pedido]);

  const mutationRemoveItemComercial = useMutation(LimpaCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoading(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R776E `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchPedido();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    },
  });

  const mutationRemoveItemTurismo = useMutation(RemoveItemByIdMoeda, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoading(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R776E `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchPedido();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    },
  });

  const mutationRemoveItemOuro = useMutation(RemoveItemByOuroIdMoeda, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoading(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R776E `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchPedido();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    },
  });

  const handlerRemoveItemComercial = () => {
    mutationRemoveItemComercial.mutate(user.id);
  };

  const handlerRemoveItemTurismo = (idMoeda) => {
    mutationRemoveItemTurismo.mutate({
      idCliente: user.id,
      idOrigem: user?.idOrigemCadastroCliente,
      idMoeda: idMoeda,
    });
  };

  const handlerRemoveItemOuro = (idMoeda) => {
    mutationRemoveItemOuro.mutate({
      idCliente: user.id,
      idOrigem: user?.idOrigemCadastroCliente,
      idMoeda: idMoeda,
    });
  };

  const handlerManipulacaoEventos = (idMoeda) => (event) => {
    if (pedido?.itens.length > 0) {
      let resp = verificaTipoItemCarrinho();

      if (resp.tipo == TipoItem.turismo)
        return handlerRemoveItemTurismo(idMoeda);

      if (resp.tipo == TipoItem.comercial) return handlerRemoveItemComercial();

      if (resp.tipo == TipoItem.ouro) return handlerRemoveItemOuro(idMoeda);
    }
  };

  return (
    <>
      <Alert />
      <Grid xs={12} lg={8} className={classes.Grid}>
      <Grid sx={{ mb: 4, mt: 2 }} item xs={12}>
          <Typography
          
            sx={{ fontSize: 18, display: "flex", alignItems: "center" }}
          >
            {/* <CurrencyExchangeOutlinedIcon sx={{ mr: 1, fontSize: 16 }} /> */}
            <TituloPagina sx={{ color: "black !important" }} />
          </Typography>
        </Grid>
        <Grid
          xs={12}
          lg={12}
          container
          sx={{ borderRadius: 2, display: "flex" }}
        >
          <Box className={classes.Step}>
            <Typography
              className={classes.Number}
          
            >
              1
            </Typography>
            <Typography  className={classes.TextStep}>Carrinho</Typography>
            <ArrowForwardIosIcon sx={{ fontSize: 12 }} />
          </Box>

          <Box className={classes.Step2}>
            <Typography
            className={classes.Number}
             sx={{opacity:0.4}}
            >
              2
            </Typography>
            <Typography    className={classes.TextStep} sx={{ opacity: 0.3 }}>Concluir compra</Typography>
            <ArrowForwardIosIcon sx={{ fontSize: 12, opacity: 0.3 }} />
          </Box>
          <Grid sx={{marginLeft:'auto'}}>
          {pedido?.itens[0]?.idTipoItemCarrinho !=
                TiposItemCarrinho.RemessaExterior &&
                pedido?.itens[0]?.idTipoItemCarrinho !=
                  TiposItemCarrinho.RecebimentoExterior && (
            <Button
              sx={{
                textTransform: "inherit",
                marginLeft: "auto",
                fontSize: 17,
              
              }}
              onClick={handlerComprarMais}
            >
              <AddShoppingCartIcon sx={{ fontSize: 16, mr: 1 }} />
              Adicionar itens no carrinho +
            </Button>)}
          </Grid>
        </Grid>
       

        <Grid xs={12} lg={12} container sx={{ borderRadius: 2 }}>
          <Grid
            item
            xs={12}
            sx={{ mt: 1, background: "#fff", p: 2, borderRadius: 2 }}
          >
            {/* <Titulo sx={{ fontWeight: "BOLD", fontSize: "18px" }}>
              Itens adicionados
            </Titulo> */}

            <List>
              <ListItem className={classes.listaCabecalho}>
                <Grid
                  container
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    borderRadius: 1,
                  }}
                >
                  {pedido?.itens.length > 0 &&
                    pedido.itens.map((p) => (
                      <>
                        <Grid
                          container
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #ddd",
                            pb: 1,
                            pt:1
                          }}
                        >
                          <Grid xs={6} lg={3}>
                            <Typography color="text" className={classes.Titulo}>
                              Produto
                            </Typography>

                            <Typography
                              style={{
                                textAlign: "start",
                                display: "flex",
                                alignItems: "center",
                                mt: 2,
                              }}
                            >
                              {p?.idTipoItemCarrinho ==
                              TiposItemCarrinho.VendaOuro ? (
                                <img
                                  src={barGold}
                                  className={classes.IconeOuro}
                                  alt
                                />
                              ) : (
                                <img
                                  src={`/images/Bandeiras/${p?.moeda?.codigoBacen}.svg`}
                                  className={classes.Bandeira}
                                  alt
                                />
                              )}
                               {p.moeda.descricao}
                            </Typography>
                          </Grid>
                          <Grid xs={6} lg={3}>
                            <Typography color="text" className={classes.Titulo}>
                              Quantidade
                            </Typography>

                            <Typography
                              style={{
                                textAlign: "start",
                                display: "flex",
                                mt: 2,
                              }}
                            >
                              {formatMoneyInString(p.valorMe, p.moeda.simbolo)}
                            </Typography>
                          </Grid>
                          <Grid xs={6} lg={3} className={classes.descricao}>
                            <Typography color="text" className={classes.Titulo}>
                              Taxa da moeda
                            </Typography>

                            <Typography
                              style={{
                                textAlign: "start",
                                display: "flex",
                                mt: 2,
                              }}
                            >
                              {formatMoneyInString(p.valorTaxaAplicada)}
                            </Typography>
                          </Grid>
                          <Grid xs={5} lg={2} className={classes.descricao}>
                            <Typography color="text" className={classes.Titulo}>
                              Valor em reais
                            </Typography>
                            <Typography
                              style={{
                                textAlign: "start",
                                display: "flex",
                                mt: 2,
                                fontWeight: "bold",
                              }}
                            >
                              {formatMoneyInString(p.valorTotalMN)}
                            </Typography>
                          </Grid>
                          <Grid xs={1} lg={1} className={classes.descricao}>
                            <IconButton
                              className={classes.IconButton}
                              onClick={handlerManipulacaoEventos(p?.moeda?.id)}
                              sx={{ p: 0, m: 0, ml: 0 }}
                            >
                              <TooltipInformacao
                                positionStart={PositionTooltip.right}
                                descricao="Excluir item do carrinho"
                              >
                                <Button
                                  color="error"
                                  sx={{ textTransform: "capitalize", mt: 1 }}
                                >
                                  <DeleteOutlineIcon
                                    fontSize="inherit"
                                    sx={{ fontSize: "1.4rem" }}
                                    color="inherit"
                                  />{" "}
                                </Button>

                                {/* <DeleteOutlineIcon
                                  fontSize="inherit"
                                  sx={{ fontSize: "1.2rem" }}
                                  color="inherit"
                                /> */}
                              </TooltipInformacao>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </ListItem>
            </List>
          </Grid>

          <Grid container sx={{ display: "flex", justifyContent: "end" }}>
            <Grid item xs={10} lg={5}>
              <CardContent></CardContent>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          md={12}
          lg={12}
          container
          sx={{
            borderRadius: 2,

            maxHeight: "20em",
            // boxShadow: "0px 0px 3px 0px #ccc",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                display: "flex",

                alignItems: "flex-end",
              }}
            >
              {loading ? (
                <>
                  <span>
                    <Skeleton width={'100%'} height={50} animation="wave" />
                  </span>
                  <span>
                    <Skeleton width={'100%'} height={50} animation="wave" />
                  </span>
                </>
              ) : (
                <>
                  <Titulo
                    color="text"
                    sx={{
                      fontWeight: "bold",
                      fontSize: 18,
                      display: "flex",
                      alignItems: "center",
                      background: "#f7f7f7",
                      width: "100%",
                      p: 2,
                      borderRadius: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Titulo color="text.secondary" sx={{ fontSize: 18, mr: 2 }}>
                      Valor Total:
                    </Titulo>{" "}
                    {formatMoneyInString(pedido?.valorTotalMoedaNacional ?? 0)}
                  </Titulo>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          lg={12}
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
        >
          <Box className={classes.Botao}>
            <ButtonAcaoGrande Handle={handleContinueOperaco} loading={loading}>
              Continuar o seu pedido
            </ButtonAcaoGrande>
          </Box>
        </Grid>
        {/* <Grid lg={12} sx={{ }}>
          <ButtonAcaoGrande Handle={handleContinueOperaco} loading={loading}>
            Continuar o seu pedido
          </ButtonAcaoGrande>
          {pedido?.itens[0]?.idTipoItemCarrinho !=
                TiposItemCarrinho.RemessaExterior &&
                pedido?.itens[0]?.idTipoItemCarrinho !=
                  TiposItemCarrinho.RecebimentoExterior && (
                  <Box className={classes.containerButtons}>
                    <ButtonAcaoGrande
                      sx={{ mb: 3 }}
                      color="success"
                      variant="outlined"
                      Handle={handlerComprarMais}
                      loading={loading}
                    >
                      + Adicionar mais itens
                    </ButtonAcaoGrande>
                  </Box>
                )}
        </Grid> */}
      </Grid>
    </>
  );
}

export default ItensCart;
