import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
      borderRadius: 12,
      margin: 20,
      padding: 15,
    },
    Box: {
      background: "#eee",
      borderRadius: 8,
      textAlign: "center",
      border: "1px dashed purple",
      padding: 15,
      margin: 10,
    
      minHeight: 120,
      [theme.breakpoints.up("sm")]: {
        width: "65%",
        zIndex: 2,
        backgroundColor: "#fcfcfd",
      },
    },
  }));

  export default useStyles;